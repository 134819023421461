import * as React from 'react';
import {
    isValidElement,
    Children,
    cloneElement,
    useCallback,
    useRef,
    useEffect,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
    sanitizeListRestProps,
    useListContext,
    useVersion,
} from 'ra-core';
import {
    Checkbox,
    Table,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';

import classnames from 'classnames';
import union from 'lodash/union';
import difference from 'lodash/difference';

import { 
    DatagridHeaderCell, 
    useDatagridStyles,
} from 'react-admin';

import DatagridContextProvider from './DatagridContextProvider';
import DatagridLoadingEx from './DatagridLoadingEx';
import DatagridBodyEx, { PureDatagridBodyEx } from './DatagridBodyEx';
import { DragAndDropWrapper } from "../../../../../../DraggableDatagrid";

import {
	useIsDraggableContext,
    useDraggableIDsContext,
} from "../../../../../../DraggableDatagrid";

const DraggableHeaderCell = ({ classes }) => {
	const { isDraggable } = useIsDraggableContext();

	if (isDraggable)
		return (
			<TableCell className={classes.headerCell}/>
		);
    else return null;
};

    
const DatagridEx = React.forwardRef((props, ref) => {
    const classes = useDatagridStyles(props);
    const {
        optimized = false,
        body = optimized ? <PureDatagridBodyEx /> : <DatagridBodyEx />,
        children,
        classes: ClassesOverride,
        className,
        empty,
        expand,
        hasBulkActions = false,
        hover,
        isRowSelectable,
        isRowExpandable,
        resource,
        rowClick,
        rowStyle,
        size = 'small',
        isHeaderShown = true,
        useDragAndDropComponents=false,
        onRowDragEnd,
        ...rest
    } = props;

    const { draggableIDs, setDraggableIDs } = useDraggableIDsContext();
    const {
		basePath,
		currentSort,
		data,
		ids: listIDs,
		loaded,
		onSelect,
		onToggleItem,
		selectedIds,
		setSort,
		total,
	} = useListContext(props);
	useEffect(() => {
		if (useDragAndDropComponents && setDraggableIDs) setDraggableIDs(listIDs);
	}, [listIDs, setDraggableIDs]);
    const version = useVersion();
    const ids = draggableIDs || listIDs;

    const isDraggableIDsLoaded =
        !useDragAndDropComponents || (draggableIDs?.length && data.hasOwnProperty(draggableIDs[0]));

    const contextValue = useMemo(() => ({ isRowExpandable }), [isRowExpandable]);

    const updateSortCallback = useCallback(
        event => {
            event.stopPropagation();
            const newField = event.currentTarget.dataset.field;
            const newOrder =
                currentSort.field === newField
                    ? currentSort.order === 'ASC'
                        ? 'DESC'
                        : 'ASC'
                    : event.currentTarget.dataset.order;

            setSort(newField, newOrder);
        },
        [currentSort.field, currentSort.order, setSort]
    );

    const updateSort = setSort ? updateSortCallback : null;

    const handleSelectAll = useCallback(
        event => {
            if (event.target.checked) {
                const all = ids.concat(
                    selectedIds.filter(id => !ids.includes(id))
                );
                onSelect(
                    isRowSelectable
                        ? all.filter(id => isRowSelectable(data[id]))
                        : all
                );
            } else {
                onSelect([]);
            }
        },
        [data, ids, onSelect, isRowSelectable, selectedIds]
    );

    const lastSelected = useRef(null);

    useEffect(() => {
        if (!selectedIds || selectedIds.length === 0) {
            lastSelected.current = null;
        }
    }, [JSON.stringify(selectedIds)]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleToggleItem = useCallback(
        (id, event) => {
            const lastSelectedIndex = ids.indexOf(lastSelected.current);
            lastSelected.current = event.target.checked ? id : null;

            if (event.shiftKey && lastSelectedIndex !== -1) {
                const index = ids.indexOf(id);
                const idsBetweenSelections = ids.slice(
                    Math.min(lastSelectedIndex, index),
                    Math.max(lastSelectedIndex, index) + 1
                );

                const newSelectedIds = event.target.checked
                    ? union(selectedIds, idsBetweenSelections)
                    : difference(selectedIds, idsBetweenSelections);

                onSelect(
                    isRowSelectable
                        ? newSelectedIds.filter((id) =>
                              isRowSelectable(data[id])
                          )
                        : newSelectedIds
                );
            } else {
                onToggleItem(id);
            }
        },
        [data, ids, isRowSelectable, onSelect, onToggleItem, selectedIds]
    );

    if (loaded === false || isDraggableIDsLoaded === false) {
        return (
            <DatagridLoadingEx
                classes={classes}
                className={className}
                expand={expand}
                hasBulkActions={hasBulkActions}
                nbChildren={React.Children.count(children)}
                size={size}
            />
        );
    }

    if (loaded && (ids.length === 0 || total === 0)) {
        if (empty) {
            return empty;
        }

        return null;
    }

    const all = isRowSelectable
        ? ids.filter(id => isRowSelectable(data[id]))
        : ids;

    const bodyElement = cloneElement(
        body,
        {
            basePath,
            className: classes.tbody,
            classes,
            expand,
            rowClick,
            data,
            hasBulkActions,
            hover,
            ids,
            onToggleItem: handleToggleItem,
            resource,
            rowStyle,
            selectedIds,
            isRowSelectable,
            version,
            useDragAndDropComponents,
        },
        children
    );

    return (
		<DatagridContextProvider value={contextValue}>
			<Table
				ref={ref}
				className={classnames(classes.table, className)}
				size={size}
				{...sanitizeListRestProps(rest)}
			>
				{isHeaderShown && (
					<TableHead className={classes.thead}>
						<TableRow className={classnames(classes.row, classes.headerRow)}>
							<DraggableHeaderCell classes={classes} />
							{expand && (
								<TableCell
									padding="none"
									className={classnames(classes.headerCell, classes.expandHeader)}
								/>
							)}
							{hasBulkActions && selectedIds && (
								<TableCell padding="checkbox" className={classes.headerCell}>
									<Checkbox
										className="select-all"
										color="primary"
										checked={
											selectedIds.length > 0 &&
											all.length > 0 &&
											all.every((id) => selectedIds.includes(id))
										}
										onChange={handleSelectAll}
									/>
								</TableCell>
							)}
							{Children.map(children, (field, index) =>
								isValidElement(field) ? (
									<DatagridHeaderCell
										className={classes.headerCell}
										currentSort={currentSort}
										field={field}
										isSorting={
											currentSort.field ===
											(field.props.sortBy || field.props.source)
										}
										key={field.props.source || index}
										resource={resource}
										updateSort={updateSort}
									/>
								) : null
							)}
						</TableRow>
					</TableHead>
				)}
				{useDragAndDropComponents ? (
					<DragAndDropWrapper onDragEnd={onRowDragEnd}>{bodyElement}</DragAndDropWrapper>
				) : (
					bodyElement
				)}
			</Table>
		</DatagridContextProvider>
	);
});

DatagridEx.propTypes = {
    basePath: PropTypes.string,
    body: PropTypes.element,
    children: PropTypes.node.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
    currentSort: PropTypes.exact({
        field: PropTypes.string.isRequired,
        order: PropTypes.string.isRequired,
    }),
    data: PropTypes.any,
    empty: PropTypes.element,
    // @ts-ignore
    expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
    hasBulkActions: PropTypes.bool,
    hover: PropTypes.bool,
    ids: PropTypes.arrayOf(PropTypes.any),
    loading: PropTypes.bool,
    onSelect: PropTypes.func,
    onToggleItem: PropTypes.func,
    resource: PropTypes.string,
    rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    rowStyle: PropTypes.func,
    selectedIds: PropTypes.arrayOf(PropTypes.any),
    setSort: PropTypes.func,
    total: PropTypes.number,
    version: PropTypes.number,
    isRowSelectable: PropTypes.func,
    isRowExpandable: PropTypes.func,
    onRowDragEnd: PropTypes.func,
    useDragAndDropComponents: PropTypes.bool,
};

DatagridEx.displayName = 'Datagrid';


export default DatagridEx;
