import CompareIcon from '@material-ui/icons/Compare';

import { MappersCreate } from './Create/Create';
import { MappersEdit } from './Edit/Edit';
import { MappersList } from './List/List';


export {
	MappersList,
	MappersCreate,
	MappersEdit,
};

export const mappersResource = {
	list: MappersList,
	edit: MappersEdit,
	create: MappersCreate,
	icon: CompareIcon,
};
