import React from 'react';

import { SelectInput } from 'react-admin';
import { UserType } from '../../entities/UserType';

export const OSFCEmployeeTypeSelectInput = (props) => (
	<SelectInput
		label="resources.company-users.fields.type"
		defaultValue={UserType.employee}
		choices={[
			{id: UserType.employee, name: 'Employee'},
			{id: UserType.manager, name: 'Manager'},
			{id: UserType.app_manager, name: 'Management'},
		]}
		{...props}
	/>
);

export const EmployeeTypeSelectInput = (props) => (
	<SelectInput
		label="resources.company-users.fields.type"
		defaultValue={UserType.employee}
		choices={[
			{id: UserType.employee, name: 'Employee'},
			{id: UserType.manager, name: 'Manager'},
		]}
		{...props}
	/>
);

