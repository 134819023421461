import React from 'react';
import ReactDOM from 'react-dom';
import { FunctionField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { EditForm } from './EditableField';

import * as reactColor from "react-color";

const { SketchPicker } = reactColor;


export const useStyles = makeStyles({
	root: {
		display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center', 
        width: '100%',
		height: '100%',
		cursor: "pointer",
		userSelect: "none",
		position: 'relative',
		zIndex: 1,
		
		'td > &:hover:before, td > span > &:hover:before': {
			content: '" "',
			zIndex: -1,
			position: 'absolute',
			width: 'calc(100% + 10px)',
			height: 'calc(100% + 14px)',
			left: -5,
			top: -7,
			backgroundColor: 'rgba(255, 255, 255, 0.4)',
			borderLeft: '1px solid rgba(0,0,0,0.1)',
			borderRight: '1px solid rgba(0,0,0,0.1)',
		}
	},
	colorPickerEditBlock: {
		margin:"0 auto",
		marginBottom: "16px"
	},
	colorPickerViewBlock: {
		width: 30,
		height: 15,
		border: '1px solid black',
	}
});


export const EditPopup = ({ render, edit, editable, updateType, color, setColor, ...props }) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleOpen = (e) => {
		setColor(props.record.color)
		setAnchorEl(e.currentTarget);
	};

	const handleClose = (e) => {
		setAnchorEl(null);
		setColor(props.record.color)
	};
	const open = Boolean(anchorEl);

	const handleSubmit = async values => {
		props.updateSelectedIds(props.record.id);
		props.updateSelectedFields({...props.record, [props.source]: color}, props.source);
		setAnchorEl(false)
	};

	return (
		<React.Fragment>
			{open && ReactDOM.createPortal((
				<EditForm anchorEl={anchorEl} handleSubmit={handleSubmit} handleClose={handleClose} edit={edit} {...props} />
			), document.body)}
			{editable === false ? render({}) : render({ openPopup: handleOpen, className: classes.root })}
		</React.Fragment>
	);
}


export const ColorEditableField = React.memo(({ editable, title="Double click to edit", EditComponent=undefined, ...props }) => {
	const classes = useStyles();
	const [color, setColor] = React.useState(props.record[props.source]);
	const Component = EditComponent || SketchPicker;

	const handleChangeComplete = (color) => {
        setColor(color.hex);
    };

	return (
		<EditPopup
			editable={editable}
			source={props.source}
			resource={props.resource}
			record={props.record}
			basePath={props.basePath}
			updateSelectedIds={props.updateSelectedIds}
			updateSelectedFields={props.updateSelectedFields}
			color={color}
			setColor={setColor}
			edit={(
				<div className={classes.colorPickerEditBlock}><Component color={color} onChange={handleChangeComplete} /></div>
			)}
			render={({ openPopup, className }) => (
				<FunctionField
					render={r => (
						<div
							title={title}
							className={className}
							onDoubleClick={openPopup}
							style={(props.selectedFields[props.record.id] || {}) &&  
										Object.keys(props.selectedFields[props.record.id] || {}).indexOf(props.source) !== -1
										? {backgroundColor: 'orange', height: 20, } 
										: {}
									}
						>
							{r[props.source] ? (
								<div
									className={classes.colorPickerViewBlock}
									style={(props.selectedFields[props.record.id] || {}) &&  
										Object.keys(props.selectedFields[props.record.id] || {}).indexOf(props.source) !== -1
										? {backgroundColor: color } 
										: {}
									}
								></div>
							) : '\u00A0'}
						</div>
					)}
				/>
			)}
		/>
	);
}, (_old, _new) => _old.record.id === _new.record.id && _old.record[_old.source] === _new.record[_new.source] && _old.editable === _new.editable)
