import { Draggable } from "@hello-pangea/dnd";
import React, { useEffect, useState } from "react";
import { Loading } from "react-admin";

import { useDraggableIDsContext } from "./DraggableIDsContext";
import { useIsDraggableContext } from "./IsDraggableContext";

const DraggableDatagridRow = ({ Component, ...props }) => {
	const { draggableIDs } = useDraggableIDsContext();
	const { isDraggable } = useIsDraggableContext();

	const [recordIndex, setRecordIndex] = useState(null);
	const { record } = props;

	useEffect(() => {
		if (draggableIDs) {
			const rcdIndex = draggableIDs.indexOf(record.id);
			setRecordIndex(rcdIndex);
		}
		// else {
		// 	console.log("Don't forget use DraggableIDsProvider")
		// }
	}, [draggableIDs, record.id]);

	if (recordIndex === null || !draggableIDs) {
		return (
			<tr>
				<td>
					<Loading loadingSecondary="" />
				</td>
			</tr>
		);
	}

	return (
		<Draggable
			isDragDisabled={!isDraggable}
			draggableId={`${record.id}`}
			key={record.id}
			index={recordIndex}
		>
			{(provided, snapshot) => (
				<Component
					isDraggable={isDraggable}
					{...props}
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					style={{ ...provided.draggableProps.style, ...props.style }}
				/>
			)}
		</Draggable>
	);
};

export { DraggableDatagridRow };
