import React from 'react';
import { useTranslate } from 'react-admin';
import { SelectInput } from 'react-admin';

export const SelectEnumInput = ({enumObj, enumName, translate=true, ...props}) => {
	const translator = useTranslate();
	// TODO: find out more better approach to translate enum
	const choices = translate ? Object.keys(enumObj).map(key => ({id: enumObj[key], name: translator(`osfc.enums.${enumName}.${key}`)})) : 
			Object.keys(enumObj).map(key => ({id: enumObj[key], name: key}));
	return (
		<SelectInput
			{...props}
			choices={choices}
			// parse={parse}
		/>
	);
};
