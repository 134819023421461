import React from 'react';
import {
    Datagrid, TextField, FunctionField,
    ArrayField, useTranslate
} from 'react-admin';

import {
    Box, 
    makeStyles
} from '@material-ui/core';

import { AddMapper } from './AddMapper';
import { UpdateMapper } from './UpdateMapper';
import { DeleteButton } from '../../../components/toolbars/DeleteButton';
import { SelectEnumField } from '../../../components/fields/SelectEnumField';

import { MapperType } from '../../../entities/MapperType';
import { optionalMapperKeys } from '../../../entities/MapperData';


const useStyles = makeStyles(theme => ({
    customHeaderRequired: {
      backgroundColor: '#C5E1B2',
    },
    customHeaderOptional: {
        backgroundColor: '#F7F1C6',
      },
    optionalLable: {
        fontStyle: 'italic',
    } 
  }));

const mapperDataRowStyle = (type) => (record, index) => {
    return {
        backgroundColor: optionalMapperKeys[type.toString()].includes(record['key']) && type !== MapperType.none ? '#F7F1C6' : '#F0F0F0',
    };
};


export const CustomerCompaniesMappersList = (props) => {
    const classes = useStyles();
    const translate = useTranslate()

    return (
        <>
            <Box display="flex" justifyContent="right" padding={1}>
                <AddMapper company_id={props.id}/>
            </Box>
            <Datagrid>
                <TextField source="id" label="resources.customer-companies-mappers.fields.id"/>
                <TextField source="name" label="resources.customer-companies-mappers.fields.name"/>
                {/* <TextField source="type" label="resources.customer-companies-mapper.fields.type"/> */}
                <SelectEnumField source="type" label="resources.customer-companies-mappers.fields.type" enumName="status" enumObj={MapperType} />
                <FunctionField source="" label={translate('resources.customer-companies-mappers.fields.data')} render={mapperRecord => 
                   <ArrayField source="data" sortable={false}>
                        <Datagrid bulkActionButtons={false} classes={{ headerCell: classes.customHeaderRequired }} rowStyle={mapperDataRowStyle(mapperRecord['type'])}>
                            <FunctionField source="" label="resources.customer-companies-mappers.fields.data_field.key"
                                render={dataRecord => 
                                    mapperRecord['type'] !== MapperType.none ?
                                        <div>{translate(`resources.customer-companies-mappers.fields.data_field.${dataRecord.key}`) + ` (${dataRecord.key})`}</div>:
                                        <div>{dataRecord.key}</div>
                                    }
                            />
                            <TextField source="value" label="resources.customer-companies-mappers.fields.data_field.value"/>
                        </Datagrid>
                    </ArrayField>
                    
                } />
                <FunctionField source="" render={record => <UpdateMapper mappers={record} />} />
                <DeleteButton 
                    resource={props.resource}
                    notifyMessage="osfc.dialogs.confirm_delete_mappers.success"
                    title="osfc.dialogs.confirm_delete_mappers.confirm.title"
                    content="osfc.dialogs.confirm_delete_mappers.confirm.content"
                />
            </Datagrid>
        </>
    );
};
    