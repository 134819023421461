import React from "react";

import { required, NumberInput } from 'react-admin';
import { GridForm, GridInput } from '../../../components/GridForm';
import { SelectEnumInput } from "../../../components/inputs/SelectEnumInput";
import { useOwnerCompanyDataContext } from "../../../contexts/OwnerCompanyDataProvider";
import { Currencies, getCurrenciesWithoutMainCurrency } from "../../../entities/Currencies";

export const CurrencyRateForm = ({isAdminOrOSFCManager, ...props}) => {
	const { ownerCompanyData } = useOwnerCompanyDataContext();

	const mainCurrency = ownerCompanyData.main_currency;
	const currenciesWithoutMainCurrency = getCurrenciesWithoutMainCurrency(mainCurrency)
	const firstValue = Object.values(currenciesWithoutMainCurrency)[0]

	const currenciesList = props.create ? currenciesWithoutMainCurrency : Currencies;
    return(
    <GridForm {...props}>
		<GridInput sm={2} component={SelectEnumInput} enumObj={Currencies} defaultValue={mainCurrency} translate={false} enumName="currency" source="currency_1" disabled={true} validate={[required()]} />
		<GridInput sm={2} component={SelectEnumInput} enumObj={currenciesList} defaultValue={firstValue} translate={false} enumName="currency" source="currency_2" disabled={!props.create} validate={[required()]} />
        <GridInput sm={2} component={NumberInput} source="rate" validate={[required()]} />
	</GridForm>
)}