import { RefreshEntityCacheButton } from 'components/toolbars/RefreshEntityCacheButton';
import { CacheEntities } from 'entities/CacheEntities';
import React from 'react';
import {
	TopToolbar,
	sanitizeListRestProps,
} from 'react-admin';

export const OwnerCompanyDataActions = (props) => {
    const { className, ...rest } = props;

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <RefreshEntityCacheButton cacheEntityId={CacheEntities.owner_company_data} />
        </TopToolbar>
    );
}
