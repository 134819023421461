import React from "react";
import { Datagrid, EditButton, List, TextField, Title, useTranslate } from 'react-admin';

import { EmptyEx } from "../../../components/_extensions/react-admin/ra-ui-materialui/src/list/EmptyEx";
import {DatagridWithBlockDeleteProps} from "../../../components/_helpers/props/DatagridProps";
import {stylesForColorInheritInList} from "../../../components/_helpers/useStyles";
import { PhoneField } from '../../../components/fields/PhoneField';
import { StyledEmailField } from '../../../components/fields/StyledEmailField';
import { UserTypeField } from "../../../components/fields/UserTypeField";
import { YesNoField } from '../../../components/fields/YesNoField';
import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import {HideBlockUnblockButtonIfDeleted} from "../../../components/toolbars/BlockUnblockButton";
import DeleteRestoreButtons from '../../../components/toolbars/DeleteRestoreButtons';
import { useOwnerCompanyDataContext } from "../../../contexts/OwnerCompanyDataProvider";
import { OsfcUsersActions } from "./Actions";
import { ManagersFilter } from './ListFilters';

const defaultSort = { field: 'id', order: 'DESC' };

export const ManagersList = props => {
	const classes = stylesForColorInheritInList();
	const translate = useTranslate();
	const { ownerCompanyData } = useOwnerCompanyDataContext();

	const resourceName = translate(`resources.${props.resource}.name`, {company_name: ownerCompanyData.short_name});
	const defaultEmptyMessage = translate('ra.page.empty', { name: resourceName });

	return (
		<List
			{...props}
			sort={defaultSort}
			exporter={false}
			bulkActionButtons={false}
			filters={<ManagersFilter />}
			classes={classes}
			actions={<OsfcUsersActions/>}
			title={resourceName}
			empty={<EmptyEx defaultEmptyMessage={defaultEmptyMessage} />}
		>
			<ScrollingWrapper>
				<Datagrid {...DatagridWithBlockDeleteProps}>
					<TextField source="id" />
					<TextField source="first_name" />
					<TextField source="last_name" />
					<StyledEmailField source="email" />
					<PhoneField source="phone" />
					<UserTypeField source="type" sortable={false} />
					<YesNoField source="is_terminal" />
					<YesNoField source="access_to_export" />
					<YesNoField source="access_to_task_force" />
					<EditButton label="" />
					<HideBlockUnblockButtonIfDeleted entity={"user"}/>
					<DeleteRestoreButtons
						replaceResource="user-is-deleted"
						confirmDeleteStringKey="osfc.dialogs.confirm_delete_user"
						confirmRestoreStringKey="osfc.dialogs.confirm_restore_user"
					/>
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
}
