import React from "react";
import { ReferenceInput, SelectInput, TextInput, AutocompleteInput, NumberInput, maxValue, minValue, required, useGetList  } from "react-admin";
import { useForm, useFormState } from 'react-final-form'
import * as dateFns from 'date-fns';

import { SelectEnumInput } from "../../../components/inputs/SelectEnumInput";
import { FormGroupGrid , GridForm, GridInput} from "../../../components/GridForm";
import { DateTimeInput } from "../../../components/inputs";
import { DirectionType } from "../../../entities/DirectionType";
import { CountryCameraCodes } from "../../../entities/CameraCountryCodes";
import { getNowByTerminal } from "../../../utils/datetime";
import TerminalDatesInput from "../../../components/inputs/TerminalDatesInput";
import { useTerminalsContext } from '../../../contexts/TerminalsProvider';

export const CreateVehicleAnprForm = props => {
    const form = useForm();
	const { values } = useFormState();
    const { data: terminals, loading: terminalsLoading } = useTerminalsContext();
    const terminalId = values.terminal_id

    const [cameraId, setCameraId] = React.useState([]);
    const [cameraIp, setCameraIp] = React.useState([]);

    const { data: _vehicleANPRCamerasData, loaded } = useGetList(
		"vehicle-anpr-cameras",
		{page: 0, perPage: -1},
		{field: 'id', order: 'ASC'},
	);
    const vehicleANPRCamerasData = Object.values(_vehicleANPRCamerasData);
	React.useEffect(() => {
		if (!terminalsLoading && terminalId) {
			form.change(
				"event_date_time",

				dateFns.formatISO(getNowByTerminal(terminals[terminalId]), { representation: "complete" })
			);
		}
	}, [terminalId, terminalsLoading]);

    React.useEffect(() => {
        const handleTerminalChange = () => {
            const filtredByTerminalData = vehicleANPRCamerasData
                .filter(item => item.terminal_id === values.terminal_id);
            const cameraIds = filtredByTerminalData
                .map(item => ({ id: item.camera_id, name: item.camera_id }));
            const defaultCameraId = cameraIds[0].id;
            const defaultVehicleANPRCameraRecord = filtredByTerminalData.find(item => item.camera_id === defaultCameraId);
            const cameraIps = defaultVehicleANPRCameraRecord.ips;
            const defaultCameraIp = cameraIps[0].id;
            
            setCameraId(cameraIds);
            setCameraIp(cameraIps);
    
            form.change('camera_id', defaultCameraId);
            form.change('camera_ip_address', defaultCameraIp);
            if (defaultVehicleANPRCameraRecord.force_vehicle_direction_type !== null) {
                form.change('vehicle_direction_type', defaultVehicleANPRCameraRecord.force_vehicle_direction_type);
            } else {
                form.change('vehicle_direction_type', DirectionType.IN);
            }
        };
        if (values.terminal_id && vehicleANPRCamerasData.length) {
            handleTerminalChange();
        }
    }, [values.terminal_id, loaded]);

    const handleCameraChange = (e) => {
        const targetVehicleANPRCameraRecord = vehicleANPRCamerasData
            .find(item => item.camera_id === e.target.value && item.terminal_id === values.terminal_id);
        const cameraIps = targetVehicleANPRCameraRecord.ips;

        setCameraIp(cameraIps);

        form.change('camera_ip_address', cameraIps[0].id);
        if (targetVehicleANPRCameraRecord.force_vehicle_direction_type !== null) {
            form.change('vehicle_direction_type', targetVehicleANPRCameraRecord.force_vehicle_direction_type);
        } else {
            form.change('vehicle_direction_type', DirectionType.IN);
        }
    };

    return (
        <GridForm {...props}>
            <FormGroupGrid>
                <GridInput lg={1} sm={6}>
                    <ReferenceInput source="terminal_id" reference="terminal"
                        defaultValue={1} validate={[required()]}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </GridInput>

                <GridInput component={TextInput}source="vehicle_license_plate" lg={3} sm={6} validate={[required()]}/>
                
                <GridInput component={SelectEnumInput} source="vehicle_direction_type"  
                    enumName="vehicle_direction_type" enumObj={DirectionType} 
                    defaultValue={DirectionType.IN} lg={1} sm={6} validate={[required()]}/>

                <GridInput lg={2} sm={6}>
                    <TerminalDatesInput component={DateTimeInput} source="event_date_time" validate={[required()]} />
                </GridInput>

                <GridInput component={AutocompleteInput} choices={CountryCameraCodes} source="vehicle_country" 
                    lg={5} sm={6} validate={[required()]} options={{ InputProps: {  size: "small" } }}/>
            </FormGroupGrid>

            <FormGroupGrid>
                <GridInput component={NumberInput}  validate={[
                    minValue(0),
                    maxValue(100),
                    required()
                ]} source="anpr_confidence_level" lg={1} sm={6} defaultValue={100} />

                <GridInput component={SelectInput} choices={cameraId} onChange={handleCameraChange} 
                    source="camera_id" lg={3} sm={6} validate={[required()]} />

                <GridInput component={SelectInput} choices={cameraIp} source="camera_ip_address"  
                    lg={3} sm={6} validate={[required()]} />
            </FormGroupGrid>
        </GridForm>
    )
}