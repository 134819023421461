import { RefreshEntityCacheButton } from 'components/toolbars/RefreshEntityCacheButton';
import { CacheEntities } from 'entities/CacheEntities';
import React from 'react';
import {
	TopToolbar,
	sanitizeListRestProps,
	useListContext,
} from 'react-admin';

export const TerminalsActions = (props) => {
    const { className, exporter, filters, ...rest } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        showFilter,
    } = useListContext(props);

    return React.useMemo(
        () => (
            <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
				<RefreshEntityCacheButton cacheEntityId={CacheEntities.terminal} />

				{filters &&
                    React.cloneElement(filters, {
                        resource,
                        showFilter,
                        displayedFilters,
                        filterValues,
                        context: 'button',
                    })}
            </TopToolbar>
        ),
        [resource, displayedFilters, filterValues, filters] // eslint-disable-line react-hooks/exhaustive-deps
    );
}
