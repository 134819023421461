import React from 'react';
import { List, Datagrid, TextField, ReferenceField } from 'react-admin';

import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { DirectionType } from '../../../entities/DirectionType';
import { SelectEnumField } from '../../../components/fields/SelectEnumField';


const defaultSort = { field: 'id', order: 'ASC' };

export const VehicleANPRCamerasList = (props) => (
    <List
        {...props}
        sort={defaultSort}
        perPage={50}
        exporter={false}
        bulkActionButtons={false}
    >
        <ScrollingWrapper>
            <Datagrid rowClick='show'>
                <TextField source='id' />
                <TextField source='camera_id' />

                <ReferenceField 
                    source='terminal_id' 
                    reference='terminal'
                    link={false}
                >
                    <TextField source='name' />
                </ReferenceField>

                <SelectEnumField 
                    source="force_vehicle_direction_type" 
                    enumName="vehicle_direction_type" 
                    enumObj={DirectionType} 
                    sortable={false}
                />

            </Datagrid>
        </ScrollingWrapper>
    </List>
);
