import TvIcon from '@material-ui/icons/Tv';

import OutdoorDisplayTaskDisplayActionList from './List/List';
import { TaskDisplayActionCreate }  from './Create/Create';
import { TaskDisplayActionEdit } from './Edit/Edit';

export const outdoorDisplayTaskDisplayActionResource = {
	list: OutdoorDisplayTaskDisplayActionList,
	create: TaskDisplayActionCreate,
	edit: TaskDisplayActionEdit,
	icon: TvIcon,
}
