import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useState } from "react";
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import { useHistory } from "react-router-dom";

import { httpClient } from "../../../api/httpClient";
import { FileInfo } from '../../../components/fields/DocumentsField/SimpleFileInfo';
import { FormGroupGrid, GridForm, GridInput } from "../../../components/GridForm";
import LanguageSelect from "../../../components/inputs/LanguageSelect";
import { SaveWithCancelToolbar } from "../../../components/toolbars/SaveWithCancelToolbar";
import { useNotifyError } from "../../../utils/notifiers/useNotifyError";

const DocumentAddButton = ({ onFileChange }) => {
    const inputRef = React.useRef(null);

    const handleSelectFile = React.useCallback(() => inputRef.current.click(), []);

    const handleFileChange = (e) => {
        if (e.target.files) {
            onFileChange(e.target.files[0]);
        }
    };

    return (
        <React.Fragment>
            <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleSelectFile}
            >
                Add Document
            </Button>
            <input
                ref={inputRef}
                onChange={handleFileChange}
                type="file"
                style={{ display: "none" }}
                accept=".pdf,.docx,.doc"
            />
        </React.Fragment>
    );
};

export const UserManualCreate = (props) => {
    const [file, setFile] = useState(null);
    const history = useHistory();
    const notifyError = useNotifyError();	

    const onSubmit = async (values) => {
        const data = new FormData();
        if (file) {
            data.append('document', file);
        }
        Object.keys(values).forEach(key => {
            data.append(key, values[key]);
        });

        await httpClient(`user-manuals`, {
			method: 'POST',
            body: data,
		}).then((response) => {
			history.push('/user-manuals');
		}).catch(error => {;
            notifyError(error);
        });
    };

    return (
        <Create {...props}>
            <SimpleForm 
                submitOnEnter={false} 
                toolbar={<SaveWithCancelToolbar />} 
                redirect={false}
                save={onSubmit} 
            >
                <GridForm>
                    <FormGroupGrid>
                        <GridInput xs={6} component={LanguageSelect} source="locale" default="en" validate={required()} />
                        <GridInput xs={6} component={TextInput} source="page_url" validate={required()} />
                    </FormGroupGrid>
                    <FormGroupGrid>
                        {file==null && <GridInput xs={6} component={DocumentAddButton} onFileChange={setFile} />}
                        {file && <GridInput xs={3} component={FileInfo} file={file} setFile={setFile} />}
                    </FormGroupGrid>
                </GridForm>
            </SimpleForm>
        </Create>
    );
};
