import React from "react";
import { Resource } from "react-admin";
import { Route } from "react-router-dom";

import { apiKeysRouts } from "../../pages/settings/APIKeys";
import { customerCompaniesResource } from "../../resources/customer-companies/";
import { customerUsers } from "../../resources/customer-users";
// import { transportersResource } from '../../resources/transporters';
import { customerDriversResource } from "../../resources/drivers";
import { entitiesResource } from "../../resources/entities/";
import { entityDocumentStatusResource } from "../../resources/entity-document-status";
import { financialStatementsResource } from "../../resources/financial-statements";
import { laborForecastResource } from "../../resources/labor-forecast";
import { mappersResource } from "../../resources/mappers";
import Messages from "../../resources/messages/List/List";
import { notifLogsResource } from "../../resources/notif-logs";
import { ordersDraftResource, ordersResource } from "../../resources/orders/";
import { OrdersMultipleChangesPage } from "../../resources/orders/MultipleChanges2";
import { osfcManagersResource } from "../../resources/osfc-users";
import { outdoorDisplayTaskDisplayActionResource } from "../../resources/outdoor-display";
import { areasResource, portsResource } from "../../resources/ports-areas";
import { articleTemplatesResource } from "../../resources/pricelist-article-templates";
import { pricelistArticlesResource } from "../../resources/pricelist-articles/";
import { pricelistHolidaysResource } from "../../resources/pricelist-holidays/";
import { recurringTaskResource } from "../../resources/recurring_tasks";
import { tasksResource } from "../../resources/tasks/";
import { transportTrailersResource } from "../../resources/trailers";
import { translationsResource } from "../../resources/translations";
import { transportTrucksResource } from "../../resources/trucks";
import { trucksTrafficResource } from "../../resources/trucks-traffic";
import { vehicleANPRCamerasResource } from "../../resources/vehicle-anpr-cameras";

export const appManagerResources = [
	<Resource name="orders" {...ordersResource} />,
	<Resource name="draft-orders" {...ordersDraftResource} />,
	<Resource {...osfcManagersResource} name="company-users" />,
	<Resource {...mappersResource} name="mappers" />,
	<Resource {...customerCompaniesResource} name="customer-companies" />,
	<Resource {...customerUsers} name="customer-users" />,

	// <Resource {...transportersResource} name="transporters" />,
	<Resource name="transporters" intent="registration" />,
	<Resource {...customerDriversResource} create={null} edit={null} name="drivers" />,
	<Resource {...transportTrucksResource} name="trucks" create={null} edit={null} delete={null} />,
	<Resource
		{...transportTrailersResource}
		name="trailers"
		create={null}
		edit={null}
		delete={null}
	/>,
	<Resource
		{...pricelistArticlesResource}
		name="pricelist-articles"
		options={{ hasUpload: false }}
	/>,
	<Resource {...pricelistHolidaysResource} name="pricelist-holidays" />,
	<Resource {...portsResource} name="ports" intent="registration" />,
	<Resource {...areasResource} name="areas" intent="registration" />,
	<Resource
		{...articleTemplatesResource}
		name="pricelist-article-templates"
		intent="registration"
	/>,
	<Resource name="notif-logs" {...notifLogsResource} />,
	<Resource name="trucks-traffic" {...trucksTrafficResource} />,
	<Resource name="vehicle-anpr-cameras" {...vehicleANPRCamerasResource} />,
	<Resource name="labor-forecast" {...laborForecastResource} />,
	<Resource name="recurring-tasks" {...recurringTaskResource} />,
	<Resource name="financial-statements" {...financialStatementsResource} />,
	<Resource name="task-display-actions" {...outdoorDisplayTaskDisplayActionResource} />,

	<Resource {...translationsResource} name="translations" />,

	<Resource name="backup-pricelist-articles-with-special" intent="registration" />,
	<Resource name="pricelist-special-price" intent="registration" />,
	<Resource name="customer-employees" intent="registration" />,

	<Resource name="users" intent="registration" />,
	<Resource name="companies" intent="registration" />,
	<Resource name="order-statuses" intent="registration" />,
	<Resource name="outgoing-doc-statuses" intent="registration" />,
	<Resource name="company-notifications" intent="registration" />,
	<Resource name="terminal" intent="registration" />,
	<Resource name="external-terminal" intent="registration" />,
	<Resource name="api-keys" intent="registration" />,
	<Resource name="customer-company-mappers" intent="registration" />,
	<Resource name="cooler-types" intent="registration" />,
	<Resource name="order-pricelist-history" intent="registration" />,
	<Resource
		name="order-pricelist"
		intent="registration"
		options={{ hasAdd: true, hasEdit: true }}
	/>,

	<Resource name="tasks" {...tasksResource} />,
	<Resource name="entities" {...entitiesResource} />,
	<Resource name="order-history" intent="registration" />,
	<Resource name="task-history" intent="registration" />,
	<Resource name="entity-history" intent="registration" />,
	<Resource name="task-statuses" intent="registration" />,

	<Resource {...customerDriversResource} create={null} edit={null} name="drivers" />,
	<Resource name="order-statuses" intent="registration" />,
	<Resource name="companies" intent="registration" />,
	<Resource name="company-notifications" intent="registration" />,
	<Resource name="rank-priorities" intent="registration" />,
	<Resource name="entity-document-status" {...entityDocumentStatusResource} />,
];

export const appManagerRoutes = [
	...apiKeysRouts,
	<Route exact path="/messages" component={Messages} />,
	<Route exact path="/orders/multiple-changes" component={OrdersMultipleChangesPage} />,
	<Route exact path="/draft-orders/multiple-changes" component={OrdersMultipleChangesPage} />,
];
