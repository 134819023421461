import React from "react";
import { Datagrid, EditButton, List, TextField } from "react-admin";

import { DatagridWithBlockDeleteProps } from "../../../components/_helpers/props/DatagridProps";
import { stylesForColorInheritInList } from "../../../components/_helpers/useStyles";
import { CopyField } from "../../../components/fields/CopyField";
import { ScrollingWrapper } from "../../../components/ScrollingWrapper";
import { TerminalsActions } from "./Actions";
import { TerminalsFilter } from "./ListFilters";

const defaultSort = { field: "id", order: "DESC" };

export const TerminalsList = (props) => {
	const classes = stylesForColorInheritInList();

	return (
		<List
			{...props}
			sort={defaultSort}
			exporter={false}
			bulkActionButtons={false}
			filters={<TerminalsFilter />}
			actions={<TerminalsActions />}
			classes={classes}
		>
			<ScrollingWrapper>
				<Datagrid {...DatagridWithBlockDeleteProps} rowClick="show">
					<TextField source="id" />
					<TextField source="name" />
					<TextField source="short_name" />
					<TextField source="account_code" />
					<TextField source="time_zone" />
					<TextField source="address" />
					<CopyField
						source="guid"
						style={{
							width: "250px",
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
							display: "inline-block",
						}}
					/>
					<EditButton />
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
};
