import React from 'react';
import { useTranslate } from 'react-admin';
import { JsonInput } from "react-admin-json-view";

import { defaultReactJsonOptions } from './JsonTextField';


export const JsonTextInput = ({ 
    label, 
    jsonString = true, 
    reactJsonOptions = defaultReactJsonOptions, 
    ...props 
}) => {
    const translate = useTranslate()

    return (
        <JsonInput
            {...props}
            label={translate(label)}
            jsonString={jsonString} 
            reactJsonOptions={reactJsonOptions}
        />
    );
};