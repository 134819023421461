import { Filter } from "react-admin"
import { ToggleTerminalsFilter } from "../../../components/filters/ToggleTerminalsFilter"

export const DisplayTerminalFilter = (props) => {
	return (
	  <Filter {...props}>
		<ToggleTerminalsFilter source="terminal_id" exclude_all alwaysOn key={"terminal_id"} />
	  </Filter>
	)
  }
  