import "./index.css";

import React from "react";
import { Admin, adminReducer } from "react-admin";
import { Redirect } from "react-router-dom";

import { _getPermissions, dataProvider, getAuthProvider } from "../api/";
import { osfcDefault as theme } from "../themes/osfcDefault";
// import { i18nProvider } from '../translation/';
import { i18nProviderStatic } from "../translation/staticProvider";
import Layout from "./Layout";
import { MyLoginPage } from "./Login";
import Menu from "./Menu";
import { customRoutesByPermissions, resourcesByPermissions } from "./roles";

const initialState = adminReducer(undefined, { type: "@@INIT" });

const customAdminReducer = (state = initialState, action) => {
	switch (action.type) {
		case "SET_SIDEBAR_OPEN":
			return {
				...state,
				ui: { ...state.ui, sidebarOpen: action.payload },
			};
		default:
			return adminReducer(state, action);
	}
};

const App = (props) => {
	const permissions = _getPermissions();

	const [customRoutes, setCustomRoutes] = React.useState(customRoutesByPermissions(permissions));

	const updateCustomRoutes = React.useCallback((new_permissions) => {
		setCustomRoutes(customRoutesByPermissions(new_permissions));
	}, []);

	const publicBaseRoutes = new Set(["/forgot-password", "/reset-password", "/documentation"]);
	return (
		<Admin
			theme={theme}
			layout={Layout}
			customReducers={{ admin: customAdminReducer }}
			menu={Menu}
			customRoutes={customRoutes}
			authProvider={getAuthProvider(publicBaseRoutes, updateCustomRoutes)}
			dataProvider={dataProvider}
			// i18nProvider={i18nProvider}
			i18nProvider={i18nProviderStatic}
			loginPage={MyLoginPage}
			catchAll={() => <Redirect to="/" />}
		>
			{resourcesByPermissions}
		</Admin>
	);
};

export default App;
