import { cloneDeep, has } from 'lodash';
import React from 'react';

import { HighlightField } from '../Field';
import { HighlightList } from '../List';

const getDiffCssClasses = ({ _diff }) => (["row-highlight-" + _diff ?? 0]);
const getDiffValue = ({ record, source }) => record[`_old_${source}`];

export const listCalcDiff = (list1, list2, mapKeys, keyKey, mapKeysToIgnore) => {
	const key = mapKeys[keyKey];	
	let isDiff = false;
	
	const keysToIgnore = mapKeysToIgnore && has(mapKeysToIgnore, keyKey) ? mapKeysToIgnore[keyKey] : [];
	keysToIgnore.push(keyKey);
	
	const dicList1 = Object.fromEntries(list1.map(x => [x[key], x]));

	for(const row2 of list2) {
		const keyVal = row2[key];
		const row1 = dicList1[keyVal]
		let _diff = 0;
		if (row1) {			
			for (const [cellKey, cellVal] of Object.entries(row2)) {
				if (keysToIgnore.includes(cellKey)) continue;

				const oldCellVal = row1[cellKey];
				const oldCellKey = "_old_" + cellKey;
				if (oldCellVal !== undefined) {
					if (Array.isArray(cellVal)) {
						const _isDiff = listCalcDiff(oldCellVal, cellVal, mapKeys, cellKey, mapKeysToIgnore);
						if (_isDiff) {
							row2[oldCellKey] = true;
							if (!_diff) _diff = 2;
						}
					} else if (cellVal !== oldCellVal) {
						row2[oldCellKey] = oldCellVal;
						if (!_diff) _diff = 2;
					}
					
				}
			}		
		} else {
			_diff = 1;			
		}
		row2["_diff"] = _diff;
		if (!isDiff && _diff) isDiff = true;
	}
	const keyValues = list2.map(x => x[key]);
	for(const row1 of list1.filter(x => !keyValues.includes(x[key]))) {
		const delRow = cloneDeep(row1);
		delRow["_diff"] = -1;
		list2.push(delRow);
		if (!isDiff) isDiff = true;
	}

	return isDiff;
};

export const DiffField = (props) => <HighlightField {...props} getHighlightValue={getDiffValue} />;
export const DiffList = (props) => <HighlightList {...props} cssFnRow={getDiffCssClasses} />;
