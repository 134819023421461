import React, {useState} from 'react';
import {
	TopToolbar,
	sanitizeListRestProps, CRUD_UPDATE_MANY_SUCCESS, FETCH_END, UPDATE,
} from 'react-admin';

import { Button } from 'react-admin';
import { useMutation } from 'react-admin';
import { useNotify } from 'react-admin';
import { useDispatch } from 'react-redux';

import { UserRole } from '../../../entities';
import { ArticlePreviewButton } from '../../../components/dialogs/ArticlePreviewButton';
import { ExportArticleButton } from './ExportArticleButton';
import { TERMINAL_ALL_VALUE } from '../../../components/filters/ToggleTerminalsFilter';

import { useRefresh } from 'react-admin';


export const ArticlesActions = (props) => {
	const {
		className,
		exporter,
		filterValues,
		onEdit,
		isEdit,
		...rest
	} = props;

	const notify = useNotify();
	const dispatch = useDispatch();
	const refresh = useRefresh();
	let isFilterTerminal = false;
	let terminalId = undefined;
	if (filterValues && filterValues["terminal_id"]) {
		terminalId = filterValues["terminal_id"][0];
		if (terminalId !== TERMINAL_ALL_VALUE) isFilterTerminal = true;
	}

	const [mutate, { loading }] = useMutation({
		type: 'updateManyArray',
		resource: props.resource,
	}, {
		withDeclarativeSideEffectsSupport: true,
		onSuccess: ({ data }) => {
			dispatch({
				type: CRUD_UPDATE_MANY_SUCCESS,
				payload: { data },
				meta: {
					resource: props.resource,
					notification: {
						body: 'ra.notification.updated',
						level: 'info',
						messageArgs: {
							smart_count: 1,
						}
					},
					fetchResponse: UPDATE,
					fetchStatus: FETCH_END
				},
			})
			props.onSuccess && props.onSuccess();
		},
		onFailure: (error) => {
			notify(error.message, "error");
		}
	});

	const onSubmit = () => {
		const payload = {
			ids: props.selectedIds,
			data: props.selectedFields,
		};
		mutate({ payload });
		props.cancelEdit()
		refresh();
	}

	const onCancel = () => {
		props.cancelEdit()
	}
	const isAdmin = props.permissions && props.permissions.role === UserRole.admin;
	
	return (
		<TopToolbar className={className} {...sanitizeListRestProps(rest)}>			
			{!isEdit ? <ExportArticleButton filters={filterValues} terminalId={terminalId} disabled={!isFilterTerminal} /> : null}
			{!isEdit ? <ArticlePreviewButton filters={filterValues} terminalId={terminalId} disabled={!isFilterTerminal}/> : null}
			{!isEdit && isAdmin ? <Button label="Edit" onClick={onEdit} disabled={!isFilterTerminal}/> : null}
			{isEdit && isAdmin ? <Button label="Apply changes" onClick={onSubmit} /> : null}
			{isEdit && isAdmin ? <Button label="Cancel" onClick={onCancel} /> : null}
		</TopToolbar>
	);
};
