import React from 'react';
import {
	CreateButton,
	useListContext,
	sanitizeListRestProps,
	TopToolbar,
} from 'react-admin';



export const MappersActions = (props) => {
    const { className, exporter, filters, ...rest } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext(props);

    return React.useMemo(
        () => (
            <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                <CreateButton basePath={basePath} />
            </TopToolbar>
        ),
        [resource, displayedFilters, filterValues, selectedIds, filters, total] // eslint-disable-line react-hooks/exhaustive-deps
    );
};
