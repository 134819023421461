import MuiButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import {
	BooleanInput,
	Button,
	Confirm,
	FormWithRedirect,
	ReferenceInput,
	SaveButton,
	SelectInput,
	required,
	useMutation,
	useNotify,
	useRefresh,
	useTranslate
} from 'react-admin';
import {useForm, useFormState} from 'react-final-form';

import { OrderStatus, OrderStatusChangeCustomer } from '../../entities';
import { OrderService } from '../../entities/OrderService';
import { systemName } from '../../utils/constants';
import { isInArray } from '../../utils/general';
import { dict2dict } from '../../utils/mapper';
import { useNotifyError } from '../../utils/notifiers/useNotifyError';
import { FormGroup, GridInput } from '../GridForm';
import { FormGroupGrid } from '../GridForm';
import { CompanySelect, DateInput, NumberInputEx, TimeInput } from '../inputs';
import { OrderReferenceInput } from '../inputs/OrderReferenceInput';
import TerminalDatesInput from '../inputs/TerminalDatesInput';
import { makeToggle } from './Toggle';

// const choicesArticle = [
// 	{id: 4000, name: '4000: Storage pallet cooler > 22 ppl'},
// 	{id: 4050, name: '4050: Storage cooler < 22 ppl'},
// ];
const articleCodes = [4000, 4050];

const [ ChangeCustomerProvider, useChangeCustomerToggle ] = makeToggle();

const ChangeCustomerFormBody = (props) => {
	const form = useForm();
	const { values } = useFormState();
	const translate = useTranslate();
	const notifyError = useNotifyError();
	const [trans, setTrans] = useState();
	// const [isArticle, setIsArticle] = useState(false);
	const [isConfirm, setIsConfirm] = useState(false);
	const [hasArticle, setHasArticle] = useState();

	const [fetchArticles, { loading }] = useMutation({
		type: 'getList',
		resource: "order-pricelist",
		payload: {
			filter: {
				id: props.record["id"],
			},
			pagination: { page: 1, perPage: -1 },
			sort: { field: 'id', order: 'DESC' }
		}
	}, {	
		onSuccess: (res) => {
			if (res.data) {
				const _hasArticle = res.data.some(x => articleCodes.includes(x["article_code"]) && x["created_by"] !== systemName );
				setHasArticle(_hasArticle);
			}
		},
		onFailure: (error) => {
			notifyError(error);
		}
	});

	useEffect(() => {
		if (props["needsArea"] && hasArticle === undefined && !loading) {
			fetchArticles();
		}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


	useEffect(() => {
		if (translate) {
			setTrans({
				"new-order.title": translate("resources.order-action.change-customer.new-order.title"),
				"new-order.company_id": translate("resources.order-action.change-customer.new-order.company_id"),
				"new-order.reference": translate("resources.order-action.change-customer.new-order.reference"),
				"new-order.etd_date": translate("resources.order-action.change-customer.new-order.etd_date"),
				"new-order.etd_time": translate("resources.order-action.change-customer.new-order.etd_time"),
				"old-order.title": translate("resources.order-action.change-customer.old-order.title"),
				"old-order.pallets": translate("resources.order-action.change-customer.old-order.pallets"),
				"old-order.kilos": translate("resources.order-action.change-customer.old-order.kilos"),
				"old-order.area_id": translate("resources.order-action.change-customer.old-order.area_id"),
				"old-order.article": translate("resources.order-action.change-customer.old-order.article"),
				"old-order.is_article": translate("resources.order-action.change-customer.old-order.is_article"),
				"old-order.days": translate("resources.order-action.change-customer.old-order.days"),
			});
		}		
    }, [translate]);

	if (!trans) return null;

	const onArticleChange = val => {
		if (hasArticle && val) {
			setIsConfirm(true);
		}
	};

	const handleConfirmClose = () => {
		form.change("old_is_article", false);
		setIsConfirm(false);
	};
	const handleConfirm = () => {
		setIsConfirm(false);
	};
	
	const needsOldSection = props["needsPallets"] || props["needsKilos"] || props["needsArea"];

	console.log("old_is_article = " + values["old_is_article"]);
	const body = (
		<FormGroup>
			<FormGroupGrid title={trans["new-order.title"]}>
				<GridInput source="new_company_id" component={CompanySelect} validate={[required()]} label={trans["new-order.company_id"]}
					filter={{ is_blocked: false, "id:ne" : props.record["company_id"]}}
				/>
				<GridInput source="new_reference" component={OrderReferenceInput} validate={[required()]} defaultValue={props.record.reference} label={trans["new-order.reference"]} />

				{props["needsEtdDate"] && <GridInput>
					<TerminalDatesInput source="new_etd_date" component={DateInput} validate={[required()]} label={trans["new-order.etd_date"]} />
				</GridInput>}
				{props["needsEtdTime"] && <GridInput>
					<TerminalDatesInput source="new_etd_time" component={TimeInput} validate={[required()]} label={trans["new-order.etd_time"]} />
				</GridInput>}
			</FormGroupGrid>

			{needsOldSection && <FormGroupGrid title={trans["old-order.title"]}>
				{props["needsPallets"] && <GridInput sm={12} component={NumberInputEx} source="old_pallets" min={0} max={100} step={10} validate={[required()]} label={trans["old-order.pallets"]} />}
				{props["needsKilos"] && <GridInput sm={12} component={NumberInputEx} type="float" digitsCount={2} source="old_kilos" min={0} max={1000} step={100} validate={[required()]} label={trans["old-order.kilos"]} />}

				{props["needsArea"] && <Grid item sm={12}>
					<ReferenceInput source="old_area_id" reference="areas" filter={{ terminal_id: [props.record['terminal_id']], disabled: false }} allowEmpty validate={[required()]} label={trans["old-order.area_id"]}>
						<SelectInput optionText="name" disableValue="disabled_by_any" fullWidth />
					</ReferenceInput>
				</Grid>}

				{/* {props["needsArea"] && <GridInput sm={12} component={SelectInput} source="old_article" choices={choicesArticle} allowEmpty onChange={onArticleChange} label={trans["old-order.article"]} />} */}
				{props["needsArea"] && hasArticle !== undefined && <GridInput sm={12} component={BooleanInput} source="old_is_article" onChange={onArticleChange} label={trans["old-order.is_article"]} />}
				
				{values["old_is_article"] && <GridInput sm={12} component={NumberInputEx} source="old_days" min={1} max={100} step={1} validate={[required()]} label={trans["old-order.days"]} />}
			</FormGroupGrid>}
			<Confirm
				isOpen={isConfirm}
				title="osfc.buttons.confirm"
				content="resources.order-action.change-customer.confirm-article.content"
				onConfirm={(handleConfirm)}
				onClose={handleConfirmClose}
				confirm="osfc.buttons.confirm"
			/>			
		</FormGroup>
		);
	return body;
};

const ChangeCustomerForm = ({ children, ...props }) => {
	const notify = useNotify();	
	const refresh = useRefresh();

	const order_id = props.record["id"];
	const terminal_id = props.record["terminal_id"];

	const [approve, { isLoading }] = useMutation({
        type: 'create',
        resource: `orders/${order_id}/change-customer`,
    }, {
		onSuccess: () => {
			props.onSuccess && props.onSuccess();
			refresh();
		},
		onFailure: (error) => {
			props.onCancel && props.onCancel();
			notify(error.message, "error");
			refresh();
		}
	});

	const handleSubmit = (values) => {
		approve({
			payload: {
				data: {
					...props.record,
					origin_id: props.record["id"],
					new_company_id : values.new_company_id,
					new_reference : values.new_reference,
					new_etd_date : values.new_etd_date,
					new_etd_time : values.new_etd_time,
					old_pallets : values.old_pallets,
					old_kilos : values.old_kilos,					
					old_area_id : values.old_area_id,
					// old_article : values.old_article,
					old_is_article : values.old_is_article,
					old_days : values.old_days,					
				},
			}
		})
	}
	
	return (
		<FormWithRedirect
			submitOnEnter={false}
			component={DialogContent}
			save={handleSubmit}
			render={({ handleSubmitWithRedirect, pristine, saving, record, ...rest }) => {								
				const body = <ChangeCustomerFormBody terminal_id={terminal_id} {...props} />
				const buttons = (
					<>
						{props.onCancel && <Button label="ra.action.cancel" onClick={props.onCancel} disabled={isLoading} color="secondary" />}
						<SaveButton
							// size="small"
							label="Chnage"
							handleSubmitWithRedirect={handleSubmitWithRedirect}
							pristine={pristine}
							saving={saving}
							disabled={isLoading}
						/>
					</>
				);

				return children && typeof children === "function" ? children({body, buttons}) : <>
					{body}
					{buttons}
				</>;
			}}
		/>
	);
}

const ChangeCustomerDialog = (props) => {
	const t = useTranslate();
	const modal = useChangeCustomerToggle();

	return (
		<ChangeCustomerForm {...props} onSuccess={modal.close} onCancel={modal.close}>
			{({ body, buttons }) => (
				<Dialog open={modal.opened} onClose={modal.close}>
					<DialogTitle>{t("osfc.dialogs.change_customer.title")}</DialogTitle>
					<DialogContent>{body}</DialogContent>
					<DialogActions>{buttons}</DialogActions>
				</Dialog>
			)}
		</ChangeCustomerForm>
	);
}
const ChangeCustomerTrigger = (props) => {
	const t = useTranslate();
	const notify = useNotify();
	const modal = useChangeCustomerToggle();

	const handleOpenModal = e => {
		if (e) e.stopPropagation();

		if (props["warnMsg"]) {
			notify(props["warnMsg"], "error");			
		} else {
			props.onClick && props.onClick();
			modal.open();
		}
		
	};

	const btnDisabled = !isInArray(OrderStatusChangeCustomer, props.record.status) || (props.record.service === OrderService.reloadCarCar && props.record.status >= OrderStatus.LOADING_STARTED);

	return ( props.children ?
		React.cloneElement(props.children, {onClick: handleOpenModal}) :
		<MuiButton size="small" color='primary' variant="outlined" disabled={btnDisabled} onClick={handleOpenModal}>{t("osfc.dialogs.change_customer.change_customer")}</MuiButton>
	);
}


export const ChangeCustomerButton = (props) => {
	const t = useTranslate();
	const [data, setData] = useState();

	useEffect(() => {
		if (props.record) {
			const _data = {
				...props.record,
				...dict2dict(props.record, {
					"reference": "new_reference",
					"etd_date": "new_etd_date",
					"etd_time": "new_etd_time",
					"area_id": "old_area_id"
				})
			};
			setData(_data);
		}		
    }, [props.record]);

	if (!data) return null;

	const params = {
		record: data
	};
	if (props.record.service === OrderService.intoPlukkStorage) {
		if (props.record.status === OrderStatus.PLUKK_COMPLETED) {
			params["needsEtdDate"] = true;
			params["needsEtdTime"] = true;

			params['needsPallets'] = !props.record['pallets'];
			params['needsKilos'] = !props.record['kilos'];
						
			params["needsArea"] = true;
		} else {
			params["warnMsg"] = t("osfc.dialogs.change_ownership.msg.plukk_must_completed");			
		}
	}

	return <ChangeCustomerProvider>
		<ChangeCustomerTrigger {...params} />
		<ChangeCustomerDialog {...params} />
	</ChangeCustomerProvider>;
};