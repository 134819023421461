import React from "react";
import { ReferenceField, SelectInput, TextField } from "react-admin";

import { ReferenceEditableField } from "../../../components/fields/EditableField";
import { BooleanField } from "../../../components/fields/EqualField";
import { LongTextField } from "../../../components/fields/LongTextField";
import { ObjectChipField } from "../../../components/fields/ObjectChipField";
import { SelectDisabledInput } from "../../../components/inputs/SelectUppercaseInput";
import {
	EntityType,
	OrderServices,
	OrderStatusCustomerEdit,
	TaskServices,
} from "../../../entities";
import { getStatusAllowedIds } from "../../orders/forms/FormManager";

export const MatchStatusField = ({ permissions, isAppManager, isOSFCUser, ...rest }) => {
	if (!rest.record) return null;

	if (rest.record.type === EntityType.order) {
		return (
			<ReferenceEditableField
				optimized={true}
				editable={isAppManager}
				permissions={permissions}
				source="status"
				reference="order-statuses"
				additionalParams={["set_order_completed"]}
				editField={
					isOSFCUser ? (
						(rec) => {
							const statusAllowedIds = getStatusAllowedIds(rec, permissions.role);
							return statusAllowedIds ? (
								<SelectDisabledInput allowedIds={statusAllowedIds} optionText="name" />
							) : (
								<SelectInput optionText="name" />
							);
						}
					) : (
						<SelectDisabledInput allowedIds={OrderStatusCustomerEdit} optionText="name" />
					)
				}
				{...rest}
			>
				<TextField source="status_name" />
			</ReferenceEditableField>
		);
	}
	if (rest.record.type === EntityType.task) {
		return (
			<ReferenceEditableField
				optimized={true}
				editable={isAppManager}
				permissions={permissions}
				source="status"
				reference="task-statuses"
				additionalParams={["set_order_completed"]}
				editField={<SelectInput optionText="name" />}
				{...rest}
			>
				<TextField source="status_name" />
			</ReferenceEditableField>
		);
	}
	return null;
};

const servicesByEntityType = {
	[EntityType.order]: OrderServices,
	[EntityType.task]: TaskServices,
};

export const MatchServiceField = (props) => {
	if (!props.record) return null;

	return (
		<ObjectChipField
			source="service"
			object={servicesByEntityType[props.record.type]}
			titleKey="short_name"
			tooltipKey="full_name"
			{...props}
		/>
	);
};

export const MatchCompanyField = (props) => {
	if (!props.record) return null;
	if (props.record.type === EntityType.order) {
		return (
			<ReferenceField reference="companies" source="company_id" link={false} {...props}>
				<TextField source="full_name" />
			</ReferenceField>
		);
	}
	if (props.record.type === EntityType.task) {
		const staticRecord = { company: "OSFC" };
		return <TextField source="company" {...props} record={staticRecord} />;
	}
};

const notesSourceByEntityType = {
	[EntityType.order]: "notes",
	[EntityType.task]: "description",
};

export const MatchNotesField = (props) => {
	if (!props.record) return null;
	return (
		<LongTextField
			source={notesSourceByEntityType[props.record.type]}
			sortable={false}
			{...props}
		/>
	);
};

const internalNotesSourceByEntityType = {
	[EntityType.order]: "internal_notes",
	[EntityType.task]: "notes",
};

export const MatchInternalNotesField = (props) => {
	if (!props.record) return null;
	return (
		<LongTextField
			source={internalNotesSourceByEntityType[props.record.type]}
			sortable={false}
			{...props}
		/>
	);
};

const TripNumberOrIsRecurringFieldByEntityType = {
	[EntityType.order]: <TextField source="trip_number" />,
	[EntityType.task]: <BooleanField source="is_recurring" />,
};

export const TripNumberOrIsRecurringField = (props) => {
	if (!props.record) return null;
	const Component = TripNumberOrIsRecurringFieldByEntityType[props.record.type];
	const modifiedRecord = { ...props.record, is_recurring: !!props.record.recurring_task_id };
	return React.cloneElement(Component, {
		...props,
		record: modifiedRecord,
		sortable: false,
	});
};
