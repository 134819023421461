import React from 'react';
import { 
    maxLength, required, 
    TextInput, ReferenceInput, SelectInput, 
} from 'react-admin';

import { GridForm, GridInput, FormGroupGrid } from '../../../components/GridForm';
import { makeFormatter } from '../../../components/formatter';
import { JsonTextInput } from '../../../components/fields/JsonTextInput';
import { SelectEnumInput } from '../../../components/inputs/SelectEnumInput';
import { DirectionType } from '../../../entities/DirectionType';


const cameraInputFormatter = (value) => makeFormatter([{ char: /[\d]/, repeat: 2 }])(value);
const CameraInput = ({ validate = [], ...props }) => (
    <TextInput 
        {...props}
        parse={cameraInputFormatter} 
        validate={[maxLength(2), ...validate]} 
    />
);

export const VehicleANPRCamerasForm = (props) => {
    return (
        <GridForm {...props}>
            <FormGroupGrid>
                <GridInput 
                    xs={4} 
                    source='camera_id' 
                    validate={[required()]} 
                >
                    <CameraInput />
                </GridInput>

                <GridInput xs={4}>
                    <ReferenceInput 
                        source="terminal_id" 
                        reference="terminal"
                        validate={[required()]}
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </GridInput>

                <GridInput 
                    component={SelectEnumInput} 
                    source="force_vehicle_direction_type"  
                    enumName="vehicle_direction_type" 
                    enumObj={DirectionType} 
                    allowEmpty
                    defaultValue={null}
                    xs={4} 
                />
            </FormGroupGrid>

            <FormGroupGrid>
                <GridInput 
                    xs={12}
                    validate={[required()]}
                >
                    <JsonTextInput 
                        source="ips" 
                        jsonString={false}
                        label='resources.vehicle-anpr-cameras.fields.ips' 
                    />
                </GridInput>
            </FormGroupGrid>
        </GridForm>
    );
};
