import React from 'react';

import { Filter, useTranslate, useListContext, useNotify } from "react-admin";

import { ToggleTerminalsFilter, TERMINAL_ALL_VALUE } from '../../../components/filters/ToggleTerminalsFilter';
import { GroupingPeriodFilter } from './GroupingPeriodFilter';
import {DateInput} from '../../../components/inputs/DateTimeInput';
import { getDateWithDayOffset, getDateWithMonthOffset } from '../../../utils/datetime';
import { useTerminalsContext } from '../../../contexts/TerminalsProvider';


export const RevenueAndExpensesChartFilter = (props) => {
	const translate = useTranslate()
	const notify = useNotify();

	const { filterValues, setFilters } = useListContext();

	const { data: terminals, ids: terminalIds, isDifferentTZ } = useTerminalsContext();
	const onGroupingPeriodChange = (groupingPeriod) => {
		let terminalId = filterValues.terminal_id[0];
		if (terminalId === TERMINAL_ALL_VALUE) {
			terminalId = terminalIds[0];
			if (isDifferentTZ)
				notify(
					`Time zones of terminals differ and data will be shown in time zone of first terminal ${terminals[terminalId].time_zone}`,
					"warning",
					{
						type: "warning",
						autoHideDuration: 60000, // TODO doesn't work!
					}
				);
		}
		
		const terminal = terminals[terminalId];
		let newDates = {};

		if (groupingPeriod[0] === 'month') {
			newDates = {
				"date_from": getDateWithMonthOffset(terminal, 12, true),
				"date_to": getDateWithMonthOffset(terminal),
			};
		} else if (groupingPeriod[0] === 'week') {
			newDates = {
				"date_from": getDateWithDayOffset(terminal, 7 * 12, true),
				"date_to": getDateWithDayOffset(terminal),
			};
		} else if (groupingPeriod[0] === 'day') {
			newDates = {
				"date_from": getDateWithDayOffset(terminal, 7, true),
				"date_to": getDateWithDayOffset(terminal),
			};
		}

		setFilters({
			...filterValues,
			grouping_period: groupingPeriod, 
			...newDates,
		});
	};

	return ( <Filter {...props}>
		<GroupingPeriodFilter source="grouping_period" defaultValue={null} onChange={onGroupingPeriodChange} alwaysOn key={"group_period"}/>
		<ToggleTerminalsFilter source="terminal_id" defaultValue={null} alwaysOn key={"terminal_id"}/>
		{[
			<DateInput 
				source="date_from"
				label={translate("osfc.filters.dates.date_from")}
				margin="dense"
				key={"date_from"}
				alwaysOn
			/>,
			<DateInput
				source="date_to"
				label={translate("osfc.filters.dates.date_to")}
				margin="dense"
				key={"date_to"}
				alwaysOn
			/>,
		 ]
		}
	</Filter>
)};
