import OwnerCompanyDataIcon from '@material-ui/icons/AccountBoxOutlined';

import { OwnerCompanyDataEdit } from './Edit/Edit';
import { OwnerCompanyDataList } from './List/List';


export {
    OwnerCompanyDataList,
    OwnerCompanyDataEdit,
};

export const ownerCompanyDataResource = {
    list: OwnerCompanyDataList,
    edit: OwnerCompanyDataEdit,
    icon: OwnerCompanyDataIcon,
};
