import React from 'react';
import { Edit, useRedirect, useRefresh } from 'react-admin';

import { OwnerCompanyDataForm } from '../forms/OwnerCompanyDataForm';
import { SimpleFormAdv } from '../../../components/forms/SimpleFormAdv';


export const OwnerCompanyDataEdit = (props) => {
    const redirect = useRedirect();

    const onSuccess = () => {
        redirect(props.basePath);
        window.location.reload();
    };

    const toolbarCompProps = {
		useSaveButtonEx: true,
		saveButtonExProps: { onSuccess },
	};

    return (
        <Edit {...props} undoable={false}>
            <SimpleFormAdv toolbarCompProps={toolbarCompProps}>
                <OwnerCompanyDataForm />
            </SimpleFormAdv>
        </Edit>
    );
};
