export const UserRole = {
    admin: "admin",
    app_manager: "app_manager",

    osfc_manager: "osfc_manager",
    osfc_employee: "osfc_employee",

    customer_manager: "customer_manager",
    customer_employee: "customer_employee",

	customer_and_transporter_manager: "customer_and_transporter_manager",
	customer_and_transporter_employee: "customer_and_transporter_employee",
	customer_and_transporter_driver: "customer_and_transporter_driver",

    transporter_manager: "transporter_manager",
	transporter_employee: "transporter_employee",
    transporter_driver: "transporter_driver",
}

export const AppRoles = [UserRole.admin, UserRole.app_manager];

export const AppManagerRoles = [...AppRoles, UserRole.osfc_manager];

export const OsfcRoles = [UserRole.osfc_manager, UserRole.osfc_employee];

export const OSFCUserRoles = new Set([
    UserRole.osfc_manager,
    UserRole.osfc_employee,
    UserRole.app_manager,
    UserRole.admin
]);

export const CompanyManagerRoles = [UserRole.customer_manager, UserRole.customer_and_transporter_manager, UserRole.transporter_manager];

export const CustomerRoles = [
    UserRole.customer_manager, UserRole.customer_employee    
];
export const CustomerAndTransporterRoles = [
    UserRole.customer_and_transporter_manager, UserRole.customer_and_transporter_employee, UserRole.customer_and_transporter_driver
];
export const TransporterRoles = [    
    UserRole.transporter_manager, UserRole.transporter_employee, UserRole.transporter_driver
];
export const AllCustomerRoles = [
    ...CustomerRoles,
    ...CustomerAndTransporterRoles,
    ...TransporterRoles
];

export const DriverRoles = [UserRole.customer_and_transporter_driver, UserRole.transporter_driver];

// TODO: remove it and simple variables
export const UserRoleByCompanyType = {
    customer_and_transporter_roles: [...CustomerAndTransporterRoles],
    transporter_all_roles:[...CustomerAndTransporterRoles, ...TransporterRoles]
}

export const hasAccessToExport = (permissions) => permissions && (permissions.access_to_export || permissions.role === UserRole.admin);
