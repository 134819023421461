import simpleRestProvider from 'ra-data-simple-rest';
import { fetchUtils } from 'react-admin';

const rName = (resource, from, to) => resource === from ? to : resource;

const simpleRestProviderEx = (apiUrl, httpClient = fetchUtils.fetchJson) => {
    const _simpleRestProvider = simpleRestProvider(apiUrl, httpClient);

    return {
        ..._simpleRestProvider, 
        getList: (resource, params) => 
            _simpleRestProvider.getList(
                rName(resource, "draft-orders", "orders"), 
                params
            ),

        updatePartial: (resource, params) => 
            httpClient(`${apiUrl}/${rName(resource, "draft-orders", "orders")}/${params.id}`, {
                method: 'PATCH',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({ data: json })),

        updateManyPartial: (resource, params) =>
            httpClient(`${apiUrl}/${resource}`, {
                method: 'PATCH',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({ data: json })),

        updateManyArray: (resource, params) =>
            httpClient(`${apiUrl}/${resource}`, {
                method: 'PUT',
                body: JSON.stringify({ ids: params.ids, data: params.data }),
            }).then(({ json }) => ({ data: json })),

        updateCustom: (resource, params) =>
            httpClient(`${apiUrl}/${resource}${params.id && "/"}${params.id}`, {
                method: "PUT",
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({ data: json })),

        createMany: (resource, params) =>
            httpClient(`${apiUrl}/${resource}`, {
                method: 'POST',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({ data: json })),
    };    
};


export default simpleRestProviderEx;