import './styles.css';

import union from 'lodash/union';
import React from "react";
import {NumberInput, ReferenceInput, SelectInput, TextInput, maxLength, translate, useDataProvider, useFormContext, usePermissions, useTranslate} from 'react-admin';
import { useForm, useFormState } from 'react-final-form';

import { formatPhoneNumber } from '../../../../../components/fields/PhoneField';
import { FormGroupGrid, GridForm, GridInputMultiChanges as GridInput, MultiChangesStateEnum, isMultiDiff } from '../../../../../components/GridForm';
import { CompanySelect, CompanyText, DateInput, NumberInputEx, PhoneInput, TimeInput } from '../../../../../components/inputs';
import { OrderReferenceInput } from '../../../../../components/inputs/OrderReferenceInput';
import { OSFCUserSelect } from '../../../../../components/inputs/OSFCUserSelect';
import { PortInput } from "../../../../../components/inputs/PortInput";
import { SelectEnumInput } from "../../../../../components/inputs/SelectEnumInput";
import { SelectTextInput } from '../../../../../components/inputs/SelectTextInput';
import { SelectDisabledInput, SelectUppercaseInput } from '../../../../../components/inputs/SelectUppercaseInput';
import TerminalDatesInput from "../../../../../components/inputs/TerminalDatesInput";
import {ReadOnlyInput} from '../../../../../components/inputs/TextReadonlyInput';
import { YesNoInput } from '../../../../../components/inputs/YesNoInput';
import {
	LabelSource,
	OrderService,
	OrderStatus,
	OrderStatusEditOrderCustomerAndReference,
	OrderStatusEditOrderTerminal,
	OrderStatusOnCompleteEdit,
	UserRole,
	UserRoleByCompanyType
} from "../../../../../entities";
import { isNullUndEmpty }from "../../../../../utils/general"
import { validatePlaceLength } from "../../../../../utils/validation/order";
import {required} from '../../../../../utils/validation/required';
import {requiredIfNoFieldValue} from '../../../../../utils/validation/requiredIfNoFieldValue';
import { CommodityInput } from '../../../forms/inputs/CommodityInput';
import { LoadingOrderInput } from '../../../forms/inputs/LoadingOrderInput';
import { PrintedDocsInput } from "../../../forms/inputs/PrintedDocsInput";
import { ServiceInput } from "../../../forms/inputs/ServiceInput";
import { TransporterInput } from '../../../forms/inputs/TransporterInput';
import { TripNumberInput } from '../../../forms/inputs/TripNumberInput';
import {
	ArrivedPopup,
	DangerTextInputForInternalNotes,
	useXPartyChange,
	ETAETDFormGroup,
	ResponsiveIconButton,
	etaEtdPorts,
	use3dPartyChange,
	useEtaEtdSync
} from '../../../forms/shared';

const plugFunc = () => {};


export const ETAETDBlock = ({isAddMode, prefix, title, byTerminalFilter, requiredMulti, ...props}) => {
	const { form } = useForm();

	const { values } = useFormState();
	// const companyId = values.company_id || props.companyId;
	// const handleDriverChange = useDriverChange(prefix);
	const [handle3dPartyChangeOnTruck, handle3dPartyChangeOnTrailer, handleXPartyChangeOnDriver] = useXPartyChange(prefix);
	const handleEtaEtdSync = useEtaEtdSync(prefix);
	// const drivers = useSelector(driversSelector);

	//const isDisabledForEditing = !!values[`${prefix}_3rd_party`];
	const curr_3rd_party_ids = (isNullUndEmpty(values[`${prefix}_3rd_party`]) && (props.multiData.recordData.state[`${prefix}_3rd_party`] & MultiChangesStateEnum.IS_INIT_DIFF)) ? props.multiData.recordData.multiValues[`${prefix}_3rd_party`] : [values[`${prefix}_3rd_party`]];
	const isDisabledForEditing = curr_3rd_party_ids.some(x => !isNullUndEmpty(x));
	
	const isIntoPlukkStorage = values.service === OrderService.intoPlukkStorage;

	const dateRequiredValueKeyPrefix = prefix === 'eta' ? 'etd' : 'eta';
	const portKey = etaEtdPorts[prefix];

	// const currentDriver = drivers[values[`${prefix}_driver_id`]];
	const currentTransportFilter = {
		no_range: true,
		is_deleted: false,
		company_id: 0
	};
	const currentTransportSort = { field: "name", order: "ASC" };

	// const currentTransporter = values[`${prefix}_3rd_party`];
	const currentDriverFilter = {		
		no_range: true,
		is_blocked: false,
		is_deleted: false,
		company_id: 0
	};
	const currentDriverSort = { field: "first_name", order: "ASC" };

	// const isNot3rdPartyRole = UserRoleByCompanyType.customer_and_transporter_roles.indexOf(props.permissions.role) === -1

	const placePrefix = prefix === "eta" ? "from" : "to";

	const handle3dPartyChange = (e) => {
		handle3dPartyChangeOnTruck(e);
		handle3dPartyChangeOnTrailer(e);
		handleXPartyChangeOnDriver(e);
	};

	const transKey = `${prefix}_3rd_party`;
	const curr_company_ids = (isNullUndEmpty(values.company_id) && (props.multiData.recordData.state["company_id"] & MultiChangesStateEnum.IS_INIT_DIFF)) ? props.multiData.recordData.multiValues["company_id"] : [values.company_id];
	const transIsDisable = curr_company_ids.some(x => isNullUndEmpty(x));
	const transIsEmptyDisable = transIsDisable && isNullUndEmpty(values[transKey]);

	return (
		<ETAETDFormGroup {...props} prefix={prefix}>
			<FormGroupGrid>
				<GridInput xs={6} sm={3}>
					<TerminalDatesInput component={DateInput} source={`${prefix}_date`}
						   validate={[isIntoPlukkStorage ? requiredIfNoFieldValue(`${dateRequiredValueKeyPrefix}_date`) : requiredMulti]}
						   onChange={handleEtaEtdSync} />
				</GridInput>
				<GridInput xs={6} sm={3}>
					<TerminalDatesInput component={TimeInput} source={`${prefix}_time`} resettable />	
				</GridInput>			
				<GridInput xs={6} sm={3}>
					<TerminalDatesInput component={TimeInput} source={`${prefix}_slot_time`} resettable />
				</GridInput>
				<GridInput xs={6} sm={2} component={PortInput} source={portKey} 
						   hidden={isAddMode} filter={byTerminalFilter} resettable />
			</FormGroupGrid>
			<FormGroupGrid>
				{/* <GridInput xs={12} sm={6} md={6} component={TransporterInput} source={`${prefix}_3rd_party`} prefix={prefix} disabled={!values.company_id} updateDependency={companyId} companyId={companyId} /> */}
				<GridInput xs={12} sm={5} md={5} component={TransporterInput} source={`${prefix}_3rd_party`} prefix={prefix} isEmptyDisable={transIsEmptyDisable} excludeIds={curr_company_ids} disabled={transIsDisable} onChange={handle3dPartyChange} resettable />
				{/* <SelectTextInput xs={12} sm={4} md={4} sourceText={`${prefix}_driver`} sourceSelect={`${prefix}_driver_id`} reference="drivers" 
								 optionText={record => `${record.first_name} ${record.last_name} | ${formatPhoneNumber(record.phone)}`} 
								 filter={companyId && currentDriverFilter} disabled={true}
								 validate={[maxLength(64)]} onChange={handleDriverChange} />  */}
				<GridInput  xs={12} sm={5} md={5} component={TextInput} source={`place_${placePrefix}`} validate={[validatePlaceLength]} resettable />
				<GridInput xs={12} sm={4} disabled={prefix === "eta"}>
					<ReferenceInput
						source={`${prefix}_external_terminal_guid`}
						reference="external-terminal"
						sort={{ field: "guid", order: "ASC" }}
						allowEmpty
					>
						<SelectInput optionText="name" />
					</ReferenceInput>
				</GridInput>
				<GridInput xs={6} sm={2} md={2} component={YesNoInput} source={`${prefix}_show_doc_for_trans`}
					label={"resources.orders.fields." + prefix + "_show_doc"} defaultValue={true} disabled={!isDisabledForEditing}/>
				<GridInput xs={6} sm={2} md={2} component={YesNoInput} source={`is_${prefix}_3rd_party_reload`}
					label={"resources.orders.fields." + "is_" + prefix + "_3rd_party_reload"} defaultValue={true} disabled={!isDisabledForEditing}/>
				<GridInput xs={12} sm={6} md={6} component={SelectTextInput} sourceText={`${prefix}_driver`} sourceSelect={`${prefix}_driver_id`} reference="drivers" 
							optionText={record => `${record.first_name} ${record.last_name} | ${formatPhoneNumber(record.phone)}`}
							filter={currentDriverFilter} sort={currentDriverSort} disabled={isDisabledForEditing} isMultiDataParent={true} resettable />
				{/* <GridInput xs={12} sm={6} md={6} component={SelectTextInput} sourceText={`${prefix}_driver`} sourceSelect={`${prefix}_driver_id`} reference="drivers" 
								 filter={companyId && currentDriverFilter} disabled={true} /> */}
				<GridInput xs={12} sm={6} md={6} component={PhoneInput} source={`${prefix}_driver_phone`} disabled={isDisabledForEditing} resettable/>
				<GridInput xs={12} sm={6} md={6} component={SelectTextInput} label={"resources.orders.fields." + prefix + "_truck"} 
								 sourceText={`${prefix}_truck`} sourceSelect={`${prefix}_truck_id`} reference="trucks" source3rdParty={`${prefix}_3rd_party`}
								 filter={currentTransportFilter} sort={currentTransportSort} disabled={isDisabledForEditing} validate={[maxLength(64)]} isMultiDataParent={true} resettable />
				<GridInput xs={12} sm={6} md={6} component={SelectTextInput} label={"resources.orders.fields." + prefix + "_trailer"} 
								 sourceText={`${prefix}_trailer`} sourceSelect={`${prefix}_trailer_id`} reference="trailers" source3rdParty={`${prefix}_3rd_party`}
								 filter={currentTransportFilter} sort={currentTransportSort} disabled={isDisabledForEditing} validate={[maxLength(64)]} isMultiDataParent={true} resettable />
			</FormGroupGrid>
		</ETAETDFormGroup>
	);
}

const resetVal = undefined;

const getDiffKeys = (obj1, obj2) => {
	const keys = union(Object.keys(obj1), Object.keys(obj2));
	return keys.filter(x => obj1[x] !== obj2[x]);	
};

const fieldRelation = {
	"terminal_id": ["port_in", "prot_out", "area_id"],
	"eta_3rd_party": ["eta_driver_id", "eta_truck_id", "eta_trailer_id", "eta_driver", "eta_truck", "eta_trailer"],
	"etd_3rd_party": ["etd_driver_id", "etd_truck_id", "etd_trailer_id", "etd_driver", "etd_truck", "etd_trailer"],
	"company_id": ["eta_3rd_party", "etd_3rd_party"]
}
fieldRelation["company_id"] = [...fieldRelation["company_id"], ...fieldRelation["eta_3rd_party"], ...fieldRelation["etd_3rd_party"]];

export const OSFCForm = ({ isAddMode, initRecordData, ...props }) => {
	const form = useForm();
	const { permissions } = usePermissions();
	
	// const [ render, setRender ] = React.useState(0);
	const [ isTerminalDisabled, setIsTerminalDisabled ] = React.useState(true);
	const [ canChangeCustomerAndReference, setCanChangeCustomerAndReference ] = React.useState(false);
	const [ enabledFields, setEnabledFields ] = React.useState();

	const onFormChange = (formState, a, b) => {
		console.log(formState);
	};

	const { values, initialValues } = useFormState({onChange: onFormChange});
	const [ recordData, seRecordData ] = React.useState(initRecordData);
    const dataProvider = useDataProvider();
	const translate = useTranslate();

	React.useEffect(()=>{
		if (initRecordData && initRecordData.multiValues && initRecordData.multiValues["status"]) {
			const _canChangeTerminal = initRecordData.multiValues["status"].every(x => OrderStatusEditOrderTerminal.includes(x));
			setIsTerminalDisabled(!_canChangeTerminal);

			const _canChangeCustomerAndReference = initRecordData.multiValues["status"].every(x => OrderStatusEditOrderCustomerAndReference.includes(x));
			setCanChangeCustomerAndReference(_canChangeCustomerAndReference);

			const _canChangeStatus = initRecordData.multiValues["status"].some(x => x === OrderStatus.ORDER_COMPLETED);
			if (_canChangeStatus) setEnabledFields({status: true});
		}
    }, [initRecordData]);
	
	React.useEffect(()=>{
		seRecordData((prev) => {
			const diffKeys = getDiffKeys(prev.values, values);
			const newState = {...prev.state};

			for(const key of diffKeys) {
				const isDiff = isMultiDiff(prev.initialValues[key], values[key], prev.state[key]);
				if (isDiff) newState[key] |= MultiChangesStateEnum.IS_DIFF;
				else newState[key] &= ~MultiChangesStateEnum.IS_DIFF;

				if (fieldRelation[key]) {
					fieldRelation[key].forEach(x => {
						if (isDiff) newState[x] |= MultiChangesStateEnum.NOT_REVERT;
						else newState[x] &= ~MultiChangesStateEnum.NOT_REVERT;
					});
				}
			}

			const newObj = {
				...prev,
				state: newState,
				values
			};
			return newObj;
		});
    }, [values]);

	React.useEffect(()=>{
		seRecordData((prev) => ({
			...prev,
			initialValues
		}));
    }, [initialValues]);

	const onChangeTest = val => {
		// console.log(val);
	};

	const on3dPartyRest = prefix => {
		form.change(`${prefix}_driver`, resetVal);
		form.change(`${prefix}_driver_id`, resetVal);
		form.change(`${prefix}_truck`, resetVal);
		form.change(`${prefix}_truck_id`, resetVal);
		form.change(`${prefix}_trailer`, resetVal);
		form.change(`${prefix}_trailer_id`, resetVal);		
	};

	const onCompanyChange = val => {
		if (val){
			dataProvider.getOne("customer-companies", { id: val})
				.then((data) => {
					form.change("commodity", data.data.commodity);
					form.change("plumb_seal", data.data.plumb_seal);
					form.change("terminal_id", data.data.terminal_id);
					onTerminalChange(data.data.terminal_id);
					form.change("set_order_completed", data.data.set_order_completed);
					form.change("is_copy_documents", data.data.copy_docs_on_clone)
				})
		} else{
			form.change("commodity", 'salmon');
			// form.change("area_id", null);
			form.change("plumb_seal", false);
			form.change("terminal_id", resetVal);
			onTerminalChange(resetVal);
			form.change("set_order_completed", false);
			form.change("is_copy_documents", false)
		}
		form.change("eta_3rd_party", resetVal);
		form.change("etd_3rd_party", resetVal);
		on3dPartyRest("eta");
		on3dPartyRest("etd");
	};

	const onTerminalChange = val => {		
		form.change("port_in", resetVal);
		form.change("port_out", resetVal);
		form.change("area_id", resetVal);
		form.change("is_area_blocked",  false);
	};

	React.useEffect(()=>{
        if (isAddMode && props.record && props.record.company_id){
            dataProvider.getOne("customer-companies", { id: props.record.company_id})
            .then((data) => {
                form.change("is_copy_documents", data.data.copy_docs_on_clone)
            });
        }
    }, [isAddMode, props.record])

	const onIsDocForNfsaChange = React.useCallback(item => {
		const englishText = "\nDocuments MUST be obtained from the Norwegian Food Safety Authority";
		const norwegianText = "\nDokumenter MÅ hentes på Mattilsynet";
		const old_internal_notes_text = values.internal_notes ? values.internal_notes : ""
		const newValueInternalNotes = item.target.value ? old_internal_notes_text + englishText + norwegianText :
			old_internal_notes_text.replace(englishText + norwegianText, "");
		form.change("internal_notes", newValueInternalNotes);
	}, [values.internal_notes, form]);

	if (!permissions) return null;

	// const canChangeCustomerAndReference = (
	// 	isAddMode
	// 	|| initialValues.status === OrderStatus.LOAD_IN_TERMINAL_STORAGE
	// 	|| initialValues.status === OrderStatus.BOOKED
	// );

	// let statusDisabled = false;

	// let enabledFields;

	// if (props.record.status === OrderStatus.ORDER_COMPLETED) {
	// 	if (permissions.role === UserRole.osfc_manager) enabledFields = {status: true};
	// 	else enabledFields = {};
	// }

	// const isTerminalDisabled = values["status"] ? 
	// 	!OrderStatusEditOrderTerminal.includes(values["status"]) : false;

	const byTerminalFilter = { terminal_id: [values["terminal_id"]] };

	const handleLabelSourceChange = (event) =>{
		const val = event.target.value
		if(!val){
			form.change("label", null)
		} else if(val === LabelSource.pallets){
			form.change("label", values.pallets)
		} else if(val === LabelSource.boxes){
			form.change("label", values.boxes)
		}
	}

	// const statusDisabled = (permissions.role !== UserRole.osfc_manager) && (
	// 	(props.record.status === OrderStatus.ORDER_COMPLETED)
	// );
	// const enabledFields = initialValues.status === OrderStatus.ORDER_COMPLETED ? {status: true} : undefined
	
	const handleAreaChange = (event) => {
		if(event.target.value){
			form.change("is_area_blocked",  true)
		} else{
			form.change("is_area_blocked",  false)
		}
	};

	const handleCbMulti = (type, key) => {
		switch (type) {
			case "revert":
				form.change(key, initialValues[key]);
				// if (recordData.state[key] & MultiChangesStateEnum.IS_DIFF) {
				// 	recordData.state[key] |= MultiChangesStateEnum.IS_CALC;
				// 	setRender(render + 1);
				// }			
				switch (key) {
					case "company_id":
						handleCbMulti("revert", "terminal_id");
						handleCbMulti("revert", "commodity");
						handleCbMulti("revert", "plumb_seal");
						handleCbMulti("revert", "set_order_completed");
						handleCbMulti("revert", "is_copy_documents");
						handleCbMulti("revert", "eta_3rd_party");
						handleCbMulti("revert", "etd_3rd_party");
						break;
					case "terminal_id":
						handleCbMulti("revert", "area_id");
						handleCbMulti("revert", "port_in");
						handleCbMulti("revert", "port_out");
						break;
					case "area_id":
						handleCbMulti("revert", "is_area_blocked");
						break;
					case "eta_3rd_party":
						handleCbMulti("revert", "eta_driver");
						handleCbMulti("revert", "eta_driver_id");
						handleCbMulti("revert", "eta_truck");
						handleCbMulti("revert", "eta_truck_id");
						handleCbMulti("revert", "eta_trailer");
						handleCbMulti("revert", "eta_trailer_id");
						break;
					case "etd_3rd_party":
						handleCbMulti("revert", "etd_driver");
						handleCbMulti("revert", "etd_driver_id");
						handleCbMulti("revert", "etd_truck");
						handleCbMulti("revert", "etd_truck_id");
						handleCbMulti("revert", "etd_trailer");
						handleCbMulti("revert", "etd_trailer_id");
						break;
					default:
						break;
				}
				break;
			case "clear":
				form.change(key, undefined);
				// if (recordData.state[key] & MultiChangesStateEnum.IS_CALC) {
				// 	recordData.state[key] ^= MultiChangesStateEnum.IS_CALC;
				// 	setRender(render + 1);
				// }
				break;		
			default:
				break;
		}		
	};

	const multiData = {recordData: recordData, cbMulti: handleCbMulti};

	const requiredMulti = (value, values, opt) => {		
		if (recordData.values && recordData.initialValues) {
			const key = opt["name"];			
			const isDiff = isMultiDiff(recordData.initialValues[key], value, recordData.state[key]);			

			if (isDiff) {
				if (isNullUndEmpty(value)) return "Required";
			}		
		}
	};

	const requiredValidArr = [requiredMulti];
	const isStatusDraft = values.status === OrderStatus.DRAFT;
	const statusAllowedIds = (enabledFields && enabledFields["status"] ? OrderStatusOnCompleteEdit : Object.values(OrderStatus)).filter(s => s !== OrderStatus.DRAFT);

	return (
		<GridForm {...props} enabledFields={enabledFields} multiData={multiData} >
			<FormGroupGrid>
				{canChangeCustomerAndReference ?
					<GridInput source="company_id" sm={2} component={CompanySelect} filter={{"no_transporters": true}} validate={!isStatusDraft ? requiredValidArr : undefined} allowEmpty={isStatusDraft} onChange={onCompanyChange} /> :
					<GridInput source="company_id" sm={2} component={CompanyText} disabled={true} />
				}
				<GridInput source="reference" sm={2} component={canChangeCustomerAndReference ? OrderReferenceInput : ReadOnlyInput} disabled={!canChangeCustomerAndReference} validate={!isStatusDraft ? requiredValidArr : undefined} />
				<GridInput source="service" sm={2} component={ServiceInput} validate={!isStatusDraft ? requiredValidArr : undefined} allowEmpty={isStatusDraft} defaultValue={!isStatusDraft ? OrderService.reloadCarCar : undefined} />
				<GridInput sm={3} hidden={isAddMode} source="status" reference="order-statuses">
					<ReferenceInput>
						<SelectDisabledInput
							allowedIds={statusAllowedIds}
							optionText="name"
						/>
					</ReferenceInput>
				</GridInput>
				<GridInput sm={1} component={YesNoInput} source="priority" defaultValue={false} onChange={(a, b, c ,d) => alert(a)} />
				<GridInput sm={1} component={YesNoInput} source="set_order_completed" defaultValue={false} />
				<GridInput sm={1} source="terminal_id" reference="terminal" disabled={isTerminalDisabled} validate={requiredValidArr} onChange={onTerminalChange}>
					<ReferenceInput>
						<SelectInput optionText="name" />
					</ReferenceInput>
				</GridInput>
				{/* <GridInput sm={2} component={LoadNoInput} source="load_no" /> */}
			</FormGroupGrid>

			{/* <Grid item sm={12} md={12} lg={6}> */}
				<ETAETDBlock sm={12} md={12} lg={6} isAddMode={isAddMode} requiredMulti={requiredMulti} prefix="eta" title="ETA  —  ARRIVAL" 
					permissions={permissions} {...props} enabledFields={enabledFields} byTerminalFilter={byTerminalFilter} multiData={multiData} />
			{/* </Grid> */}
			{/* <Grid item sm={12} md={12} lg={6}> */}
				<ETAETDBlock sm={12} md={12} lg={6} isAddMode={isAddMode} requiredMulti={requiredMulti} prefix="etd" title="ETD  —  DEPARTURE" 
					permissions={permissions} {...props} enabledFields={enabledFields} byTerminalFilter={byTerminalFilter} multiData={multiData} />
			{/* </Grid> */}

			{/* <Grid item xs={12} md={6} lg={3}> */}
				<FormGroupGrid xs={12} md={6} lg={3} title={translate("resources.orders.block-label.split-loads")}>
					<GridInput xs={4} component={YesNoInput} source="split_load" defaultValue={false} />
					<GridInput xs={4} component={TripNumberInput} source="trip_number" validate={null} resettable />
					<GridInput xs={4} component={LoadingOrderInput} source="loading_order" validate={null} resettable />
				</FormGroupGrid>
			{/* </Grid> */}

			{/* <Grid item xs={12} md={6} lg={3}> */}
				<FormGroupGrid xs={12} md={6} lg={3} title={translate("resources.orders.block-label.plumb-seal")}>
					<GridInput xs={6} sm={4} component={YesNoInput} source="plumb_seal" defaultValue={false} />
					<GridInput xs={6} sm={8} component={TextInput} source="plumb_number" resettable />
				</FormGroupGrid>
			{/* </Grid> */}

			{/* <Grid item xs={12} md={6} lg={3}> */}
				<FormGroupGrid  xs={12} md={6} lg={3} title={translate("resources.orders.block-label.extra-loading-time")}>
					<GridInput xs={6} sm={5} component={NumberInput} options={{ format: "" }} source="extra_loading_time" resettable />
					<GridInput xs={6} sm={5} component={YesNoInput} source="is_extra_loading_time_billable" defaultValue={false} />
				</FormGroupGrid>
			{/* </Grid> */}

			{/* <Grid item xs={12} md={6} lg={3}> */}
				<FormGroupGrid xs={12} md={6} lg={3} title={translate("resources.orders.block-label.storage-allocation")}>
					<GridInput xs={8} sm={8} source="area_id" reference="areas" filter={{ ...byTerminalFilter, disabled: false }} onChange={handleAreaChange} resettable>
						<ReferenceInput>
							<SelectInput optionText="name" disableValue="disabled_by_any" />
						</ReferenceInput>
					</GridInput>
					<GridInput xs={4} sm={4} component={YesNoInput} source="is_area_blocked" disabled={!values.area_id}/>
					<GridInput xs={6} sm={6} component={SelectEnumInput} onChange={handleLabelSourceChange} options={{ format: "" }} source="label_source" key={"label_source"} enumName="label_source" enumObj={LabelSource}/>
					<GridInput xs={6} sm={6} component={NumberInputEx} options={{ format: "" }} source="label" disabled={!values["label_source"]} resettable />
				</FormGroupGrid>
			{/* </Grid> */}

			<FormGroupGrid>
				<GridInput xs={12} sm={6} md={3} hidden={values.service === OrderService.reloadCarCar} source="cooler_type_id" reference="cooler-types" filter={{ order_service: values.service }}>
					<ReferenceInput>
						<SelectUppercaseInput optionText="name" />
					</ReferenceInput>
				</GridInput>
				<GridInput xs={6} sm={6} md={3} component={PrintedDocsInput} source="printed_doc" defaultValue={false} />
				<GridInput xs={6} sm={6} md={3} component={CommodityInput} source="commodity" />
				<GridInput xs={6} sm={6} md={3} component={YesNoInput} source="is_doc_for_nfsa" defaultValue={false}
					onChange={onIsDocForNfsaChange}
				/>
			</FormGroupGrid>

			{/* <DimensionsBlock {...props} /> */}
			<FormGroupGrid xs={12} lg={6}>
				<GridInput xs={4} component={NumberInputEx} source="pallets" min={0} max={100} step={10} resettable />
				<GridInput xs={4} component={NumberInputEx} source="boxes" min={0} max={100} step={5} onChange={onChangeTest} resettable />
				<GridInput xs={4} component={NumberInputEx} type="float" digitsCount={2} source="kilos" min={0} max={1000} step={100} resettable />
			</FormGroupGrid>

			<FormGroupGrid lg={6}>
				<GridInput source="assignee_1" sm={6} md={3} lg={6} component={OSFCUserSelect} resettable />
				<GridInput source="assignee_2" sm={6} md={3} lg={6} component={OSFCUserSelect} resettable />
			</FormGroupGrid>

			<FormGroupGrid>
				<GridInput sm={12} md={6} component={TextInput} source="notes" multiline rows={6} validate={[maxLength(1024)]} resettable />
				<GridInput sm={12} md={6} className="red-border" component={DangerTextInputForInternalNotes} source="internal_notes"
						   multiline rows={6} validate={[maxLength(1000)]} disabled />
			</FormGroupGrid>
		</GridForm>
	);
};
