import React from "react";
import { Filter } from "react-admin";
import { ToggleStateFilter } from "../../../components/filters/ToggleStateFilter";
import { FunctionField } from "react-admin";
import { useOwnerCompanyDataContext } from "../../../contexts/OwnerCompanyDataProvider";
export const CurrencyRatesFilters= (props) => {
    const { ownerCompanyData } = useOwnerCompanyDataContext();

    console.log(props)
    return(
        <Filter {...props}>
            <ToggleStateFilter source="is_actual" alwaysOn key={"is_actual"}/>
            <FunctionField
                label="Main Currency"
                render={() => <span style={{ fontWeight: 'bold'}}>Master currency: {ownerCompanyData.main_currency} </span> } 
                style={{marginBottom: '15px', marginLeft: '15px'}}
                alwaysOn
            />
        </Filter>
    )
}