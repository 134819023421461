import ExternalTerminalsIcon from "@material-ui/icons/Storage";

import { ExternalTerminalsCreate } from "./Create/Create";
import { ExternalTerminalsEdit } from "./Edit/Edit";
import { ExternalTerminalsList } from "./List/List";

export { ExternalTerminalsList, ExternalTerminalsEdit };

export const externalTerminalsResource = {
	list: ExternalTerminalsList,
	edit: ExternalTerminalsEdit,
	create: ExternalTerminalsCreate,
	icon: ExternalTerminalsIcon,
};
