import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Fragment, useState } from 'react';
import {
	Button,
	Confirm,
	useNotify,
	useRefresh,
} from 'react-admin';

import useUpdatePartial from "../_extensions/react-admin/core/dataProvider/useUpdatePartial";
import { MuiButtonMainProps } from '../_helpers/props/MuiButtonProps';

const useDeleteButtonStyles = makeStyles({
	label: {
		paddingLeft: '0',
	},
	button: {
		backgroundColor: '#f44336',
		color: 'white',
		'&:hover': {
			backgroundColor: '#ff7961',
		},
	},
});

const useRestoreButtonStyles = makeStyles({
	label: {
		paddingLeft: '0',
	},
	button: {
		backgroundColor: 'green',
		color: 'white',
		'&:hover': {
			backgroundColor: '#ff7961',
		},
	},
});

const DeleteRestoreButtons = ({
	resource: targetResource,
	replaceResource: targetReplaceResource,
	record,
	confirmDeleteStringKey="osfc.dialogs.confirm_delete_entity",
	confirmRestoreStringKey="osfc.dialogs.confirm_restore_entity",
	requestMethod = "updatePartial",
}) => {
	const resource = targetReplaceResource ?? targetResource;

	const [open, setOpen] = useState(false);
	const refresh = useRefresh();
	const notify = useNotify();

	const baseStringKey = record['is_deleted'] ? confirmRestoreStringKey : confirmDeleteStringKey;
	const deleteButtonClasses = useDeleteButtonStyles();
	const restoreButtonClasses = useRestoreButtonStyles();
	const [update, { loading }] = useUpdatePartial(
		resource,
		record['id'],
		{is_deleted: !record['is_deleted']},
		{is_deleted: record['is_deleted']},
		{
			withDeclarativeSideEffectsSupport: true,
			onSuccess: () => {
				refresh();
				notify(baseStringKey + ".success", "info");
			},
			onFailure: (error) => {
				notify(error.message, "error");
			}
		},
		requestMethod
	);
	const handleClick = (e) => {
		e.stopPropagation();
		setOpen(true)
	};
	const handleDialogClose = () => setOpen(false);

	const handleConfirm = () => {
		update();
		setOpen(false);
	};

	return (
		<Fragment>
			<Button
				label={record['is_deleted'] ? 'osfc.buttons.restore' : 'osfc.buttons.delete'}
				onClick={handleClick}
				{...MuiButtonMainProps}
				classes={record['is_deleted'] ? restoreButtonClasses : deleteButtonClasses}
			/>
			<Confirm
				isOpen={open}
				loading={loading}
				title={baseStringKey + ".title"}
				content={baseStringKey + ".content"}
				onConfirm={handleConfirm}
				onClose={handleDialogClose}
				confirm="osfc.buttons.confirm"
			/>
		</Fragment>
	);
}

export default DeleteRestoreButtons;
