import React from "react";

import { TextInput, required } from 'react-admin';
import { useFormState } from 'react-final-form';

import { GridForm, GridInput } from '../../../components/GridForm/';
import { MapperTypeSelect } from "../../../components/inputs/MapperTypeSelect";
import { MapperDataInputPredefined } from "../components/MapperDataInput";
import { MapperType } from "../../../entities/MapperType";
import { getOptionalKeysByType } from "../../../utils/mapper";


export const MappersEditForm = ({...props}) => {
	const { values } = useFormState();
	const optKeys = getOptionalKeysByType(values["type"]);

	return (
	<GridForm>
		<GridInput xs={6} component={TextInput} source="name"  validate={required()}/>
		<GridInput xs={6} component={MapperTypeSelect} source="type" defaultValue={MapperType.order_import} disabled />
		<GridInput xs={12} source="data">
			<MapperDataInputPredefined optionalKeys={optKeys} formData={values}/>
		</GridInput>
	</GridForm>

)};
