import Box from '@material-ui/core/Box';
import MuiButton from '@material-ui/core/Button';
import React from 'react';
import {Edit, useTranslate} from 'react-admin';
import { Link } from 'react-router-dom';

import {useMutateForm} from '../../../api/useMutateForm';
import {
	orderWarningValidations,
} from '../../../components/_helpers/warningsConditions';
import {ActionsWithBackButton} from '../../../components/actionbars/ActionsWithBackButton';
import {DocumentsFieldBlock} from '../../../components/fields/DocumentsField';
import {SimpleFormAdv} from '../../../components/forms/SimpleFormAdv';
import {OrderStatus} from '../../../entities';
import {FormCustomer} from '../forms/FormCustomer';
import {FormCustomerTransporter} from "../forms/FormCustomerTransporter";
import {FormManager} from '../forms/FormManager';
import {FormTransporter} from '../forms/FormTransporter';
import {loadingDisable, unloadingDisable} from '../Show/Tabs'

export const ExtraActionButtons = ({ data: record }) => {
	const translate = useTranslate();

	const unloading_disable = unloadingDisable(record);
	const loading_disable = loadingDisable(record);

	if (unloading_disable && loading_disable) return [];
	return [
		<Box ml={1} display="flex">
			<MuiButton
				component={Link}
				to={`${record.id}/show/4`}
				size="small"
				color="primary"
				variant="outlined"
			>
				{translate("tab.out-documents.name")}
			</MuiButton>
		</Box>,
	];
};

export const OrderEditBase = (Form, name, actionsProps) => (_props) => {
	const props = {..._props};

	const isDraft = window.location.href.indexOf("draft-orders") !== -1;
	if (isDraft) {
		props.resource = "orders";
		props.title = "resources.draft-orders.name";
	}

	const [mutateForm, state] = useMutateForm("update", props.resource, props.basePath, props.id, {}); // eslint-disable-line
	const toolbarCompProps = {
		warningValidations: orderWarningValidations,
	};
	const mutateFormAndEmit = (...args) => {
		const result = mutateForm(...args);
		return result;
	};

	return (
		<React.Fragment>
			<Edit
				{...props}
				undoable={false}
				actions={
					<ActionsWithBackButton
						{...actionsProps}
						cloneMutableValues={{
							reference: null,
							origin_id: props.id,
						}}
						ExtraActionButtons={ExtraActionButtons}
					/>
				}
			>
				<SimpleFormAdv
					save={mutateFormAndEmit}
					children={<Form/>}
					toolbarCompProps={toolbarCompProps}
				/>
			</Edit>
			<Edit {...props} undoable={false} actions={null}>
				<DocumentsFieldBlock variant="elevation"/>
			</Edit>
		</React.Fragment>
	);
};

export const OrderEditByManager = OrderEditBase(FormManager, "Manager", {
	hasClone: (record) => record?.status !== OrderStatus.DRAFT,
	hasChange: (record) => record?.status !== OrderStatus.DRAFT,
	hasExtraButtons: (record) => record?.status !== OrderStatus.DRAFT,
})
export const OrderEditByCustomer = OrderEditBase(FormCustomer, "Customer", {
	hasDelete: (record) =>
		record?.status === OrderStatus.BOOKED || record?.status === OrderStatus.DRAFT,
	hasClone: (record) => record?.status !== OrderStatus.DRAFT,
	hasExtraButtons: (record) => record?.status !== OrderStatus.DRAFT,
});
export const OrderEditByCustomerTransporter = OrderEditBase(FormCustomerTransporter, "Customer and transporter", {
	ifCustomerTransporterOrderEdit: true,
	hasExtraButtons: (record) => record?.status !== OrderStatus.DRAFT,
});
export const OrderEditByTransporter = OrderEditBase(FormTransporter, "Transporter", {
	hasExtraButtons: (record) => record?.status !== OrderStatus.DRAFT,
});
