import { Box, Typography } from '@material-ui/core';
import React from "react";
import { Datagrid, List, Loading, ReferenceField, TextField, useTranslate } from 'react-admin';
import { useListContext } from "react-admin";

import { dataProvider } from "../../../api";
import { DatagridWithBlockProps } from '../../../components/_helpers/props/DatagridProps';
import { ColorEditableField } from "../../../components/fields/ColorField";
import { TextEditableField } from "../../../components/fields/EditableField";
import { PriceEditableField } from '../../../components/fields/PriceField';
import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import GridUserDataProvider, { useGridUserDataContext } from "../../../contexts/GridUserDataProvider";
import { useOwnerCompanyDataContext } from "../../../contexts/OwnerCompanyDataProvider";
import { useNotifyError } from "../../../utils/notifiers/useNotifyError";
import { ArticlesActions } from "./Actions";
import { ArticlesFilters, filterDefaultValues } from './ListFilters';
import { SpecialPricesField } from './SpecialPriceField';

const defaultSort = { field: 'code', order: 'DESC' };

const articleRowStyle = (record) => {	
	return {
		backgroundColor: record.color,
	};
};


export const _ArticlesList = props => {
	const { filters: currentFilters, sort: currentSort } = useGridUserDataContext();
	const notifyError = useNotifyError();
	const [selectedIds, setSelectedIds] = React.useState([]);
	const [selectedFields, setSelectedFields] = React.useState({});
	const [isEdit, setIsEdit] = React.useState(false);
	const [currenciesDict, setCurrenciesDict] = React.useState({});

	const translate = useTranslate();

	React.useEffect(() => {
		dataProvider.getList("currency-rates", {
			pagination: {page: 0, perPage: -1},
			sort: {field: 'id', order: 'DESC'},
			filter: {is_actual: "active"},
		}).then((data) => {
			if (data?.data) {
				const transformedData = data.data.reduce((acc, item) => {
					acc[item.currency_2] = item; 
					return acc;
					}, {});
				setCurrenciesDict(transformedData);
			};
		}).catch((error) => {
			notifyError(error);
		})
	}, []);


	if (!props.permissions || currentFilters === undefined) {
		return <Loading loadingSecondary="" />;
	};

	const onEdit = () => {
		setIsEdit(true);
	}

    const updateSelectedIds = (newId) => {
		setSelectedIds(prevSelectedIds => {
			if (prevSelectedIds.includes(newId)) {
				return prevSelectedIds; 
			}
			return [...prevSelectedIds, newId];
		});
    };

	const updateSelectedFields = (newField, source) => {
		setSelectedFields(prevFields => {
			prevFields[newField.id] = {...prevFields[newField.id], [source]: newField[source]};
			return prevFields;
		});
    };

	const updateSelectedFieldsSpecialPrice = (recordId, specialPrices) => {
		setSelectedFields(prevFields => {
			prevFields[recordId] = {...prevFields[recordId], "special_price": specialPrices};
			return prevFields;
		});
    };
	
	const cancelEdit = () => {
		setSelectedIds([]);
		setSelectedFields({});
		setIsEdit(false);
	}

	return (
		<List
			{...props}
			exporter={false}
			empty={false}
			bulkActionButtons={false}
			sort={currentSort}
			pagination={null}
			filterDefaultValues={currentFilters}
			filters={<ArticlesFilters mainCurrency={props.mainCurrency} currenciesDict={currenciesDict} selectedCurrency={currentFilters}/>}
			actions={props.options.hasUpload ? <ArticlesActions selectedIds={selectedIds} cancelEdit={cancelEdit}
					selectedFields={selectedFields} onEdit={onEdit} isEdit={isEdit} permissions={props.permissions}/> : null}
		>
			<PriceListDatGrid updateSelectedIds={updateSelectedIds} updateSelectedFields={updateSelectedFields} 
					currentFilters={currentFilters} updateSelectedFieldsSpecialPrice={updateSelectedFieldsSpecialPrice} 
					selectedIds={selectedIds} selectedFields={selectedFields} isEdit={isEdit} translate={translate} />
		</List>
	);
};

const PriceListDatGrid = (props) => {
	const {updateSelectedIds, 
		updateSelectedFields, 
		updateSelectedFieldsSpecialPrice, 
		selectedIds, 
		selectedFields, 
		isEdit, 
		currentFilters,
		translate,
	} = props;

	const { error } = useListContext(); 

	return(
		<>
		{error ? (
			<Box 
				display="flex" 
				justifyContent="center" 
				alignItems="center" 
				height={200}
			>
				<Typography component="span" variant="h3">
					{translate("resources.pricelist-articles.price_rate_not_found")}
				</Typography>
			</Box>
		  ) : (
			<ScrollingWrapper>
			  <Datagrid
				{...DatagridWithBlockProps}
				rowStyle={articleRowStyle}
				rowClick={null}
				size="small"
			  >
				<TextField source="code" sortable={false} />
		  
				<ReferenceField source="terminal_id" reference="terminal" link={false} sortable={false}>
				  <TextField label="Terminal" source="name" />
				</ReferenceField>
		  
				<TextField source="name" sortable={false} />
				<TextField source="calculation" sortable={false} />
		  
				<PriceEditableField source="price_a" updateSelectedIds={updateSelectedIds} updateSelectedFields={updateSelectedFields}
				  selectedIds={selectedIds} selectedFields={selectedFields} editable={isEdit} mainCurrency={currentFilters.currency} sortable={false} />
				<PriceEditableField source="price_b" updateSelectedIds={updateSelectedIds} updateSelectedFields={updateSelectedFields}
				  selectedIds={selectedIds} selectedFields={selectedFields} editable={isEdit} mainCurrency={currentFilters.currency} sortable={false} />
				<PriceEditableField source="price_c" updateSelectedIds={updateSelectedIds} updateSelectedFields={updateSelectedFields}
				  selectedIds={selectedIds} selectedFields={selectedFields} editable={isEdit} mainCurrency={currentFilters.currency} sortable={false} />
				<PriceEditableField source="price_d" updateSelectedIds={updateSelectedIds} updateSelectedFields={updateSelectedFields}
				  selectedIds={selectedIds} selectedFields={selectedFields} editable={isEdit} mainCurrency={currentFilters.currency} sortable={false} />
				<PriceEditableField source="price_e" updateSelectedIds={updateSelectedIds} updateSelectedFields={updateSelectedFields}
				  selectedIds={selectedIds} selectedFields={selectedFields} editable={isEdit} mainCurrency={currentFilters.currency} sortable={false} />
				
				<TextField source="service_description" sortable={false} />
		  
				<ColorEditableField source="color" updateSelectedIds={updateSelectedIds} updateSelectedFields={updateSelectedFields} 
						selectedIds={selectedIds} selectedFields={selectedFields} editable={isEdit} sortable={false} />
				<SpecialPricesField source="special_prices" sortable={false} updateSelectedIds={updateSelectedIds} updateSelectedFieldsSpecialPrice={updateSelectedFieldsSpecialPrice}
				  selectedCurrency={currentFilters.currency} selectedIds={selectedIds} selectedFields={selectedFields} editable={isEdit} />
			  </Datagrid>
			</ScrollingWrapper>
		  )}</>
		  
	)
}

export const ArticlesList = (props) => {
	const {ownerCompanyData} = useOwnerCompanyDataContext();

	if(!ownerCompanyData) return <Loading loadingPrimary="Loading..." />;

	return (
	<GridUserDataProvider 
		resource={props.resource}
		filterDefaultValues={{currency: ownerCompanyData.main_currency, ...filterDefaultValues}} 
		sortDefaultValues={defaultSort}
	>
		<_ArticlesList {...props} mainCurrency={ownerCompanyData.main_currency} />
	</GridUserDataProvider>
)};
