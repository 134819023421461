import ChangeIcon from '@material-ui/icons/Edit';
import * as React from "react";
import { Button, useRedirect, useTranslate } from 'react-admin';

const MultipleOrdersEditButton = ({ selectedIds = null, basePath }) => {
	const translate = useTranslate();
	const redirect = useRedirect();

	if (!selectedIds) return null;

	const label = translate('resources.orders.bulk-edit.button');

	const handleOnClick = () => {
		const query = `selected_ids=${selectedIds.join(',')}`;
		const redirectTo = `${basePath}/multiple-changes?${query}`;
		redirect(redirectTo);
	};

	return (
		<Button
			onClick={handleOnClick}
			label={label}
		>
			<ChangeIcon />
		</Button>
	);
};


const CustomBulkActionButtons = ({ selectedIds, basePath, filterValues, resource, isDraft, ...props }) => {
	const _basePath = isDraft ? "/draft-orders" : basePath;
	return (
		<React.Fragment>
			<MultipleOrdersEditButton selectedIds={selectedIds} basePath={_basePath} />
		</React.Fragment>
	);
};


export default CustomBulkActionButtons;
