import React from 'react';

import { OrdersDetailsType } from '../../../entities/OrdersDetailsType';
import { isNullUndEmpty } from '../../../utils/general';
import { HighlightField } from '../Field';
import { HighlightList } from '../List';

const getWarningCssClasses = ({ details }) => {
	let highlightType = 0;
	if (details.details_info.some(detail => detail.type === OrdersDetailsType.error)) {
		highlightType = -1;
	} else if (details.details_info.some(detail => detail.type === OrdersDetailsType.warning)) {
		highlightType = 2;
	};
	return [`row-highlight-${highlightType}`];
};

const getSources = ({ source, replaceSource }) => {
	const sources = {"main": [], "extra": []};
	sources["main"] = source.split("|");
	if (replaceSource) {
		sources["extra"] = [replaceSource];
	}
	return sources; 
};

const getSource = ({ record, ...rest }) => {
	const { main, extra } = getSources(rest);
	if (main.length > 1 && extra.length === 0) {
		const filteredMain = main
			.filter(source => !isNullUndEmpty(record[source]))
		return filteredMain[0]
	}
	return main[0];
};

const getWarningValue = ({ record, ...rest }) => {
	const sources = getSources(rest);
	const messageOrFalse = (
		record.details.details_info
		.find((detail) => (
			[OrdersDetailsType.error, OrdersDetailsType.warning].indexOf(detail.type) !== -1 && 
			[...sources.main, ...sources.extra].indexOf(detail.field) !== -1
		))?.message ?? false
	);
	return messageOrFalse
};

const getIsNullishSource = ({ record, ...rest }) => {
	const sources = getSources(rest);
	const checkSources = sources.extra.length !== 0 ? 
		sources.extra : sources.main;
	const isNullishSource = !checkSources
		.some(source => !isNullUndEmpty(record[source]));
	return isNullishSource;
};

export const WarningField = (props) => (
	<HighlightField 
		{...props} 
		getSource={getSource}
		getHighlightValue={getWarningValue} 
		getIsNullishSource={getIsNullishSource} 
	/>
);

export const WarningList = (props) => (
	<HighlightList 
		{...props} 
		cssFnRow={getWarningCssClasses} 
	/>
);
