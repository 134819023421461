import React from "react";
import { Resource } from "react-admin";
import { Route } from "react-router-dom";

import { UserRole } from "../../entities/UserRole";
import { Developer } from "../../pages/developer/";
import { Settings } from "../../pages/settings/";
import { Documentation } from "../../pages/public-documentation/";
import { ForgotPassword, ResetPassword } from "../Login";
import { adminResources, adminRoutes } from "./admin";
import { appManagerResources, appManagerRoutes } from "./app_manager";
import {
	customerAndTransporterEmployeeResources,
	customerAndTransporterEmployeeRoutes,
} from "./customer_and_transporter_employee";
import {
	customerAndTransporterManagerResources,
	customerAndTransporterManagerRoutes,
} from "./customer_and_transporter_manager";
import { customerEmployeeResources, customerEmployeeRoutes } from "./customer_employee";
import { customerManagerResources, customerManagerRoutes } from "./customer_manager";
import { osfcEmployeeResources, osfcEmployeeRoutes } from "./osfc_employee";
import { osfcManagerResources, osfcManagerRoutes } from "./osfc_manager";
import { transporterAdminResources, transporterAdminRoutes } from "./transporter_admin";
import { transporterDriverResources, transporterDriverRoutes } from "./transporter_driver";
import { transporterEmployeeResources, transporterEmployeeRoutes } from "./transporter_employee";

export const resources = {
	[UserRole.admin]: adminResources,
	[UserRole.app_manager]: appManagerResources,

	[UserRole.osfc_manager]: osfcManagerResources,
	[UserRole.osfc_employee]: osfcEmployeeResources,

	[UserRole.customer_manager]: customerManagerResources,
	[UserRole.customer_employee]: customerEmployeeResources,

	[UserRole.customer_and_transporter_manager]: customerAndTransporterManagerResources,
	[UserRole.customer_and_transporter_employee]: customerAndTransporterEmployeeResources,
	[UserRole.customer_and_transporter_driver]: transporterDriverResources,

	[UserRole.transporter_manager]: transporterAdminResources,
	[UserRole.transporter_employee]: transporterEmployeeResources,
	[UserRole.transporter_driver]: transporterDriverResources,
};

export const customRoutes = {
	[UserRole.admin]: adminRoutes,
	[UserRole.app_manager]: appManagerRoutes,

	[UserRole.osfc_manager]: osfcManagerRoutes,
	[UserRole.osfc_employee]: osfcEmployeeRoutes,

	[UserRole.customer_manager]: customerManagerRoutes,
	[UserRole.customer_employee]: customerEmployeeRoutes,

	[UserRole.customer_and_transporter_manager]: customerAndTransporterManagerRoutes,
	[UserRole.customer_and_transporter_employee]: customerAndTransporterEmployeeRoutes,
	[UserRole.customer_and_transporter_driver]: transporterDriverRoutes,

	[UserRole.transporter_manager]: transporterAdminRoutes,
	[UserRole.transporter_employee]: transporterEmployeeRoutes,
	[UserRole.transporter_driver]: transporterDriverRoutes,
};

export const resourcesByPermissions = (permissions) => {
	if (!permissions) return [];
	return [...resources[permissions.role], <Resource name="settings" />];
};

export const customRoutesByPermissions = (permissions) => [
	...(permissions ? customRoutes[permissions.role] : []),
	<Route exact path="/developer" component={Developer} />,
	<Route path="/settings" component={Settings} />,
	<Route path="/documentation" component={Documentation} noLayout/>,
	<Route exact path="/forgot-password" component={ForgotPassword} noLayout />,
	<Route
		exact
		path="/reset-password/:request_id/:request_code/:user_id/:email/:created_at"
		component={ResetPassword}
		noLayout
	/>,
];
