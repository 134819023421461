export const MapperType = {
	none: 0,
	loading: 1,
	unloading: 2,
	loading_unloading_cc: 3,
	order_import: 10
}

export const CustomerCompaniesMapperType = {
	none: 0,
	loading: 1,
	unloading: 2,
	loading_unloading_cc: 3,
}

