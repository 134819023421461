import React from "react";
import { EmailField, ReferenceManyField, Show, SimpleShowLayout, TextField } from "react-admin";

import { ActionsWithBackButton } from "../../../components/actionbars/ActionsWithBackButton";
import { PhoneField } from "../../../components/fields/PhoneField";
import { PriceCategoryField } from "../../../components/fields/PriceCategoryField";
import { YesNoField } from "../../../components/fields/YesNoField";
import { ScrollingWrapperInCard } from "../../../components/ScrollingWrapper";
import { CompanyCustomerType } from '../../../entities';

import { EmployeesDatagrid } from "./EmployeesDatagrid";
import { SelectEnumField } from "components/fields/SelectEnumField";


export const CompanyShow = (props) => {
	return (
		<Show actions={<ActionsWithBackButton />} {...props}>
			<SimpleShowLayout>
				<TextField source="id" disabled />
				<TextField source="full_name" autoFocus />
				<TextField source="short_name" />
				<TextField source="accountancy_number" />
				<PhoneField source="phone" />
				<EmailField source="email" />
				<EmailField source="notification_email" />
				<PriceCategoryField source="price_category" />
				<YesNoField source="set_order_completed" />
				<SelectEnumField source="type" enumObj={CompanyCustomerType} enumName="company-type" />
				<EmailField source="transporter_notification_email" />
				<TextField source="guid" />
				<ScrollingWrapperInCard>
					<ReferenceManyField label="Employees" reference="customer-users" target="company_id">
						<EmployeesDatagrid id={props.id} />
					</ReferenceManyField>
				</ScrollingWrapperInCard>
			</SimpleShowLayout>
		</Show>
	);
};
