import React from "react";
import {
    ArrayInput,
    BooleanInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    TextInput,
    minValue,
    required,
} from "react-admin";

import { Typography, makeStyles, Tooltip, IconButton, Box } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import SimpleFormIteratorEx from "../../../components/_extensions/react-admin/ra-ui-materialui/src/form/SimpleFormIteratorEx";
import { FormGroupGrid, GridForm, GridInput } from "../../../components/GridForm";
import { DateTimeInput } from "../../../components/inputs";
import { SelectEnumInput } from "../../../components/inputs/SelectEnumInput";
import { useTerminalsContext } from '../../../contexts/TerminalsProvider';
import { DisplayTaskActionType } from "../../../entities/DisplayTaskActionType";

const BrHintTooltip = () => (
    <Tooltip 
      title={
        <span style={{ fontSize: "16px", lineHeight: "20px" }}> 
          Use &lt;br&gt; to split text into multiple lines.<br />
          (Lines depend on display configuration)
        </span>
      }
      arrow
    >
      <IconButton size="medium">
        <InfoIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );


const useArrayInputStyle = makeStyles(() => ({
    root: {
        '& div.ra-input': {
            display: 'inline-block',
            marginLeft: '0.7em',
        },
    }
}));


export const TaskDisplayActionForm = (props) => {
    const { sortedTerminalsASC } = useTerminalsContext();
    const arrayInputClasses = useArrayInputStyle();

    return (
        <GridForm {...props}>
            <FormGroupGrid>
                <GridInput 
                    sm={6} md={2} 
                    validate={[required()]}
                >
                    <ReferenceInput 
                        source="terminal_id" 
                        reference="terminal" 
                        sort={{ field: 'id', order: 'ASC' }}
                        validate={[required()]} 
                        defaultValue={sortedTerminalsASC[0]?.id}
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </GridInput>
                <GridInput sm={6} md={3} component={DateTimeInput} source="scheduled_to"/>

                <GridInput sm={1} component={BooleanInput} source="priority"/> 
            </FormGroupGrid>

            <Box sx={{ p: 2, border: "1px solid #ddd", borderRadius: "8px", backgroundColor: "#f9f9f9", width: "100%" }}>
                <Typography variant="h6" align="center" gutterBottom>
                    Task Actions
                    <Box component="span" sx={{ position: "relative", top: "-2px", marginLeft: "5px" }}>
                        <BrHintTooltip />
                    </Box>
                </Typography>
                
                <ArrayInput source="task_options" fullWidth>
                    <SimpleFormIteratorEx classes={arrayInputClasses} inline={true} disableReordering showIndex={false} fullWidth>
                        <TextInput source="text" label="Text" />
                        <SelectEnumInput 
                            source="type" 
                            label="Type" 
                            enumName={"display_task_action_type"}
                            enumObj={DisplayTaskActionType}
                            validate={[required()]} 
                            
                        />
                        <NumberInput source="duration" label="Duration (seconds)" validate={[required(), minValue(0)]}  />
                    </SimpleFormIteratorEx>
                </ArrayInput>
            </Box>
        </GridForm>
    )
}
