import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { FunctionField } from 'react-admin';

const _getIsNullishSource = (props) => false;
const _getSource = (props) => props.source;

export const HighlightField = ({
	component: Component, 
	getHighlightValue, 
	getIsNullishSource = _getIsNullishSource, 
	getSource = _getSource, 
	...props
}) => {
	const renderField = (record) => {
		const payload = {...props, record};
		const highlightValue = getHighlightValue(payload);
		const targetSource = getSource(payload);

		if (highlightValue) {
			const isNullishSource = getIsNullishSource(payload);
			return (
				<Tooltip
					title={highlightValue}
					placement="top"
					className="cell-tooltip-highlight"
				>
					<div>
						{!isNullishSource ?
							<Component {...props} source={targetSource} />
						: 
							<span>{'\u00A0'}</span>
						}
					</div>
				</Tooltip>
			);
		} else {
			return <Component {...props} source={targetSource} />;
		}
	};

	return <FunctionField {...props} render={renderField} />;
};
