import React from "react";
import { Filter, } from 'react-admin';
import { CompanySelect } from '../../../components/inputs/CompanySelect';
import TextInputEx from "../../../components/_extensions/react-admin/input/TextInputEx";
import { TruckSelect } from '../../../components/inputs/TruckSelect';


export const DriversFilter = ({ companyResource, ...props }) => {
	return (
	<Filter {...props}>
		<CompanySelect source="company_id" reference="transporters"
					    alwaysOn />
		<TextInputEx source="first_name" />
		<TextInputEx source="last_name" />
		<TextInputEx source="email" />
		<TextInputEx source="phone" />
		<TruckSelect source="truck_id" reference="trucks"/>
	</Filter>
)};
