import React from "react";
import { Resource } from "react-admin";
import { Route } from "react-router-dom";

import { CompanySettingsPage } from "../../pages/settings/";
import { apiKeysRouts } from "../../pages/settings/APIKeys";
import { customersEmployeesResource } from "../../resources/customer-employees/";
import { pricelistArticlesResource } from "../../resources/customer-pricelist-articles";
import { customerUsers } from "../../resources/customer-users";
import { customerDriversResource } from "../../resources/drivers/";
import Messages from "../../resources/messages/List/List";
import { ordersByAPIKeysResource } from "../../resources/orders-by-api-keys";
import {
	OrderCreateByCustomer,
	OrderEditByCustomer,
	ordersDraftResource,
	ordersResource,
} from "../../resources/orders/";
import { areasResource } from "../../resources/ports-areas";
import { pricelistHolidaysResource } from "../../resources/pricelist-holidays/";

export const customerManagerResources = [
	<Resource
		{...ordersResource}
		edit={OrderEditByCustomer}
		create={OrderCreateByCustomer}
		options={{ label: "Orders" }}
		name="orders"
	/>,
	<Resource 
		{...ordersDraftResource}
		edit={OrderEditByCustomer}
		name="draft-orders" 
	/>,
	<Resource name="orders-by-api-keys" {...ordersByAPIKeysResource} />,
	// <Resource {...customersEmployeesResource} name="customer-employees" />,
	<Resource {...customerUsers} name="customer-users" options={{ label: "Employees" }} />,
	// <Resource {...transportersResource} create={null} edit={null} name="transporters" />,
	// <Resource {...customerDriversResource} name="drivers" />,
	<Resource
		{...pricelistArticlesResource}
		name="customer-pricelist-articles"
		options={{ hasUpload: false }}
	/>,
	<Resource {...pricelistHolidaysResource} name="pricelist-holidays" />,
	<Resource name="ports" intent="registration" />,
	<Resource name="areas" intent="registration" />,
	<Resource name="users" intent="registration" />,
	<Resource name="customer-companies" intent="registration" />,
	<Resource name="company-notifications" intent="registration" />,
	<Resource name="order-statuses" intent="registration" />,
	<Resource name="outgoing-doc-statuses" intent="registration" />,
	<Resource name="cooler-types" intent="registration" />,
	<Resource name="order-pricelist" intent="registration" />,
	<Resource name="transporters" intent="registration" />,
	<Resource name="drivers" intent="registration" />,
	<Resource name="trucks" intent="registration" />,
	<Resource name="trailers" intent="registration" />,
	<Resource name="order-history" intent="registration" />,
	<Resource name="terminal" intent="registration" />,
	<Resource name="external-terminal" intent="registration" />,
	<Resource name="api-keys" intent="registration" />,
];

export const customerManagerRoutes = [
	...apiKeysRouts,
	<Route exact path="/messages" component={Messages} />,
	<Route exact path="/notifications" component={CompanySettingsPage} />,
];
