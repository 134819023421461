import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import React from 'react';
import {
	CloneButton,
	DeleteButton,
	EditButton,
	ShowButton,
	TopToolbar,
	usePermissions,
	useRedirect,
	useTranslate
} from 'react-admin';

import { OrderStatus, UserRole } from "../../entities";
import { MuiButtonMainProps } from '../_helpers/props/MuiButtonProps';
import { ChangeCustomerButton } from '../dialogs/ChangeCustomerDialog';

export const ActionsWithBackButton = (props) => {
	const {
		basePath,
		data,
		hasList,
		hasEdit,
		hasShow,
		
		hasChange,
		hasExtraButtons,
		cloneMutableValues,
		ifCustomerTransporterOrderEdit,
		redirectPath,
		ExtraActionButtons = null,
	} = props;
	let {
		hasClone,
		hasDelete,
	} = props;
	const redirect = useRedirect();
	const t = useTranslate();
	const {permissions} = usePermissions();

	if (!permissions || !data) return null;

	if (ifCustomerTransporterOrderEdit){
		if (permissions.company_id === data.company_id) {
			hasDelete = (record) => record?.status === OrderStatus.BOOKED;
			hasClone = true;
		}
	}
	//  TODO - Uncomment and correct after configuration access to orders for admin 
	// if (hasChange){
	// 	if (permissions.role !== UserRole.osfc_manager){
	// 		hasChange = false
	// 	}
	// }
	return (
		<TopToolbar>
			{hasList && <Box display="flex">
				<Button startIcon={<ArrowBack/>} {...MuiButtonMainProps} size="small"
					onClick={e => redirect(redirectPath || basePath)}>
					{t('ra.action.back')}
				</Button>
			</Box>}
			{hasEdit && <Box ml={1} display="flex">
				<EditButton variant="outlined" basePath={basePath} record={data}/>
			</Box>}
			{hasShow && <Box ml={1} display="flex">
				<ShowButton variant="outlined" basePath={basePath} record={data}/>
			</Box>}
			{hasClone && (hasClone instanceof Function ? hasClone(data) : hasClone) && <Box ml={1} display="flex">
				<CloneButton variant="outlined" basePath={basePath} record={{...data, ...cloneMutableValues}}/>
			</Box>}
			{hasChange && (hasChange instanceof Function ? hasChange(data) : hasChange) && <Box ml={1} display="flex">
				<ChangeCustomerButton userId={permissions.id} record={data} />
			</Box>}
			{hasDelete && (hasDelete instanceof Function ? hasDelete(data) : hasDelete) && <Box ml={1} display="flex">
				<DeleteButton undoable={false} basePath={basePath} record={data}/>
			</Box>}
			{hasExtraButtons && (hasExtraButtons instanceof Function ? hasExtraButtons(data) : hasExtraButtons) && ExtraActionButtons && (
				<ExtraActionButtons {...props} />
			)}
			<Box display="flex" flex="1"/>
		</TopToolbar>
	);
};
