import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import { useDataProvider } from 'react-admin';

const TerminalsContext = createContext();

const processTerminals = (array) => {
	return array.reduce(
		(acc, item) => {
			acc.ids.push(item.id);
			acc.data[item.id] = item;
			if (acc.time_zone && acc.time_zone !== item.time_zone) {
				acc.isDifferentTZ = true;
			}
			return acc;
		},
		{ ids: [], data: {}, time_zone: "undefined", isDifferentTZ: false }
	);
};

const TerminalsProvider = ({ children }) => {
	const dataProvider = useDataProvider();
	// data is object of objects with id as a key.
	const [data, setData] = useState({});
	const [ids, setIds] = useState([]);
	const [sortedTerminalsASC, setSortedTerminalsASC] = useState([]);
	const [isDifferentTZ, setIsDifferentTZ] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		dataProvider
			.getList("terminal", {
				pagination: { page: 0, perPage: -1 },
				sort: { field: "id", order: "DESC" },
				filter: {},
			})
			.then((data) => {
				if (data?.data) {
					const _sortedTerminalsASC = data.data.sort((a, b) => a.id - b.id );
					setSortedTerminalsASC(_sortedTerminalsASC);
					const { ids, data: objects, isDifferentTZ } = processTerminals(data.data);
					setData(objects);
					setIds(ids);
					setIsDifferentTZ(isDifferentTZ);
				}
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [dataProvider]);

    const contextValue = { data, ids, loading, isDifferentTZ, sortedTerminalsASC };

	return <TerminalsContext.Provider value={contextValue}>{children}</TerminalsContext.Provider>;
};

const useTerminalsContext = () => {
	const context = useContext(TerminalsContext);

	if (!context) {
		throw new Error("useTerminals must be used within an TerminalsProvider");
	}

	return context;
};

export { TerminalsProvider, useTerminalsContext };
