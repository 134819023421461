import Tooltip from "@material-ui/core/Tooltip";
import React from "react";

const LabelWithTooltip = ({ label, tooltip }) => {
	return (
		<Tooltip title={tooltip} placement="top">
			<div>{label}</div>
		</Tooltip>
	);
};

export default LabelWithTooltip;
