import React from "react";
import { Create, SimpleForm, useTranslate } from 'react-admin';

import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';

import { ManagerCreateForm } from './CreateForm';
import { useOwnerCompanyDataContext } from "../../../contexts/OwnerCompanyDataProvider";


export const ManagerCreate = (props) => {
	const translate = useTranslate();
	const { ownerCompanyData } = useOwnerCompanyDataContext();

	const mainTitle = translate(`resources.${props.resource}.name`, {company_name: ownerCompanyData.short_name});
	const title = `${translate('ra.action.create')} ${mainTitle}`;

	return (
		<Create {...props} title={title}>
			<SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelToolbar />} redirect="list">
				<ManagerCreateForm />
			</SimpleForm>
		</Create>
	);
};
