import React, { useState } from 'react';
import { useTranslate } from 'react-admin';

import ImportButton from './ImportButton';
import PreviewDialog from './PreviewDialog';
import UploadDialog from './UploadDialog';

const StepsEnum = {
	none: 0,
	upload: 1,
	preview: 2,
};

const ImportPreviewButton = () => {
	const t = useTranslate();

	const [dialogOpen, setDialogOpen] = useState(StepsEnum.none);
	const [dialogPayload, setDialogPayload] = useState({
		[StepsEnum.upload]: {},
		[StepsEnum.preview]: {},
	});

	const handleOnDialogOpen = (stepType) => () => {
		setDialogOpen(stepType);
	};

	const handleOnDialogClose = () => {
		setDialogOpen(StepsEnum.none);
		setDialogPayload({
			[StepsEnum.upload]: {},
			[StepsEnum.preview]: {data: []},
		});
	};

	const handleOnSetDialogPayload = (stepType) => (data) => {
		setDialogPayload({
			...dialogPayload,
			[stepType]: data,
		});
	};

	return (
		<React.Fragment>
			<ImportButton 
				onClick={handleOnDialogOpen(StepsEnum.upload)} 
				label={t("osfc.dialogs.orders-import-upload.buttons.import")} 
			/>
			{dialogOpen === StepsEnum.upload && (
				<UploadDialog 
					onDialogClose={handleOnDialogClose} 
					onDialogOpen={handleOnDialogOpen(StepsEnum.preview)} 
					setSharedData={handleOnSetDialogPayload(StepsEnum.preview)} 
				/>
			)}
			{dialogOpen === StepsEnum.preview && (
				<PreviewDialog 
					onDialogClose={handleOnDialogClose} 
					sharedData={dialogPayload[StepsEnum.preview]} 
					setSharedData={handleOnSetDialogPayload(StepsEnum.preview)} 
				/>
			)}
		</React.Fragment>
	);
};

export {
	ImportPreviewButton as default,
};
