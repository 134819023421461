import React, {useCallback} from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useMemo } from 'react';
import {
	MenuItemLink,
	usePermissions,
	UserMenu,
	useLocale,
	useTranslate,
	useRedirect,
} from 'react-admin';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import Divider from '@material-ui/core/Divider';
import SettingsIcon from '@material-ui/icons/Settings';
import UserIcon from '@material-ui/icons/AccountCircle';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { AppRoles, UserRole, AppManagerRoles } from '../entities/';
import { LocaleSwitcher } from './LocaleSwitcher.js';

import HelpIcon from '@material-ui/icons/Help';
import Box from '@material-ui/core/Box';
import { IconButton, Badge } from '@material-ui/core';
import MessageIcon from '@material-ui/icons/Message'; // or 'ChatBubble' for an alternative chat icon
import { httpClient } from '../api/httpClient.js';
import { useSocketContext } from '../contexts/SocketProvider.js';
import { useNotifyError } from '../utils/notifiers/useNotifyError';
import { SocketRooms } from '../entities/SocketRooms';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isInArray } from '../utils/general';
import { DefaultLng } from '../utils/locale';
import CloseIcon from '@material-ui/icons/Close';

const MenuItemClickRaw = withStyles((theme) => ({
	active: {
		color: theme.palette.text.secondary,
	},
}))(MenuItemLink);

export const MenuItemClick = (props) => {
	return <MenuItemClickRaw to="#" {...props} />;
};

const useUserMenuStyles = makeStyles((theme) => ({
	userButton: {
		marginLeft: theme.spacing(1),
	},
}));

const CustomToast = (message) => {
	if (typeof message === 'string') {
		return(<p>{message}</p>)
	}
	else{
		const splitMessage = message['message'].split('<br>');

		return (
			<div>
				{splitMessage.map((line, index) => (
					<span key={index}>
						{line}
						{index < splitMessage.length - 1 && <br />}
					</span>
				))}
			</div>
		);
	}
};

const handleEventNotification = (eventsPayload) => {
	localStorage.setItem("events", JSON.stringify(eventsPayload));
	window.dispatchEvent(new Event('storage'));
};

const CloseButton = ({ closeToast }) => (
    <i
      className="material-icons"
      onClick={(event) => {
		event.stopPropagation();
		closeToast();
	  }}
    >
        <CloseIcon style={{ fontSize: '30px' }} />

    </i>
  );

export const MyUserMenu = (props) => {
	const location = useLocation();
	const { permissions } = usePermissions();
	const redirect = useRedirect();
	const notifyError = useNotifyError();
	const userMenuStyles = useUserMenuStyles();
	const translate = useTranslate();
	const [unreadCount, setUnreadCount] = React.useState(0);
	const { socket, error: socketError, joinRoom } = useSocketContext();
	const [currentManual, setCurrentManual] = React.useState(null);
	const [manuals, setManuals] = React.useState([]);

	React.useEffect(() => {
		socketError && notifyError(socketError);
	}, [socketError, notifyError]);

	React.useEffect(() => {
		if (socket?.connected && permissions) {
			if (permissions.role === UserRole.osfc_manager 
				|| permissions.role === UserRole.app_manager 
				|| permissions.role === UserRole.admin){
				joinRoom([socket.id, SocketRooms.common, SocketRooms.managers]);
			}
			else{
				joinRoom([socket.id, SocketRooms.common]);
			}
		}
	}, [socket?.id, socket?.connected, joinRoom, permissions]);

	React.useEffect(() => {
		if (socket) {
			socket.on('notification', (message) => toast.info(CustomToast(message), {
				position: "bottom-right",
				className: 'foo-bar',
				closeOnClick: false,
				autoClose: 1000*60*5, 
				pauseOnFocusLoss: false,
				closeButton: CloseButton,
				onClick: () => {
					if (typeof message !== 'string' && message['link']) {
						window.location.href = message['link']; 
					}
				}
			  }));
			socket.on('event_notification', handleEventNotification);
			return () => {
				socket.off('notification');
				socket.off('event_notification');
			};
		}
	}, [socket]);


	const fetchMessages = () => {
		// httpClient(`messages?filter=${encodeURIComponent(JSON.stringify({ unread: true }))}`, {
		httpClient(`messages-unread-count`, {
			method: 'GET',
		}).then((response) => {
			setUnreadCount(Number(response.body));
		});
	};
	React.useEffect(() => {
		if (permissions && isInArray(AppManagerRoles, permissions.role)) {
			fetchMessages();
			const intervalId = setInterval(fetchMessages, 60 * 1000);
			return () => clearInterval(intervalId);
		}
		const fetchManual = async () => {
            try {
                const response = await httpClient('user-manuals', {
                    method: 'GET',
                });
                setManuals(JSON.parse(response.body));
            } catch (error) {
                console.error('Failed to fetch user manual:', error);
            }
        };
        fetchManual();
	}, []);

	React.useEffect(() => {
		if (manuals && permissions) {
			const currentPath = location.pathname.startsWith('/') ? location.pathname.slice(1) : location.pathname;

			const foundManuals = manuals.filter(x => x.page_url === currentPath);
			setCurrentManual(foundManuals.find(x => x.locale === permissions.locale) || foundManuals.find(x => x.locale === DefaultLng));
		}		
    }, [location, manuals, permissions]);

	const handleMessagesClick = useCallback(() => {
		setUnreadCount(0);
		redirect('/messages');
	}, []);

	const handleManualClick = useCallback(() => {
		if (currentManual)
		window.open(currentManual.preview_src, '_blank');
	}, [currentManual]);

	if (!permissions) return null;

	return (
		<React.Fragment>
			{isInArray(AppManagerRoles, permissions.role) ? (
				<IconButton
					// to="/messages"
					onClick={handleMessagesClick}
					component={Link}
					style={{ color: 'white' }}
				>
					{unreadCount ? (
						<Badge badgeContent={unreadCount} color="error">
							<MessageIcon />
						</Badge>
					) : (
						<MessageIcon />
					)}
				</IconButton>
			) : null}
			<LocaleSwitcher />
			<Box ml={1}>
				<IconButton
					size="medium"
					onClick={handleManualClick}
					color="inherit"
					disabled={!currentManual}
				>
					{<HelpIcon />}
				</IconButton>
			</Box>
			<UserMenu {...props} classes={userMenuStyles}>
				<MenuItemLink
					to="#"
					sidebarIsOpen
					leftIcon={<UserIcon />}
					primaryText={permissions.email}
				/>
				<MenuItemLink
					to="/settings"
					primaryText={translate('user-menu.settings')}
					leftIcon={<SettingsIcon />}
				/>
				<Divider />
				{permissions.role === UserRole.admin ? (
					<MenuItemLink
						to="/developer"
						primaryText={translate('user-menu.developer')}
						leftIcon={<DeveloperModeIcon />}
					/>
				) : null}
			</UserMenu>
			<ToastContainer autoClose={false} position="top-right" />
		</React.Fragment>
	);
};
