import React from 'react';

import {
	usePermissions,
	ReferenceInput,
	SelectInput,
	useTranslate,
	BooleanInput,
	AutocompleteArrayInput,
	ReferenceArrayInput 
} from 'react-admin';
import { useFormState } from 'react-final-form';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';

import { UpdateForm } from './UpdateForm';
import { CardBlock } from './CardBlock';

import { AppRoles, UserRole } from '../../entities';
import { GridInput, GridForm } from '../../components/GridForm';
import { YesNoInput, EmailInput } from '../../components/inputs/';
import { SelectEnumInput } from '../../components/inputs/SelectEnumInput';
import { CommodityInput } from '../../resources/orders/forms/inputs/CommodityInput';
import LanguageSelect from '../../components/inputs/LanguageSelect';
import { isInArray } from '../../utils/general';
import { Currencies } from '../../entities/Currencies';
import { CompanyCustomerType } from '../../entities';


const CompanySettingsForm = (props) => {
	const { permissions } = usePermissions();
	const { values } = useFormState();
	
	const filter = props.currentCompanyId ? { "id:ne": props.currentCompanyId } : {};
	const isAppManager = permissions && isInArray(AppRoles, permissions.role);
	const isTransporter = values["type"] == CompanyCustomerType.transporter;

	return (
		<GridForm {...props}>
			<GridInput md={3} sm={6} component={LanguageSelect}/> 
			<GridInput lg={1} md={2} sm={6} component={SelectEnumInput} enumObj={Currencies}  translate={false} enumName="currency" source="currency" disabled={!isAppManager}/>
			<GridInput md={3} sm={6} component={EmailInput} source="deviation_notification_email" />
			{!isTransporter && <GridInput md={1} sm={6} component={YesNoInput} source="plumb_seal" defaultValue={false} />}		
			{!isTransporter && <GridInput md={3} sm={6} component={CommodityInput} source="commodity" />	}
			{/* <GridInput md={3} sm={6}>
				<ReferenceInput source="area_id" reference="areas" filter={byTerminalFilter}>
					<SelectInput optionText="name" />
				</ReferenceInput>
			</GridInput> */}
			<GridInput md={4} sm={6} options={{size: "small"}}>
				<ReferenceArrayInput source="preferred_transporters" reference="transporters" filter={filter}>
					<AutocompleteArrayInput optionText="full_name" />
				</ReferenceArrayInput>
			</GridInput>
			{!isTransporter && <GridInput md={1} sm={6}>
				<ReferenceInput source="terminal_id" reference="terminal">
					<SelectInput optionText="name" />
				</ReferenceInput>
			</GridInput>}
			{!isTransporter && <GridInput md={1} sm={6} component={YesNoInput} source="is_output_document" defaultValue={false} />}
			<GridInput md={3} sm={6}>
				<BooleanInput label="Copy docs" source="copy_docs_on_clone" />
			</GridInput>
		</GridForm>
	);
};


export const CompanySettingsBlock = (props) => {
	const { permissions } = usePermissions();
	const translate = useTranslate();

	const isAdminAppManager = permissions && isInArray(AppRoles, permissions.role);
	
	const currentCompanyId = permissions?.company_id || (props?.record && props.record.id);
	
	return (
		currentCompanyId ? <UpdateForm
			id={currentCompanyId}
			resource="customer-companies"
			render={({ body, buttons }) => (
				<CardBlock Component={isAdminAppManager ? Box : Card} 
						   title={isAdminAppManager ? "" : translate("tab.company_settings.name")} 
						   buttons={buttons} children={body} />
			)}
		>
			<CompanySettingsForm currentCompanyId={currentCompanyId} />
		</UpdateForm> : <></>
	);
}
