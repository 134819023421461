import React from "react";
import { useMutation, FunctionField, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MUIList from '@material-ui/core/List';
import MUIListItem from '@material-ui/core/ListItem';
import MUIListItemText from '@material-ui/core/ListItemText';


import { currencyOptions, currencyLocales, toPrice } from '../../../components/fields/PriceField';


const useStyles = makeStyles({
	nowrap: {
		whiteSpace: 'nowrap',
	},
	input: {
		width: '100px',
	},
});


const SpecialPricesButton = (props) => {
	const classes = useStyles();
	const translate = useTranslate()
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [prices, setPrices] = React.useState({});

	React.useEffect(() => {
		if (!props.selectedFields[props.recordId]) {
			setPrices({});
		}
	}, [props.selectedFields]);

	const [approve, { loading, data }] = useMutation({
		type: 'getManyReference',
		resource: 'pricelist-special-price',
		payload: {
			filter: {
				article_code: props.articleCode,
				terminal_id: props.terminalId,
				currency: props.selectedCurrency
			},
			pagination: { page: 1, perPage: 10 },
			sort: { field: 'id', order: 'DESC' },
		}
	});

	const handleClick = (e) => {
		try {
			approve();
		} catch (err) {
			console.error(err);
		}
		setAnchorEl(e.currentTarget);
		e.preventDefault();
		e.stopPropagation();
	};

	const handleClose = (e) => {
		setAnchorEl(null);
		e.preventDefault();
		e.stopPropagation();
	};

	const handlePriceChange = (e, item, recordId, articleCode) => {
		const newPrice = e.target.value;
		if (newPrice < 0) {
			return;
		}
		const updatedPrices = {
			...prices,
			[recordId]: {
				...prices[recordId],
				article_code: articleCode,
				terminal_id: item.terminal_id,
				[item.company_id]: newPrice === "" ? "" : newPrice,
			},
		};

		setPrices(updatedPrices);
		props.updateSelectedFieldsSpecialPrice(recordId, updatedPrices[recordId]);
		props.updateSelectedIds(recordId);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const getSpecialPriceColor = (item) => {
		if (props.selectedFields[props.recordId] && Object.keys(props.selectedFields[props.recordId].special_price || {}).indexOf(item.company_id.toString()) !== -1) {
			return 'orange';
		}
	}

	const getSpecialPriceButtonColor = (data) => {
		if (data && data.length && props.selectedFields[props.recordId]) {
			for (const item of data) {
				if (Object.keys(props.selectedFields[props.recordId].special_price || {}).indexOf(item.company_id.toString()) !== -1) {
					return 'orange';
				}
			}
		}
	}

	return (
		<div>
			<Button size="small" aria-describedby={id} variant="outlined" color="primary" onClick={handleClick} 
				style={{ backgroundColor: getSpecialPriceButtonColor(data) }}
			>
				{translate("resources.pricelist-articles.button.prices")}
			</Button>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'right',
				}}
			>
				<MUIList dense>
					{loading ? <CircularProgress /> : data && data.length ? data.map(item =>
						<MUIListItem button={true} key={item.company_id}>
							<Box className={classes.nowrap} display="flex" width="100%" justifyContent="space-between">
								<Box display="flex" mr={2}><Typography variant="body2">{item.company_name}</Typography></Box>
								{!props.editable ? <Box display="flex"><Typography variant="body2">{item.price.toLocaleString(currencyLocales, currencyOptions)}</Typography></Box> :
									<Box display="flex"><TextField
										className={classes.input}
										value={prices[props.recordId]?.[item.company_id] != null ? prices[props.recordId]?.[item.company_id] : item.price}
										onChange={(e) => handlePriceChange(e, item, props.recordId, props.articleCode)}
										variant="outlined"
										size="small"
										type="number"
										inputProps={{
											min: 1,
											step: 1,
											pattern: "[0-9]*",
											style: { padding: '8px', backgroundColor: getSpecialPriceColor(item) },
										}}

									/></Box>}
							</Box>
						</MUIListItem>
					) : <MUIListItem>
						<MUIListItemText primary={translate("resources.pricelist-articles.no_special_prices")} />
					</MUIListItem>}
				</MUIList>
			</Popover>
		</div>
	);
}


export const SpecialPricesField = (props) => {
    return (
        <FunctionField
            {...props}
            render={() => (
                <SpecialPricesButton
                    recordId={props.record.id}
                    updateSelectedIds={props.updateSelectedIds}
                    updateSelectedFieldsSpecialPrice={props.updateSelectedFieldsSpecialPrice} selectedCurrency={props.selectedCurrency}
                    selectedIds={props.selectedIds}
                    selectedFields={props.selectedFields}
                    editable={props.editable}
                    articleCode={props.record.code}
                    terminalId={props.record.terminal_id}
                />
            )}
        />
    );
};

SpecialPricesField.defaultProps = {
	addLabel: true,
};
