import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import { FunctionField, TextField, useNotify, useTranslate } from "react-admin";
export const useStyles = makeStyles({
	button: {
		fontSize: "inherit",
	},
});

function copyText(text, notify, translate) {
	const notifyError = () => {
		notify(
			<div style={{ width: "80vw", textWrap: "wrap", wordWrap: "break-word" }}>
				{translate("osfc.buttons.copy.error.is_not_supported") + "\n" + text}
			</div>,
			"error",
			{},
			false,
			1000000,
			true
		);
	};
	if (navigator.clipboard) {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				notify(translate("osfc.buttons.copy.success"), "info");
			})
			.catch(() => {
				notifyError();
			});
	} else {
		notifyError();
	}
}

export const CopyButtonWrapper = ({
	children,
	text,
	fontSizeInherited,
	stopClickPropagation = true,
	...props
}) => {
	const classes = useStyles();
	const notify = useNotify();
	const translate = useTranslate();

	const handleClick = (e) => {
		stopClickPropagation && e.stopPropagation();
		copyText(text, notify, translate);
	};

	return (
		<>
			<Tooltip title="Copy">
				<Button
					classes={fontSizeInherited ? { root: classes.button } : undefined}
					onClick={handleClick}
				>
					{React.cloneElement(children, props)}
				</Button>
			</Tooltip>
		</>
	);
};

export const CopyField = ({
	source,
	FieldComponent = TextField,
	stopClickPropagation,
	...props
}) => (
	<FunctionField
		render={(record) => (
			<CopyButtonWrapper text={record[source]} stopClickPropagation={stopClickPropagation}>
				<FieldComponent record={record} source={source} {...props} />
			</CopyButtonWrapper>
		)}
	/>
);
