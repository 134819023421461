export const OrderStatus = {
	DRAFT: 0,
	BOOKED: 1,
	ON_HOLD: 2,
	DOC_COMPLETED: 3,
	ONE_IN_TERMINAL: 4,
	LOAD_IN_TERMINAL_STORAGE: 5,
	PRODUCTION_LIST: 6,
	PLUKK_STARTED: 7,
	PLUKK_COMPLETED: 8,
	LOADING_READY: 9,
	LOADING_STARTED: 10,
	LOADING_COMPLETED: 11,
	ORDER_COMPLETED: 12,
	DELETED: 13,
	DEVIATION: 14,
}

export const OrderStatusCustomerEdit = [OrderStatus.DRAFT, OrderStatus.BOOKED, OrderStatus.ON_HOLD, OrderStatus.DOC_COMPLETED];
export const OrderStatusEditOrderTerminal = [OrderStatus.DRAFT, OrderStatus.BOOKED, OrderStatus.ON_HOLD, OrderStatus.DOC_COMPLETED];
export const OrderStatusEditOrderCustomerAndReference = [OrderStatus.DRAFT, OrderStatus.BOOKED, OrderStatus.LOAD_IN_TERMINAL_STORAGE];

// export const OrderStatusCustomerEditOrder = [OrderStatus.DRAFT, OrderStatus.BOOKED, OrderStatus.ON_HOLD, OrderStatus.DOC_COMPLETED, OrderStatus.ONE_IN_TERMINAL, OrderStatus.LOAD_IN_TERMINAL_STORAGE, OrderStatus.PLUKK_STARTED, OrderStatus.PLUKK_COMPLETED];
export const OrderStatusCustomerEditOrder = [OrderStatus.DRAFT, OrderStatus.BOOKED, OrderStatus.ON_HOLD, OrderStatus.DOC_COMPLETED, OrderStatus.ONE_IN_TERMINAL, OrderStatus.LOAD_IN_TERMINAL_STORAGE, OrderStatus.DEVIATION];

export const OrderStatusTransporterEditOrder_1 = [
	OrderStatus.BOOKED,
	OrderStatus.ON_HOLD,
	OrderStatus.DOC_COMPLETED,
	OrderStatus.ONE_IN_TERMINAL,
	OrderStatus.LOAD_IN_TERMINAL_STORAGE,
	OrderStatus.PRODUCTION_LIST
];
export const OrderStatusTransporterEditOrder_2 = [
	OrderStatus.BOOKED,
	OrderStatus.ON_HOLD,
	OrderStatus.DOC_COMPLETED,
	OrderStatus.ONE_IN_TERMINAL,
	OrderStatus.LOAD_IN_TERMINAL_STORAGE,
	OrderStatus.PRODUCTION_LIST,
	OrderStatus.PLUKK_STARTED,
	OrderStatus.PLUKK_COMPLETED,
	OrderStatus.LOADING_READY,
	OrderStatus.LOADING_STARTED,
	OrderStatus.LOADING_COMPLETED
];

export const OrderStatusOnCompleteEdit = [OrderStatus.LOADING_COMPLETED];

export const OrderStatusSkipNotifTrans = [OrderStatus.DRAFT, OrderStatus.BOOKED, OrderStatus.DOC_COMPLETED, OrderStatus.PLUKK_STARTED, OrderStatus.PLUKK_COMPLETED, OrderStatus.ORDER_COMPLETED];
export const OrderStatusSkipNotifDriver = [OrderStatus.DRAFT, OrderStatus.BOOKED, OrderStatus.DOC_COMPLETED, OrderStatus.PLUKK_STARTED, OrderStatus.PLUKK_COMPLETED, OrderStatus.ORDER_COMPLETED];

export const OrderStatusActive = [
	OrderStatus.BOOKED,
	OrderStatus.ON_HOLD,
	OrderStatus.DOC_COMPLETED,
	OrderStatus.ONE_IN_TERMINAL,
	OrderStatus.LOADING_READY,
	OrderStatus.LOADING_STARTED,
	OrderStatus.LOAD_IN_TERMINAL_STORAGE,
	OrderStatus.PLUKK_STARTED,
	OrderStatus.PLUKK_COMPLETED,
	OrderStatus.DEVIATION,
];

export const OrderStatusNotactive = [
	OrderStatus.LOADING_COMPLETED,
	OrderStatus.ORDER_COMPLETED,
	OrderStatus.DELETED,
];

export const OrderStatusRankRange = [
	OrderStatus.DOC_COMPLETED,
	OrderStatus.ONE_IN_TERMINAL,
	OrderStatus.LOAD_IN_TERMINAL_STORAGE,
	OrderStatus.PRODUCTION_LIST,
	OrderStatus.PLUKK_STARTED,
	OrderStatus.PLUKK_COMPLETED,
	OrderStatus.LOADING_READY,
	OrderStatus.LOADING_STARTED,
	OrderStatus.DEVIATION,
];

export const OrderStatusChangeCustomer = [
	...OrderStatusActive,
	OrderStatus.LOADING_COMPLETED
].filter((s) => s !== OrderStatus.DRAFT);

export const OrderNormalStatuses = Object.values(OrderStatus).filter(s => s !== OrderStatus.DRAFT);
