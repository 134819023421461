import { IconButton, Tooltip } from '@material-ui/core';
import RefreshTwoTone from '@material-ui/icons/RefreshTwoTone';
import { httpClient } from 'api/httpClient';
import { CacheEntities } from 'entities/CacheEntities';
import React from 'react';
import { useTranslate } from 'react-admin';
import { useNotifyError } from 'utils/notifiers/useNotifyError';
import { useNotifySuccess } from 'utils/notifiers/useNotifySuccess';

export const RefreshEntityCacheButton = ({ cacheEntityId }) => {
	const t = useTranslate();
	const notifySuccess = useNotifySuccess();
	const notifyError = useNotifyError();

	const handleRefreshClick = async () => {
		try {
			await httpClient(`/cache/${cacheEntityId}/refresh`, {
				method: 'POST',
			});
			notifySuccess(t('osfc.messages.success'));
		} catch {
			notifyError(t('osfc.messages.unsuccess'));
		}
	};

	if (Object.values(CacheEntities).indexOf(cacheEntityId) === -1) return;

	return (
		<Tooltip title={t('resources.terminal.buttons.refresh')}>
			<IconButton
				aria-label="refresh"
				onClick={handleRefreshClick}
				color='primary'
			>
				<RefreshTwoTone />
			</IconButton>
		</Tooltip>
	);
};
