import React from "react";
import { Datagrid, EditButton, EmailField, List, TextField } from "react-admin";

import { DatagridWithBlockProps } from "../../../components/_helpers/props/DatagridProps";
import { CopyField } from "../../../components/fields/CopyField";
import { PhoneField } from "../../../components/fields/PhoneField";
import { PriceCategoryField } from "../../../components/fields/PriceCategoryField";
import { YesNoField } from "../../../components/fields/YesNoField";
import { ScrollingWrapper } from "../../../components/ScrollingWrapper";
// import { CompanyExpandPanel } from './ItemExpand';
import BlockUnblockButton from "../../../components/toolbars/BlockUnblockButton";
import { CustomerCompanyActions } from "./Actions";
import { SelectEnumField } from "../../../components/fields/SelectEnumField";
import { CompanyCustomerType } from "../../../entities";
import { CompaniesFilter } from "./ListFilters";

const defaultSort = { field: "id", order: "DESC" };

export const CompaniesList = (props) => {
	return (
		<List
			{...props}
			exporter={false}
			bulkActionButtons={false}
			filters={<CompaniesFilter />}
			sort={defaultSort}
			perPage={22}
			actions={<CustomerCompanyActions />}
		>
			<ScrollingWrapper>
				<Datagrid
					{...DatagridWithBlockProps}
					// expand={<CompanyExpandPanel />}
					rowClick="show"
				>
					<TextField source="id" />
					<TextField source="full_name" />
					<TextField source="short_name" />
					<TextField source="accountancy_number" />
					<EmailField source="email" />
					<PhoneField source="phone" />
					<PriceCategoryField source="price_category" />
					<YesNoField source="set_order_completed" />
					<TextField source="currency" />
					<SelectEnumField source="type" enumObj={CompanyCustomerType} enumName={'company-type'}/>
					<CopyField
						source="guid"
						style={{
							width: "250px",
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
							display: "inline-block",
						}}
					/>
					<EditButton label="" />
					<BlockUnblockButton entity="company" />
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
};
