import PublishIcon from '@material-ui/icons/Publish';
import React from 'react';

import { ProgressIcon } from '../../ProgressIcon';
import { ToolbarButton } from '../OrdersExportDialog/ToolbarButton';

const ImportButton = ({ label, loading, disabled, onClick }) => (
	<ToolbarButton
		variant="text"
		disabled={disabled}
		icon={loading ? <ProgressIcon /> : <PublishIcon />}
		onClick={onClick}
	>
		{label}
	</ToolbarButton>
);

export {
	ImportButton as default,
};
