import React from 'react';
import { useState } from 'react';
import {Confirm, useRefresh, useNotify, useMutation, useTranslate} from 'react-admin';

import {makeStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box'
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';


import { OrderDocumentOutputType } from '../../../entities';

// TODO: move codes color to sahred place
const useStyles = makeStyles(t => ({	
	unloadButton: {
		backgroundColor: "rgba(183,255,125,0.2)"
	},
	loadButton: {
		backgroundColor: "rgba(255,140,140,0.1)"
	},
}));


export const GenerateDocumentsOutputButton = ({type, isLoading, setIsLoading, disabled, order, buttonText, margin, isOutputDoc}) => {
    const [open, setOpen] = useState(false);
	const refresh = useRefresh();
    const notify = useNotify();
	const translate = useTranslate();
	const classes = useStyles();

	const type_name = Object.keys(OrderDocumentOutputType).find(key => OrderDocumentOutputType[key] === type);

	let date = new Date();
	let offset = date.getTimezoneOffset();

	// TODO: Investigate if it is possible to use existing(or modified) useMutation that could handle Warning header
	const createOutDoc = async () =>{
		await fetch(`/orders/${order.id}/documents-output`, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				type: type,
				offset: (offset / 60) * -1,
			}),
		})
		.then(res => {
			const warning = res.headers.get("Warning");
			return res.json().then(data => ({ data, warning }));
		})
		.then(({ data, warning }) => {
			let message = translate(`osfc.dialogs.confirm_generate_doc.${type_name}.success`);

			if (!warning) {
				notify(message);
			}else{
				const warnings = warning.split("; ");
				notify(<>
					<Alert severity="warning">
					{message}<br/>
					{warnings.map((warn, index) => (
						<>Warning - {warn}<br/></>
					))}
					</Alert>
					</>,
					{
						type: "warning",
						autoHideDuration: 15000,
					})
			}
	
			handleDialogClose();
			refresh();
		})
		.catch(err => notify(err))
		.finally(() => setIsLoading(false));
	}

	const handleClick = (e) => {
		e.stopPropagation();
		if (isOutputDoc){
			setOpen(true);
		}
		else{
			setIsLoading(true)
			createOutDoc();
		}	
	};

    const handleDialogClose = () => setOpen(false);
	const handleConfirm = async () => {
		setIsLoading(true)
		createOutDoc();
		setOpen(false);
	};

	return (
        <Box m={margin}>
			{/* <Button variant="contained" className={isOutputDoc ? classes.loadButton : classes.unloadButton} onClick={handleClick} endIcon={type === OrderDocumentOutputType.loading ? <GetAppIcon/> : <PublishIcon/>}>{translate(buttonText)}</Button> */}

			<Button variant="contained" disabled={disabled} className={isOutputDoc ? classes.loadButton : classes.unloadButton} onClick={handleClick}>{translate(buttonText)}</Button>
				{isOutputDoc &&
				<Confirm
					isOpen={open}
					loading={isLoading}
					title={`osfc.dialogs.confirm_regenerate_doc.${type_name}.confirm.title`}
					content={`osfc.dialogs.confirm_regenerate_doc.${type_name}.confirm.content`}
					onConfirm={handleConfirm}
					onClose={handleDialogClose}
					confirm="osfc.buttons.confirm"
					confirmColor='warning'
				/>}
		</Box>	
	);
};