import { useRecordContext } from 'react-admin';
import React from 'react';
import { Table, 
	TableBody, 
	TableCell, 
	TableContainer, 
	TableHead, 
	TableRow } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { DisplayTaskActionTypeName } from '../../../entities/DisplayTaskActionType';

export const OutdoorDisplayTaskDisplayActionRowExpand = (props) => {
	const record = useRecordContext(props);
	return (
		<Box>
			<Card>
				<CardContent>
					<Typography variant="h6" gutterBottom>
						Task Actions
					</Typography>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Text</TableCell>
									<TableCell>Type</TableCell>
									<TableCell>Duration</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{record?.task_options?.map((action, index) => (
									<TableRow key={index}>
										<TableCell>{action.text}</TableCell>
										<TableCell>{DisplayTaskActionTypeName[action.type]}</TableCell>
										<TableCell>{action.duration}s</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</CardContent>
			</Card>
		</Box>
	)
}
