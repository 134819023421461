import React, { Fragment } from "react";
import {
	Button, List, Datagrid, TextField, usePermissions, useNotify, ChipField, ReferenceArrayField,
  FunctionField, SingleFieldList, useRefresh, Confirm
} from 'react-admin';
// import Button from '@material-ui/core/Button';
import { ScrollingWrapper } from "../../../components/ScrollingWrapper";
import ExportIcon from '@material-ui/icons/GetApp';
import { DateFormatField, DateTimeField } from '../../../components/fields/DateFormatField';
import { httpClient } from "../../../api/httpClient";
import { DeleteButton } from "../../../components/toolbars/DeleteButton";
import { WarningPopover } from "../../../components/popovers/WarningPopover";
import { PopupContent } from "../Create";
import { ReferenceArrayDialog } from "../components/ReferenceArrayDialog";

const defaultSort = { field: 'id', order: 'DESC' };

const CustomDownloadButton = ({ record }) => {

    return (
      <Button
        label={"XLSX"}
        variant="outlined"
        color="primary"
        href={record.preview_src}
        redirect={true}
        startIcon={<ExportIcon/>} 
      />
    );
  };
  
  
const CustomRegenerateButton = ({ record }) => {
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openWarnings, setOpenWarnings] = React.useState(false);
  const [lastWarnings, setLastWarnings] = React.useState([])
  const [invoicedOrderIds, setInvoicedOrderIds] = React.useState([]);
  const refresh = useRefresh()
  const notify = useNotify()

  const generateInvoiceRequest = async (data) => {
    const res = await httpClient("/invoices", {
      method: 'PUT',
      body: JSON.stringify(data),
    });
    return res 
  }

  const handleConfirm = () => {
    const data = {
              id: record.id,
              ignore_warnings: false,
              invoiced_order_ids: invoicedOrderIds
           }
    const response = generateInvoiceRequest(data).then(data => {
      if (data.json.warnings && data.json.warnings.length > 0) {
        setLastWarnings(data.json.warnings);
        setOpenConfirm(false);
        setOpenWarnings(true);
      } else {
        refresh();
      }
    }).catch(error => {
      notify(error.message, "error")
    });
  };

  const handleRegenerate = () => {
    const data = {
      id: record.id,
      ignore_warnings: true,
      invoiced_order_ids: invoicedOrderIds
    }
    const response = generateInvoiceRequest(data).then(data => {     
        refresh();
    }).catch(error => {
      notify(error.message, "error")
    });
  };

  
  const handleClickOpenConfirm = (e) => {
    e.stopPropagation();
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleCloseWarnings = () => {
    setOpenWarnings(false);
    setInvoicedOrderIds([])
  };


  return (
    <Fragment>
        <Button
            label='osfc.fields.document.regenerate'
            variant="contained"
            color="secondary"
            onClick={handleClickOpenConfirm}
        />
        <Confirm
            isOpen={openConfirm}
            title='osfc.fields.document.regenerate'
            content='osfc.dialogs.confirm_regenerate.content'
            onConfirm={handleConfirm}
            onClose={handleCloseConfirm}
            confirm='osfc.buttons.confirm'
        />
        <WarningPopover PopupContent={PopupContent} open={openWarnings} data={lastWarnings} title='osfc.dialogs.invoices.warning.title' handleClose={handleCloseWarnings} handleIgnore={handleRegenerate} invoicedOrderIds={invoicedOrderIds}
                setInvoicedOrderIds={setInvoicedOrderIds}/>
    </Fragment>
  );
};
  

export const InvoiceList = (props) => {
	const { permissions } = usePermissions();

  if (!permissions) {
		return null;
	}

	return (
		<List
			{...props}
			exporter={false}
      empty={false}
			bulkActionButtons={false}
			sort={defaultSort}
		>
			<ScrollingWrapper>
				<Datagrid>
					<TextField source="id" />
					<DateFormatField source="date_from" withLocalTimeZone/>
					<DateFormatField source="date_to" withLocalTimeZone/>
          {/* <ReferenceArrayField reference="orders" source="order_ids" sortable={false}>
              <SingleFieldList linkType="show" style={{ display: "block" }}>
                  <ChipField source="id" size="small"/>
              </SingleFieldList>
          </ReferenceArrayField> */}
          <FunctionField source={"order_ids"} {...props} sortable={false} render={record => {
              return <ReferenceArrayDialog record={record}/>
          }} />
          <DateTimeField source="created_at" withLocalTimeZone/>
					<CustomDownloadButton />
          <CustomRegenerateButton />
          <DeleteButton
              resource={props.resource}
              notifyMessage="osfc.dialogs.confirm_delete_invoices.success"
              title="osfc.dialogs.confirm_delete_invoices.confirm.title"
              content="osfc.dialogs.confirm_delete_invoices.confirm.content"
          />
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
};
