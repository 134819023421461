import React from 'react';
import {Show, TabbedShowLayout, useRecordContext} from 'react-admin';

import {ActionsWithBackButton} from '../../../components/actionbars/ActionsWithBackButton';
import { OrderStatus } from '../../../entities';
import {AppManagerRoles, OSFCUserRoles, UserRole} from '../../../entities/UserRole';
import { isInArray } from '../../../utils/general';
import {ChatTab, DocumentsTab, GeneralTab, OrderHistoryTab, OrderPrcelistHistoryTab, OutDocumentsTab, PlugTab} from "./Tabs"

export const tabbedShowLayoutPadding = 8;

// export const OrderShowTabsWrapper = ({permissions, hasShow, children, ...props}) => {
// 	const isOSFCManager = React.useMemo(() => {
// 		if (permissions) {
// 			return (
// 				permissions.role === UserRole.osfc_employee
// 				|| permissions.role === UserRole.osfc_manager
// 			);
// 		}
// 		return false;
// 	}, [permissions]);
// 	if (!permissions) return null;

// 	return (
// 		<Show actions={<ActionsWithBackButton hasEdit={!permissions['company_is_blocked']}/>} {...props}>
// 			<TabbedShowLayout style={{paddingBottom: tabbedShowLayoutPadding}}>
// 				{React.Children.map(children, (tab) => {
// 					return React.cloneElement(tab, {isOSFCManager: isOSFCManager, permissions: permissions});
// 				})}
// 			</TabbedShowLayout>
// 		</Show>
// 	);
// };

// export const OrderShow = (props) => {
// 	if (!props.permissions) return null;
	
// 	const isOSFCManager = props.permissions && props.permissions.role === UserRole.osfc_manager;	

// 	return (
// 		<OrderShowTabsWrapper {...props}>
// 			<GeneralTab/>
// 			{isOSFCManager ? <OrderHistoryTab /> : <PlugTab/>}
// 			{isOSFCManager ? <OrderPrcelistHistoryTab/> : <PlugTab/>}			
// 			<DocumentsTab/>
// 			<ChatTab/>
// 		</OrderShowTabsWrapper>
// 	);
// }

export const OrderShowTabsWrapper = ({permissions, ..._props}) => {
	if (!permissions) return null;
	const props = {..._props};

	const isDraft = window.location.href.indexOf("draft-orders") !== -1;
	if (isDraft) {
		props.resource = "orders";
		props.title = "resources.draft-orders.name";
	}

	return (
		<Show actions={<ActionsWithBackButton hasEdit={!permissions['company_is_blocked']}/>} {...props}>
			<OrderShow permissions={permissions} />
		</Show>
	);
};
const OrderShow = (props) => {
	const record = useRecordContext();

	let isOrderHistoryTab = false,
		isOrderPrcelistHistoryTab = false,
		isCustomerAsTrans = false,
		isOutDocumentsTab = true;
	
	switch(props.permissions.role){
		case UserRole.osfc_manager:
			isOrderHistoryTab = true;
			isOrderPrcelistHistoryTab = true;
			break;
		case UserRole.app_manager:
			isOrderHistoryTab = true;
			isOrderPrcelistHistoryTab = true;
			break;
		case UserRole.admin:
			isOrderHistoryTab = true;
			isOrderPrcelistHistoryTab = true;
			break;
		case UserRole.customer_manager:
			isOrderHistoryTab = true;
			break;
		case UserRole.customer_and_transporter_manager:
			isOrderHistoryTab = record["company_id"] === props.permissions["company_id"];
			isCustomerAsTrans = !isOrderHistoryTab;
			break;
		case UserRole.customer_and_transporter_employee:
			isCustomerAsTrans = record["company_id"] !== props.permissions["company_id"];
			break;
		case UserRole.customer_and_transporter_driver:
			isCustomerAsTrans = true;
			break;
		default:
			break;
	}

	if (record.status === OrderStatus.DRAFT) {
		isOrderPrcelistHistoryTab = false;
		isOutDocumentsTab = false;
	}

	// TODO: check if need to pass isOSFCManager={isOSFCManager} permissions={props.permissions} to each tab

	const isOSFCManager = props.permissions && isInArray(AppManagerRoles, props.permissions.role);

	const isOSFCUser = props.permissions && OSFCUserRoles.has(props.permissions.role);

	return (
		<TabbedShowLayout style={{paddingBottom: tabbedShowLayoutPadding}} {...props}>
			<GeneralTab isOSFCManager={isOSFCManager} isCustomerAsTrans={isCustomerAsTrans} permissions={props.permissions} label="tab.geneneral.name"/>
			{isOrderHistoryTab ? <OrderHistoryTab isOSFCManager={isOSFCManager} permissions={props.permissions} label="tab.order_history.name"/> : <PlugTab/>}
			{isOrderPrcelistHistoryTab ? <OrderPrcelistHistoryTab isOSFCManager={isOSFCManager} permissions={props.permissions} label="tab.order_pricelist_history.name"/> : <PlugTab/>}			
			<DocumentsTab isOSFCManager={isOSFCManager} isCustomerAsTrans={isCustomerAsTrans} permissions={props.permissions} label="tab.documents.name"/>
			{isOSFCUser && isOutDocumentsTab ? <OutDocumentsTab isOSFCManager={isOSFCManager} isCustomerAsTrans={isCustomerAsTrans} permissions={props.permissions} label="tab.out-documents.name"/> : <PlugTab/>}
			<ChatTab isOSFCManager={isOSFCManager} permissions={props.permissions} label="tab.chat.name"/>
		</TabbedShowLayout>
	);
}

// export const OrderShowForTransporter = (props) => {
// 	return (
// 		<OrderShowTabsWrapper {...props} >
// 			<GeneralTab isPricelistNeed={false}/>
// 			<PlugTab/>
// 			<PlugTab/>
// 			<DocumentsTab/>
// 			<ChatTab/>
// 		</OrderShowTabsWrapper>
// 	);
// }

// export const OrderShowForCustomerTransporter = (props) => {	
// 	return (
// 		<OrderShowTabsWrapper {...props} >
// 			<GeneralTab isPricelistNeed={null}/>
// 			<PlugTab/>
// 			<PlugTab/>
// 			<DocumentsTab/>
// 			<ChatTab/>
// 		</OrderShowTabsWrapper>
// 	);
// }
