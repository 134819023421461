import React from "react";

import { Create, SimpleForm } from 'react-admin';

import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';
import { MappersCreateForm } from './CreateForm';


export const MappersCreate = ({permissions, ...props}) => {
	return (
		<Create {...props}>
			<SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelToolbar />} redirect="list">
				<MappersCreateForm />
			</SimpleForm>
		</Create>
	);
};
