import React from 'react';
import { Datagrid, ListContextProvider } from 'react-admin';

import { CssRowFieldCellWrapper } from '../fields/CssFieldCellWrapper';
import { ScrollingWrapper } from '../ScrollingWrapper';

export const HighlightList = ({value, children, cssFnRow}) => (
	value ? (
		<ListContextProvider value={value}>
			<ScrollingWrapper>
				<Datagrid size="small" rowClick={null} hover={false}>
					<CssRowFieldCellWrapper cssFn={cssFnRow} />
					{children}
				</Datagrid>
			</ScrollingWrapper>
		</ListContextProvider>
		) 
	: <></>
);
