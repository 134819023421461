import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SubmitIcon from '@material-ui/icons/Save';
import { keyBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Confirm, NumberField, TextField, useMutation, useRefresh, useTranslate } from 'react-admin';

import { LabelSource } from '../../../entities';
import { useNotifyError } from '../../../utils/notifiers/useNotifyError';
import { useNotifySuccess } from '../../../utils/notifiers/useNotifySuccess';
import { FakeReferenceTextField } from '../../fields/FakeReferenceTextField';
import { LongTextField } from '../../fields/LongTextField';
import { NoneOrValueField } from '../../fields/NoOrValueField';
import { PriorityField } from '../../fields/PriorityField';
import { SelectEnumField } from '../../fields/SelectEnumField';
import { YesNoField } from '../../fields/YesNoField';
import { WarningField, WarningList } from '../../HighlightList';

const PreviewDialog = ({ onDialogClose, sharedData, setSharedData }) => {
	const t = useTranslate();
	const refresh = useRefresh();
	const notifyError = useNotifyError();
	const notifySuccess = useNotifySuccess();

	const [ordersWithWarningsData, setOrdersWithWarningsData] = useState(sharedData?.data || []);
	const [submitConfirmOpen, setSubmitConfirmOpen] = useState(false);

	const [orderCreate, { loading }] = useMutation({
        type: 'create',
        resource: 'orders',
		payload: {
			data: ordersWithWarningsData.filter(item => !item.details.is_error),
		}
    }, {
		onSuccess: () => {
			notifySuccess(t("osfc.dialogs.orders-import-preview.msg.success"));
			setOrdersWithWarningsData([]);
			setSharedData({});
			onDialogClose();
			refresh();
		},
		onFailure: (error) => {
			notifyError(error);
		}
	});

	const handleOnSubmitConfirm = () => {
		setSubmitConfirmOpen(false);
		orderCreate();
	};

	const handleOnSubmit = () => {
		if (ordersHaveErrors) {
			setSubmitConfirmOpen(true);
			return;
		}
		orderCreate();
	};

	const ordersHaveErrors = ordersWithWarningsData.filter(item => item.details.is_error).length ? true : false;

	return (
		<Dialog open maxWidth="lg" onClose={onDialogClose} fullWidth scroll="body">
			<DialogTitle>{t("osfc.dialogs.orders-import-preview.title")}</DialogTitle>
			<DialogContent style={{ maxHeight: "80vh", overflow: "auto"}}>
				<PreviewGrid data={ordersWithWarningsData} />
			</DialogContent>
			<DialogActions>
				<Button variant="contained" disabled={loading} label={t("ra.action.cancel")} onClick={onDialogClose} color="secondary" />
				<Button 
					variant="contained" 
					label={t("osfc.buttons.submit")} 
					onClick={handleOnSubmit} 
					color="primary" 
					startIcon={loading ? <CircularProgress size={16} thickness={5} color="inherit" /> : <SubmitIcon />}
				/>
				{ordersHaveErrors &&
					<Confirm
						isOpen={submitConfirmOpen}
						content="osfc.dialogs.orders-import-preview.dialogs.confirm.content"
						title="osfc.dialogs.orders-import-preview.dialogs.confirm.title"
						onConfirm={handleOnSubmitConfirm}
						onClose={() => setSubmitConfirmOpen(false)}
					/>
				}
			</DialogActions>
		</Dialog>
	);
};

export const PreviewGrid = ({ data }) => {
	const [ids, setIds] = React.useState();
	const [dicData, setDicData] = React.useState();

	useEffect(()=> {		
		if (data) {
			const _data = data.map((d, index) => ({...d, "id": index + 1}));
			const _ids = _data.map(x => x["id"]);
			setIds(_ids);
			const _dicData = keyBy(_data, "id");
			setDicData(_dicData);
		} else {
			setIds(undefined);
			setDicData(undefined);
		}
	}, [data]);

	const listValue = { ids: ids, data: dicData, currentSort: { field: 'id', order: 'ASC' } };

	return (
		(dicData && ids) ?
			<WarningList value={listValue}>
				<WarningField component={FakeReferenceTextField} source="company_id" label="resources.orders.fields.company_id" sortable={false} replaceSource="customer_name" />
				<WarningField component={PriorityField} source="priority" label="resources.orders.fields.priority" sortable={false} />
				<WarningField component={TextField} source="reference" label="resources.orders.fields.reference" sortable={false} />
				<WarningField component={FakeReferenceTextField} source="status" label="resources.orders.fields.status" sortable={false} replaceSource="status_name" />
				<WarningField component={FakeReferenceTextField} source="service" label="resources.orders.fields.service" sortable={false} replaceSource="service_name" />
				<WarningField component={FakeReferenceTextField} source="terminal_id" label="resources.orders.fields.terminal" sortable={false} replaceSource="terminal_name" />

				<WarningField component={TextField} source="eta_date" label="resources.orders.fields.eta_date" sortable={false} />
				<WarningField component={TextField} source="eta_time" label="resources.orders.fields.eta_time" sortable={false} />
				<WarningField component={TextField} source="eta_slot_time" label="resources.orders.fields.eta_slot_time" sortable={false} />
				<WarningField component={FakeReferenceTextField} source="port_in" label="resources.orders.fields.port_in" sortable={false} replaceSource="port_in_name" />
				<WarningField component={FakeReferenceTextField} source="eta_3rd_party" label="resources.orders.fields.eta_3rd_party" sortable={false} replaceSource="eta_3rd_party_name" />
				<WarningField component={YesNoField} source="eta_show_doc_for_trans" label="resources.orders.fields.eta_show_doc" sortable={false} />
				<WarningField component={FakeReferenceTextField} source="eta_driver|eta_driver_id" label="resources.orders.fields.eta_driver" sortable={false} replaceSource="eta_driver_name" />
				<WarningField component={TextField} source="eta_driver_phone" label="resources.orders.fields.eta_driver_phone" sortable={false} />
				<WarningField component={FakeReferenceTextField} source="eta_truck|eta_truck_id" label="resources.orders.fields.eta_truck" sortable={false} replaceSource="eta_truck_name" />
				<WarningField component={FakeReferenceTextField} source="eta_trailer|eta_trailer_id" label="resources.orders.fields.eta_trailer" sortable={false} replaceSource="eta_trailer_name" />
				<WarningField component={LongTextField} source="place_from" label="resources.orders.fields.place_from" sortable={false} />
				<WarningField component={YesNoField} source="is_eta_3rd_party_reload" label="resources.orders.fields.is_eta_3rd_party_reload" sortable={false} />
				<WarningField component={FakeReferenceTextField} source="assignee_1" label="resources.orders.fields.assignee_1" sortable={false} replaceSource="assignee_1_name" />

				<WarningField component={TextField} source="etd_date" label="resources.orders.fields.etd_date" sortable={false} />
				<WarningField component={TextField} source="etd_time" label="resources.orders.fields.etd_time" sortable={false} />
				<WarningField component={TextField} source="etd_slot_time" label="resources.orders.fields.etd_slot_time" sortable={false} />
				<WarningField component={FakeReferenceTextField} source="port_out" label="resources.orders.fields.port_out" sortable={false} replaceSource="port_out_name" />
				<WarningField component={FakeReferenceTextField} source="etd_3rd_party" label="resources.orders.fields.etd_3rd_party" sortable={false} replaceSource="etd_3rd_party_name" />
				<WarningField component={YesNoField} source="etd_show_doc_for_trans" label="resources.orders.fields.etd_show_doc" sortable={false} />
				<WarningField component={FakeReferenceTextField} source="etd_driver|etd_driver_id" label="resources.orders.fields.eta_driver" sortable={false} replaceSource="etd_driver_name" />
				<WarningField component={TextField} source="etd_driver_phone" label="resources.orders.fields.etd_driver_phone" sortable={false} />
				<WarningField component={FakeReferenceTextField} source="etd_truck|etd_truck_id" label="resources.orders.fields.etd_truck" sortable={false} replaceSource="etd_truck_name" />
				<WarningField component={FakeReferenceTextField} source="etd_trailer|etd_trailer_id" label="resources.orders.fields.etd_trailer" sortable={false} replaceSource="etd_trailer_name" />
				<WarningField component={LongTextField} source="place_to" label="resources.orders.fields.place_to" sortable={false} />
				<WarningField component={YesNoField} source="is_etd_3rd_party_reload" label="resources.orders.fields.is_etd_3rd_party_reload" sortable={false} />
				<WarningField component={FakeReferenceTextField} source="assignee_2" label="resources.orders.fields.assignee_2" sortable={false} replaceSource="assignee_2_name" />

				<WarningField component={NumberField} source="boxes" label="resources.orders.fields.boxes" sortable={false} />
				<WarningField component={NumberField} source="label" label="resources.orders.fields.label" sortable={false} />
				<WarningField component={SelectEnumField} source="label_source" label="resources.orders.fields.label_source" sortable={false} enumName="label_source" enumObj={LabelSource} />
				<WarningField component={NumberField} source="pallets" label="resources.orders.fields.pallets" sortable={false} />
				<WarningField component={NumberField} source="kilos" label="resources.orders.fields.kilos" sortable={false} />
				<WarningField component={YesNoField} source="is_doc_for_nfsa" label="resources.orders.fields.is_doc_for_nfsa" sortable={false} />
				<WarningField component={TextField} source="commodity" label="resources.orders.fields.commodity" sortable={false} />
				<WarningField component={TextField} source="cooler_type_name" label="resources.orders.fields.cooler_type_id" sortable={false} />
				<WarningField component={YesNoField} source="split_load" label="resources.orders.fields.split_load" sortable={false} />
				<WarningField component={TextField} source="trip_number" label="resources.orders.fields.trip_number" sortable={false} />
				<WarningField component={NumberField} source="loading_order" label="resources.orders.fields.loading_order" sortable={false} />
				<WarningField component={YesNoField} source="plumb_seal" label="resources.orders.fields.plumb_seal" sortable={false} />
				<WarningField component={NoneOrValueField} source="printed_doc" label="resources.orders.fields.printed_doc" sortable={false} />
				<WarningField component={TextField} source="extra_loading_time" label="resources.orders.fields.extra_loading_time" sortable={false} />
				<WarningField component={YesNoField} source="is_extra_loading_time_billable" label="resources.orders.fields.is_extra_loading_time_billable" sortable={false} />
				<WarningField component={YesNoField} source="is_area_blocked" label="resources.orders.fields.is_area_blocked" sortable={false} />
				<WarningField component={TextField} source="area_name" label="resources.orders.fields.area_id" sortable={false} />
				<WarningField component={YesNoField} source="set_order_completed" label="resources.orders.fields.set_order_completed" sortable={false} />
				<WarningField component={TextField} source="notes" label="resources.orders.fields.notes" sortable={false} maxWidth={100} />
				<WarningField component={TextField} source="internal_notes" label="resources.orders.fields.internal_notes" sortable={false} maxWidth={100} />
			</WarningList> 
		:
			<></>
	);
};

export {
	PreviewDialog as default,
};
