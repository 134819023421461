import React from "react";

import { Edit, SimpleForm } from 'react-admin';

import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';
import { MappersEditForm } from './EditForm';

export const MappersEdit = ({permissions, ...props}) => {
	return (
		<Edit {...props} undoable={false}>
			<SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelToolbar />}>
				<MappersEditForm />
			</SimpleForm>
		</Edit>
	);
};
