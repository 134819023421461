import React from "react";
import { Edit, SimpleForm, useTranslate } from 'react-admin';

import { SaveWithCancelWithPasswordChangeToolbar } from '../../../components/toolbars/SaveWithCancelWithPasswordChangeToolbar';

import { ManagerEditForm } from './EditForm';
import { useOwnerCompanyDataContext } from "../../../contexts/OwnerCompanyDataProvider";


export const ManagerEdit = (props) => {
	const translate = useTranslate();
	const { ownerCompanyData } = useOwnerCompanyDataContext();

	const mainTitle = translate(`resources.${props.resource}.name`, {company_name: ownerCompanyData.short_name});
	const title = `${mainTitle} #${props.id}`;

	return (
		<Edit {...props} undoable={false} title={title}>
			<SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelWithPasswordChangeToolbar />}>
				<ManagerEditForm />
			</SimpleForm>
		</Edit>
	);
};
