import React from "react";
import { Edit, SimpleForm } from "react-admin";

import { SaveWithCancelToolbar } from "../../../components/toolbars/SaveWithCancelToolbar";
import { ExternalTerminalsEditForm } from "./EditForm";

export const ExternalTerminalsEdit = (props) => (
	<Edit {...props} undoable={false}>
		<SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelToolbar />}>
			<ExternalTerminalsEditForm />
		</SimpleForm>
	</Edit>
);
