import { isInArray } from "utils/general";
import { AppManagerRoles, CustomerAndTransporterRoles, CustomerRoles, OsfcRoles, TransporterRoles, UserRole } from "./UserRole";

export const CompanyCustomerType = {
	customer: 2,
	transporter: 3,
	customer_and_transporter: 4,
}

export const CompanyType = {
	none: null,
	admin: 0,	
	base: 1,
	...CompanyCustomerType,
}



export const getCompanyTypeByRole = (userRole) => {
	if (!userRole) return CompanyType.none;	
	if (isInArray(AppManagerRoles, userRole)) return CompanyType.admin;
	if (isInArray(OsfcRoles, userRole)) return CompanyType.base;
	if (isInArray(CustomerRoles, userRole)) return CompanyType.customer;
	if (isInArray(CustomerAndTransporterRoles, userRole)) return CompanyType.customer_and_transporter;
	if (isInArray(TransporterRoles, userRole)) return CompanyType.transporter;
};