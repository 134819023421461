import React from 'react';
import { useTranslate, SelectField } from 'react-admin';


export const SelectEnumField = ({enumObj, enumName, translate = true, reverse = false, ...props}) => {
	const translator = useTranslate();
	// TODO: find out more better approach to translate enum
	const choices = translate ? Object.keys(enumObj).map(key => (
		!reverse ? {id: enumObj[key], name: translator(`osfc.enums.${enumName}.${key}`)} 
		: {id: parseInt(key), name: enumObj[key]}
	)) :
		Object.keys(enumObj).map(key => (
			!reverse ? {id: enumObj[key], name:key} 
			: {id: parseInt(key), name: enumObj[key]}
		));
	return (
		<SelectField
			{...props}
			choices={choices}
			// parse={parse}
		/>
	);
};


SelectEnumField.defaultProps = {
	addLabel: true,
};
