import React from "react";

import { TextInput, required } from 'react-admin';
import { useFormState, useForm } from 'react-final-form';

import { GridForm, GridInput } from '../../../components/GridForm/';
import { MapperTypeSelect } from "../../../components/inputs/MapperTypeSelect";
import { MapperDataInputPredefined } from "../components/MapperDataInput";
import { MapperType } from "../../../entities/MapperType";
import { getOptionalKeysByType, predefinedByType } from "../../../utils/mapper";


export const MappersCreateForm = ({...props}) => {
	const { values } = useFormState();
	const form = useForm();

	const optKeys = getOptionalKeysByType(values["type"]);
	const predefinedData = predefinedByType[values["type"]];

	React.useEffect(() => {
        if (values["type"]) {
            form.change("data", predefinedData); 
        }
    }, [values["type"], form, predefinedData]);

	
	return (
	<GridForm initialValues={{ data: predefinedData }}>
		<GridInput xs={6} component={TextInput} source="name" validate={required()}/>
		<GridInput xs={6} component={MapperTypeSelect} source="type" defaultValue={MapperType.order_import} disabled />
		<GridInput xs={12} source="data">
			<MapperDataInputPredefined optionalKeys={optKeys} formData={values}/>
		</GridInput>
	</GridForm>

)};
