import React from "react";
import { Resource } from "react-admin";
import { Route } from "react-router-dom";

import { CompanySettingsPage } from "../../pages/settings/";
import { apiKeysRouts } from "../../pages/settings/APIKeys";
import { pricelistArticlesResource } from "../../resources/customer-pricelist-articles";
import { customerUsers } from "../../resources/customer-users";
import { customerDriversResource } from "../../resources/drivers/";
import Messages from "../../resources/messages/List/List";
import { ordersByAPIKeysResource } from "../../resources/orders-by-api-keys";
import {
	OrderCreateByCustomer,
	OrderEditByCustomerTransporter,
	OrderShowTabsWrapper,
	ordersResource,
} from "../../resources/orders/";
import { pricelistHolidaysResource } from "../../resources/pricelist-holidays/";
import { transportTrailersResource } from "../../resources/trailers";
import { transportTrucksResource } from "../../resources/trucks";

export const customerAndTransporterManagerResources = [
	<Resource
		{...ordersResource}
		edit={OrderEditByCustomerTransporter}
		create={OrderCreateByCustomer}
		show={OrderShowTabsWrapper}
		options={{ label: "Orders" }}
		name="orders"
	/>,
	<Resource name="orders-by-api-keys" {...ordersByAPIKeysResource} />,

	// <Resource {...customersEmployeesResource} name="customer-employees" />,
	<Resource {...customerUsers} name="customer-users" options={{ label: "Employees" }} />,
	// <Resource {...transportersResource} create={null} edit={null} name="transporters" />,
	<Resource {...transportTrucksResource} name="trucks" />,
	<Resource {...transportTrailersResource} name="trailers" />,
	<Resource name="drivers" {...customerDriversResource} />,
	<Resource
		{...pricelistArticlesResource}
		name="customer-pricelist-articles"
		options={{ hasUpload: false }}
	/>,
	<Resource {...pricelistHolidaysResource} name="pricelist-holidays" />,
	<Resource name="ports" intent="registration" />,
	<Resource name="areas" intent="registration" />,
	<Resource name="users" intent="registration" />,
	<Resource name="customer-companies" intent="registration" />,
	<Resource name="company-notifications" intent="registration" />,
	<Resource name="order-statuses" intent="registration" />,
	<Resource name="outgoing-doc-statuses" intent="registration" />,
	<Resource name="cooler-types" intent="registration" />,
	<Resource name="order-pricelist" intent="registration" />,
	<Resource name="order-history" intent="registration" />,
	<Resource name="transporters" intent="registration" />,
	<Resource name="terminal" intent="registration" />,
	<Resource name="external-terminal" intent="registration" />,
	<Resource name="api-keys" intent="registration" />,
	<Resource name="company-users" intent="registration" />,
];

export const customerAndTransporterManagerRoutes = [
	...apiKeysRouts,
	<Route exact path="/messages" component={Messages} />,
	<Route exact path="/notifications" component={CompanySettingsPage} />,
];
