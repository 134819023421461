import React from "react";
import { Filter } from "react-admin";

import TextInputEx from "../../../components/_extensions/react-admin/input/TextInputEx";

export const ExternalTerminalsFilter = (props) => (
	<Filter {...props}>
		<TextInputEx source="name" />
		<TextInputEx source="guid" />
		<TextInputEx source="api_url" />
		<TextInputEx source="api_key" />
	</Filter>
);
