import React from "react";

import { Edit } from 'react-admin';
import { TaskDisplayActionForm } from "../Form/TaskDisplayActionForm";
import { useMutateForm } from '../../../api/useMutateForm';
import { SimpleFormAdv } from "../../../components/forms/SimpleFormAdv";
import { ActionsWithBackButton } from "../../../components/actionbars/ActionsWithBackButton";

export const TaskDisplayActionEdit = ({permissions, ...props}) => {
	const [mutateForm, state] = useMutateForm("update", 
        props.resource, props.basePath, props.id, {}); 

    if(!permissions) return null;
    const actionsProps = {
        hasList: true,
    };
	return (
		<Edit {...props}
			undoable={false}
			actions={
					<ActionsWithBackButton 
						{...actionsProps} 						
					/>
				}>
			<SimpleFormAdv submitOnEnter={false} save={mutateForm} >
				<TaskDisplayActionForm  />
			</SimpleFormAdv>
		</Edit>
	);
};