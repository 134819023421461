import React from 'react';
import { Create, SimpleForm } from 'react-admin';

import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';
import { VehicleANPRCamerasForm } from '../forms/VehicleANPRCamerasForm';


export const VehicleANPRCamerasCreate = (props) => (
    <Create {...props}>
        <SimpleForm 
            submitOnEnter={false} 
            toolbar={<SaveWithCancelToolbar />} 
            redirect='list'
        >
            <VehicleANPRCamerasForm isCreateForm />
        </SimpleForm>
    </Create>
);
