import React from "react";
import { Create, SimpleForm } from "react-admin";

import { SaveWithCancelToolbar } from "../../../components/toolbars/SaveWithCancelToolbar";
import { ExternalTerminalsCreateForm } from "./CreateForm";

export const ExternalTerminalsCreate = (props) => (
	<Create {...props}>
		<SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelToolbar />} redirect="list">
			<ExternalTerminalsCreateForm />
		</SimpleForm>
	</Create>
);
