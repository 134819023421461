import React from "react";
import { List, Datagrid, TextField, DateField } from "react-admin";
import { CurrencyRatesFilters } from "./Filters";
import { CustomEditButton } from "./CustomEditButton";
import { SelectEnumField } from "../../../components/fields/SelectEnumField";
import { Currencies } from "../../../entities/Currencies";
import { YesNoField } from "../../../components/fields/YesNoField";

const defaultSort = { field: 'is_actual', order: 'DESC' };

export const CurrenciesRatesList = (props) => {
    return(
        <List {...props}
        sort={defaultSort}
        filters={<CurrencyRatesFilters/>}
        bulkActionButtons={false}	
        filterDefaultValues={{is_actual: 'active'}}	
        exporter={false}>
            <Datagrid >
                <CustomEditButton />
                <TextField source="id" />
                <SelectEnumField source="currency_1" translate={false} enumName="currency" enumObj={Currencies}/>
                <SelectEnumField source="currency_2" translate={false} enumName="currency" enumObj={Currencies}/>
                <TextField source="rate" />
                <YesNoField source="is_actual" />
                <DateField source="date_from" />
            </Datagrid>
        </List>
    )
}