import React from "react";
import { Create, required, useTranslate } from 'react-admin';
import { DateInput } from "../../../components/inputs";
import { FormGroupGrid, GridForm, GridInput } from '../../../components/GridForm/';
import { SimpleFormWithWarningsDialog } from "../../../components/forms/SimpleFormWithWarningsDialog";
import { WarningPopover } from "../../../components/popovers/WarningPopover";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Checkbox } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';


export const isInvoicesSelector = (state) => {
    return state.admin.resources['invoices'] &&
           state.admin.resources['invoices'].data &&
           Object.keys(state.admin.resources['invoices'].data).length > 0;
}

const InvoiceForm = ({...props}) => {
    const is_record = useSelector(isInvoicesSelector);

    return(
        <GridForm {...props}>
            <FormGroupGrid>
                <GridInput sm={6} component={DateInput} source="date_from" validate={[!is_record && required()]} autoFocus />
                <GridInput sm={6} component={DateInput} source="date_to" />
            </FormGroupGrid>
        </GridForm>
    )
}


const LinkButton = ({resource, id}) => {
    return (
        <Link href={'/#/' + resource + '/'+ id + '/show'} target="_blank">
            {id}
        </Link>
    )
}


export const PopupContent = ({ data, invoicedOrderIds, setInvoicedOrderIds, ...props }) => {
    const translate = useTranslate()
    const handleToggle = (orderId) => {
        setInvoicedOrderIds((prev) =>
            prev.includes(orderId) ? prev.filter((id) => id !== orderId) : [...prev, orderId]
        );
    };

    return (
        <List>
            {data.map((item) => (
                <ListItem key={item.order_id}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={1}>
                            <ListItemText
                                secondary={<LinkButton id={item.order_id} resource="orders" />}
                            />
                        </Grid>

                        <Grid item xs={9}>
                            <ListItemText primary={item.warning_message} />
                        </Grid>

                        {item.is_invoiced &&
                            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body2" style={{ marginRight: 8, fontWeight: 'bold', fontStyle: 'italic' }}>
                               {translate("osfc.dialogs.invoices.order_exclude")}
                            </Typography>
                            <Checkbox
                                checked={invoicedOrderIds.includes(item.order_id)}
                                onChange={() => handleToggle(item.order_id)}
                            />
                        </Grid>
                        }
                    </Grid>
                </ListItem>
            ))}
        </List>
    );
};

export const InvoiceCreate = ({permissions, ...props}) => {
    const [open, setOpen] = React.useState(false);
    const [invoicedOrderIds, setInvoicedOrderIds] = React.useState([]);

    const handleClose = () => {
        setOpen(false);
        setInvoicedOrderIds([]);
    };
    
    return (
		<Create {...props}>
            <SimpleFormWithWarningsDialog 
                Form={InvoiceForm}
                Dialog={WarningPopover}
                PopupContent={PopupContent}
                redirect_url={'list'}
                title={'osfc.dialogs.invoices.title'}
                open={open}
                setOpen={setOpen}
                handleClose={handleClose}
                setInvoicedOrderIds={setInvoicedOrderIds}
                invoicedOrderIds={invoicedOrderIds}
                additionalData = {{invoiced_order_ids: invoicedOrderIds}}
                {...props}
            />
		</Create>
	);
}