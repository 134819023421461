import { CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';
import { useGetList, usePermissions, useRedirect } from 'react-admin';
import { useLocation } from 'react-router-dom';

import { OSFCUserRoles } from '../../../entities/UserRole';
import { OSFCEditPage } from './OSFCPage';

const useStyles = makeStyles(theme => ({
	loadingContainer: {
		position: 'relative', 
		top: '50%', 
		left: '50%',
	},
}));


export const OrdersMultipleChangesPage = (props) => {
	const { permissions } = usePermissions();
	const redirect = useRedirect();
	const location = useLocation();
	const classes = useStyles();

	const queryParams = new URLSearchParams(location.search);
	const selectedIdsParam = queryParams.get("selected_ids");
	const selectedIds = selectedIdsParam ? selectedIdsParam.split(',').map(Number) : [];
	
	if (selectedIds.length === 0) redirect('/');

	const splittedPathNames = location.pathname.split("/");
	const basePath = `/${splittedPathNames[1]}`;	

	let resource = splittedPathNames[1];
	let ordersFilter = { selected_ids: selectedIds };
	if (resource.indexOf("draft") !== -1) {
		resource = "orders";
		ordersFilter = { ...ordersFilter, draft_orders: true };
	}

	const { data, loading } = useGetList(
		resource, 
		{ page: 0, perPage: -1 },
		{ field: 'id', order: 'DESC' },
		ordersFilter,
	);

	if (loading) { 
		return (
			<div className={classes.loadingContainer}>
				<CircularProgress />
			</div>
		);
	}

	if (!permissions) return null;

	const isOSFCUser = permissions && OSFCUserRoles.has(permissions.role);
	if (isOSFCUser) {
		return <OSFCEditPage basePath={basePath} resource={resource} 
							 selectedIds={selectedIds} recordsByIds={data} 
							 permissions={permissions} />
	}

	return null;
};
