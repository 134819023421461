import React from "react";
import { ReferenceField, FunctionField } from "react-admin";

export const UserFullNameReferenceField = ({
	source = "created_by",
	reference = "users",
	link = false,
	sortable = true,
	...rest
}) => (
		<ReferenceField
			source={source}
			reference={reference}
			link={link}
			sortable={sortable}
			{...rest}
		>
			<FunctionField render={(record) => `${record.first_name} ${record.last_name}`} />
		</ReferenceField>
);
