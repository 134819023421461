import React, { createContext, useContext, useEffect } from 'react';

import { httpClient } from '../api/httpClient';


const OwnerCompanyDataContext = createContext();

const OwnerCompanyDataProvider = ({ children }) => {
    const [ownerCompanyData, setOwnerCompanyData] = React.useState({});

    useEffect(() => {
        refreshOwnerCompanyData();
    }, []);

    const refreshOwnerCompanyData = () => (
        httpClient(`owner-company-data/${1}`, {
            method: 'GET',
        }).then((res) => {
            setOwnerCompanyData(res.json);
        })
    );

    const contextValue = { 
        ownerCompanyData,
        refreshOwnerCompanyData,
    };

    return (
        <OwnerCompanyDataContext.Provider value={contextValue}>
            {children}
        </OwnerCompanyDataContext.Provider>
    );
};

const useOwnerCompanyDataContext = () => {
    const context = useContext(OwnerCompanyDataContext);
  
    if (!context) {
        throw new Error('useOwnerCompanyData must be used within an OwnerCompanyDataProvider');
    }
  
    return context;
};


export { OwnerCompanyDataProvider, useOwnerCompanyDataContext };