import React from 'react';
import {
	ReferenceField,
	Show,
	SimpleShowLayout,
	TextField,
} from 'react-admin';

import { ActionsWithBackButton } from '../../../components/actionbars/ActionsWithBackButton';
import { DateTimeFormatField } from '../../../components/fields/DateFormatField';
import { ExtraActionButtons } from './Actions';

export const BackupLogPricelistShow = (props ) => (
	<Show 
		{...props}
		actions={<ActionsWithBackButton hasExtraButtons ExtraActionButtons={ExtraActionButtons} />} 
	>
		<SimpleShowLayout>
			<TextField source='id' />
			<TextField source='version' />
			<DateTimeFormatField source='created_at' />

			<ReferenceField source='created_by' reference='users' link={false}>
				<TextField source='first_name' />
			</ReferenceField>

			<ReferenceField source='terminal_id' reference='terminal' link={false}>
				<TextField source='name' />
			</ReferenceField>

			<TextField source='file_path' />
		</SimpleShowLayout>
	</Show>
);
