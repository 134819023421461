import { Card, CardContent, IconButton, Typography, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';

const useDocumentItemStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	rootGray: {
		width: '100%',
		opacity: 0.5
	},
	media: {
		height: 240,
		backgroundPosition: 'top',
	},
	contentRoot: {


		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',

		'&:last-child': {
			paddingBottom: theme.spacing(1),
		}
	},
	title: {
		overflowWrap: 'anywhere',
		textAlign: 'left!important'
	},
}));

export const FileInfo = ({ file, setFile }) => {
    const classes = useDocumentItemStyles();
    const title = file.name;
    return(
        <Card className={classes.root} elevation={0} variant="outlined" >
			<CardContent className={classes.contentRoot}>
				<Typography className={classes.title} variant="body2" color="textSecondary" component="p">
					{title} 
                    <IconButton  
						style={{marginBottom:"2px"}}
						onClick={()=>setFile(null)}>
                        <DeleteIcon fontSize="inherit" style={{color:"#ab1f15"}}/>
                    </IconButton >
				</Typography>
			</CardContent>
		</Card>
    )
}