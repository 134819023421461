import CameraIcon from '@material-ui/icons/Camera';

import { VehicleANPRCamerasCreate } from './Create/Create';
import { VehicleANPRCamerasEdit } from './Edit/Edit';
import { VehicleANPRCamerasList } from './List/List';
import { VehicleANPRCamerasShow } from './Show/Show';


export {
    VehicleANPRCamerasList,
    VehicleANPRCamerasCreate,
    VehicleANPRCamerasEdit,
    VehicleANPRCamerasShow,
};

export const vehicleANPRCamerasResource = {
    list: VehicleANPRCamerasList,
    edit: VehicleANPRCamerasEdit,
    create: VehicleANPRCamerasCreate,
    show: VehicleANPRCamerasShow,
    icon: CameraIcon,
};
