
import React from "react";
import { useTranslate } from 'react-admin';

import Popover from '@material-ui/core/Popover';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
	popupContent: {
		margin: "25px",
	},
	dialogAction: {
		display: 'flex',
        justifyContent: 'space-between'
	},
});


export const WarningPopover = ({PopupContent, open, data, title, handleClose, handleIgnore, ...props}) => {
    const classes = useStyles();
    const translate = useTranslate()

	return (
        <Popover
            open={open}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            onClose={handleClose}
        >
            <div className={classes.popupContent}>
                <DialogTitle id="simple-dialog-title">{translate(title)}</DialogTitle>
                <DialogContent>
                    <PopupContent data={data} {...props}/>
                </DialogContent>   
                <DialogActions className={classes.dialogAction}>
                    <Button onClick={handleClose} variant="contained" color="primary">{translate("osfc.buttons.cancel")}</Button> 
                    <Button onClick={handleIgnore} variant="contained">{translate("osfc.buttons.ignore_warnings")}</Button> 
                </DialogActions> 
            </div>
    </Popover>
	);
}