import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import {
	BooleanInput,
	CheckboxGroupInput,
	ReferenceArrayInput,
	SelectInput,
	usePermissions,
	useTranslate,
} from 'react-admin';
import { FormSpy } from 'react-final-form';

import CheckboxGroupInputEx from "../../components/_extensions/react-admin/input/CheckboxGroupInputEx";
import { GridInput } from '../../components/GridForm/';
import { EmailInput } from '../../components/inputs/';
import BitwiseAutocompleteArrayInput from '../../components/inputs/BitwiseAutocompleteArrayInput';
import { AppManagerRoles, AppRoles, CustomerAndTransporterRoles, TransporterRoles, UserRole, getCompanyTypeByRole } from '../../entities/';
import { CompanyCustomerType } from '../../entities/';
import { OrderNormalStatuses, OrderStatusSkipNotifDriver, OrderStatusSkipNotifTrans } from '../../entities/OrderStatus';
import { SysNotifEventsChoices, SysNotifType, SysNotifTypeTranslationChoices } from '../../entities/SysNotifTypes';
import { isInArray } from '../../utils/general';
import { CardBlock } from './CardBlock';
import { UpdateForm } from './UpdateForm';

const useStyles = makeStyles(theme => ({
	root: {
		
		'&>div>label>.MuiCheckbox-root': {
			padding: '0px 9px',
		},

		'&>legend': {
			marginBottom: '9px',
		}
	}
}));


export const NotificationsBlock = () => {
	const classes = useStyles();
	const t = useTranslate();
	const { permissions } = usePermissions();

	if (!permissions) {
		return null;
	};

	const isDraftAccessRole = permissions && (
		[...AppManagerRoles, UserRole.customer_manager].indexOf(permissions.role) !== -1
	);

	const disabledValues = permissions.role === UserRole.customer_and_transporter_driver ? OrderStatusSkipNotifDriver : null;
	let userNotifOptions = SysNotifTypeTranslationChoices;
	if (permissions.role !== UserRole.customer_and_transporter_driver) {
		userNotifOptions = SysNotifTypeTranslationChoices.filter((option) => option.id !== SysNotifType.mobile_push);
	}

	const statusesFilter = !isDraftAccessRole ? { id: OrderNormalStatuses } : {};

	return (
		<UpdateForm
			id={permissions.id}
			resource="settings"
			render={({ body, buttons }) => (
				<CardBlock  buttons={buttons} children={body} />
			)}
			permissions={permissions}
		>
				<BitwiseAutocompleteArrayInput 
					source="chat_notification_type"
					label="pages.settings.fields.chat_notification_type"
					choices={userNotifOptions}
					fullWidth
				/>
				<BitwiseAutocompleteArrayInput 
					source="order_updates_statuses_notify_type"
					label="pages.settings.fields.order_updates_statuses_notify_type"
					choices={userNotifOptions}
					fullWidth
				/>
				<FormSpy subscription={{ values: true }}>
					{({ values }) => (
						<ReferenceArrayInput
							disabled={values.order_updates_statuses_notify_type === 0}
							source="order_updates_statuses"
							defaultValue={[]}
							reference="order-statuses"
							filter={{ with_permission: true, ...statusesFilter }}
							label="pages.settings.fields.order_updates_statuses"
						>
							<CheckboxGroupInputEx disabledValues={disabledValues} classes={classes} row={false} 
												fullWidth options={{ size: "small" }} />
						</ReferenceArrayInput>
					)}
				</FormSpy>

		</UpdateForm>

	);
}


export const CompanyNotificationsBlock = (props) => {
	const t = useTranslate();
	const { permissions } = usePermissions();
	const classes = useStyles();

	const isAdminAppManager = permissions && isInArray(AppRoles, permissions.role);
	const isDraftAccessRole = permissions && isInArray([...AppManagerRoles, UserRole.customer_manager], permissions.role);

	const currentCompanyId = permissions?.company_id || (props?.record && props.record.id);

	const notifEventChoices = SysNotifEventsChoices.map(el => ({id: el.id, name: t(`osfc.enums.sysnotifevent.${el.name}`)}));

	const companyType = props.record ? props.record.type : getCompanyTypeByRole(permissions?.role);
	const isCustomerComp = companyType === CompanyCustomerType.customer || companyType === CompanyCustomerType.customer_and_transporter;
	const isTransporterComp = companyType === CompanyCustomerType.transporter || companyType === CompanyCustomerType.customer_and_transporter;

	const statusesFilter = !isDraftAccessRole ? { id: OrderNormalStatuses } : undefined;

	return (
		currentCompanyId ? <UpdateForm
			id={currentCompanyId}
			resource="company-notifications"
			render={({ body, buttons }) => (
				<CardBlock Component={isAdminAppManager ? Box : Card} 
						   title={isAdminAppManager ? "" : t("pages.notifications.blocks.notifications")} 
						   buttons={buttons} children={body} />
			)}
		>
			 <Grid container spacing={2} justify="flex-start">
			 {isCustomerComp &&
				<Grid item sm={12} md={6}>
					{!isAdminAppManager && <EmailInput source="notification_email" label={t("pages.notifications.fields.notification_email")} disabled />}			
					<BooleanInput source="email_order_updates" defaultValue={false} label={t("pages.notifications.fields.email_order_updates")} />
					<FormSpy subscription={{ values: true }}>
					{({ values }) => (
						// client changed requirements: move deviation_notification_email to settings
						// <GridForm>
							<ReferenceArrayInput
								sm={12}
								disabled={!values.email_order_updates}
								source="email_order_updates_statuses"
								defaultValue={[]}
								reference="order-statuses"
								label={t("pages.notifications.fields.email_order_updates_statuses")}
								filter={statusesFilter}
								>
								<CheckboxGroupInput classes={classes} row={isAdminAppManager} fullWidth options={{ size: "small" }} />
							</ReferenceArrayInput>
							/*
							{(values.email_order_updates_statuses && values.email_order_updates_statuses.includes(OrderStatus.DEVIATION)) && (
							<GridInput xs={10} component={EmailInput} disabled={!values.email_order_updates} source="deviation_notification_email" />
							)}
							*/
						// </GridForm>
						)}
					</FormSpy>
				</Grid>}
				<Grid item sm={12} md={6}>
					{(isTransporterComp) && <>
						{!isAdminAppManager && <EmailInput source="transporter_notification_email" label={t("pages.notifications.fields.transporter_notification_email")} disabled/>}
						<GridInput sm={6} component={SelectInput} source="sys_notif_events" label={t("pages.notifications.fields.sys_notif_events")} choices={notifEventChoices} />
						<BooleanInput source="trans_email_order_updates" defaultValue={false} label={t("pages.notifications.fields.trans_email_order_updates")} />
						<FormSpy subscription={{ values: true }}>
							{({ values }) => (
								<ReferenceArrayInput
									disabled={!values.trans_email_order_updates}
									source="trans_email_order_updates_statuses"
									defaultValue={[]}
									reference="order-statuses"
									label={t("pages.notifications.fields.trans_email_order_updates_statuses")}
								>
									{/* <CheckboxGroupInput classes={classes} row={isAdminAppManager} fullWidth options={{ size: "small" }} /> */}
									<CheckboxGroupInputEx disabledValues={OrderStatusSkipNotifTrans} classes={classes} row={isAdminAppManager} fullWidth options={{ size: "small" }} />									
								</ReferenceArrayInput>
							)}
						</FormSpy>
					</>}					
				</Grid>				
			</Grid>
		</UpdateForm> : <></>
	);
}
