import React from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import {
	NumberInput, FunctionField, useMutation,
	CRUD_UPDATE_SUCCESS, FETCH_END, UPDATE, useNotify,
} from 'react-admin';
import { UserRole } from '../../entities';
import { EditForm } from './EditableField';
import { useEditableFieldStyles } from './EditableField';
import { Currencies } from '../../entities/Currencies';
import { getKeyFromEnumByValue } from '../_helpers/mappers';

const NumberEditableField = ({ editable, title="Double click to edit", EditInputComponent, style={}, checkIsDisabled, editLabel, ...props }) => {
    const InputComponent = EditInputComponent || NumberInput;
	const isEditable = editable && (checkIsDisabled ? !checkIsDisabled(props.record) : true)							
	return (
		<EditPopup
			editable={isEditable}
			source={props.source}
			resource={props.resource}
			record={props.record}
			basePath={props.basePath}
			updateSelectedIds={props.updateSelectedIds}
			updateSelectedFields={props.updateSelectedFields}
			edit={(
				<InputComponent autoFocus
						   label={editLabel ? editLabel : props.label}
						   source={props.source} 
						   reference={props.reference} 
						   validate={props.validate} 
						   min={props.min} 
						   format={props.format}
						   parse={props.parse}
						   choices={props.choices}						  
						/>
						   
			)}
			render={({ openPopup, className }) => (
				<FunctionField
					render={r => (
						<div 
							title={title} 
							className={className} 
							onDoubleClick={openPopup}
							style={(props.selectedFields[props.record.id] || {}) &&  
								Object.keys(props.selectedFields[props.record.id] || {}).indexOf(props.source) !== -1
								? { ...style, backgroundColor: 'orange' } 
								: style
							}
						>
							{r[props.source] != null ? (props.formatField ? props.formatField(r[props.source]) : r[props.source]) : '\u00A0'}
						</div>
					)}
				/>
			)}
		/>
	);
}


const EditPopup = ({ render, edit, editable, updateType, prepareValues = v => v, additionalParams = [], ...props }) => {

	const classes = useEditableFieldStyles();
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const handleOpen = (e) => {
		setAnchorEl(e.currentTarget);
	};

	const handleClose = (e) => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const notify = useNotify();

	const [mutate, { loading }] = useMutation({
		type: updateType || 'updatePartial',
		resource: props.resource,
	}, {
		withDeclarativeSideEffectsSupport: true,
		onSuccess: ({ data }) => {
			setAnchorEl(null);
			dispatch({
				type: CRUD_UPDATE_SUCCESS,
				payload: { data },
				meta: {
					resource: props.resource,
					notification: {
						body: 'ra.notification.updated',
						level: 'info',
						messageArgs: {
							smart_count: 1,
						}
					},
					fetchResponse: UPDATE,
					fetchStatus: FETCH_END
				},
			})
			props.onSuccess && props.onSuccess();
		},
		onFailure: (error) => {
			notify(error.message, "error");
		}
	});

	const handleSubmit = async values => {
		props.record[props.source] = values[props.source];
		props.updateSelectedIds(props.record.id);
		props.updateSelectedFields(props.record, props.source);
		setAnchorEl(false)
	};

	const isCustomerCompany = props.permissions && (
		props.permissions.role === UserRole.customer_manager || 
		props.permissions.role === UserRole.customer_employee
	);

	return (
		<React.Fragment>
			{((!isCustomerCompany) && open) && ReactDOM.createPortal((
				<EditForm anchorEl={anchorEl} handleSubmit={handleSubmit} handleClose={handleClose} loading={loading} edit={edit} {...props} />
			), document.body)}
			{editable === false ? render({}) : render({ openPopup: handleOpen, className: classes.root })}
		</React.Fragment>
	);
}
// import { NumberField } from 'react-admin';


export const currencyLocales = 'en-US';
// export const currencyOptions = { style: 'currency', currency: 'Nkr', maximumSignificantDigits: 3 };
export const currencyOptions = { maximumFrictionDigits: 3 };

export const toPrice = (value, mainCurrency) => {
	if (value === null || value === undefined) {
		return value;
	}

	const currency = getKeyFromEnumByValue(Currencies, mainCurrency);
	return `${currency} ` + value.toLocaleString(currencyLocales, currencyOptions);
}

export const PriceField = (props) => {
	if (props.record[props.source] === null || props.record[props.source] === undefined) {
		return null;
	}

	return (
		<span>
			{toPrice(props.record[props.source], props.mainCurrency)}
		</span>
	);

	// return (
	// 	<NumberField {...props} locales={currencyLocales} options={currencyOptions} />
	// );
};

export const PriceEditableField = (props) => {
	const formatPriceField = (value) => toPrice(value, props.mainCurrency);
	return (
		<NumberEditableField {...props} editable={props.editable} formatField={formatPriceField} />
	);
}

NumberEditableField.defaultProps = {
    addLabel: true,
};

PriceField.defaultProps = {
    addLabel: true,
};
