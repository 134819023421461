import React from 'react';

import { useTranslate, useLocale, useListContext, useDataProvider, Link } from 'react-admin';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';

import { LinkOrderType } from '../../entities/LinkOrderType';
import { OutDocumenStatus, OrderDocumentOutputType } from '../../entities';


const useStyles = makeStyles(theme => ({
	popover: {
	  pointerEvents: 'none',
	},
	popoverContent: {
	  pointerEvents: 'auto',
	},
	popoverHeader: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	}
}));

// const headers = ["move_order", "pallet_number", "status", "income_port"];
  
export const OutgoingDocItemsPopup = ({anchorEl, data, handlePopoverOpen, handlePopoverClose, isOpen}) => {
	const translate = useTranslate()
	const locale = useLocale()
	const classes = useStyles();
	const dataProvider = useDataProvider();
	
	// const [headersDic, setHeadersDic] = React.useState();
	const [title, setTitle] = React.useState();
	const [headers, setHeaders] = React.useState();
	const [headerRow, setHeaderRow] = React.useState();
	const [linkButtonText, setLinkButtonText] = React.useState();
	

	const { filterValues, setFilters } = useListContext();

	const { outDocType, outDocSrc, outDocItems, suggested_area, suggested_area_2, outgoing_order_doc_status, outDocStatuses, link_order_key, link_order_value,
			eta_date, eta_time, etd_date, etd_time } = data;

	const hundleFilterUpdate = () => {
		if (link_order_key && link_order_value) {
			if (outDocType == OrderDocumentOutputType.loading){
				if (link_order_key === "etd_truck_id" || link_order_key === "etd_truck"){
					setFilters({...filterValues, ...{[link_order_key]: link_order_value, ["etd_date"]: etd_date,  ["etd_time"]: etd_time}})	
				}else{
					setFilters({...filterValues, ...{[link_order_key]: link_order_value, ["etd_date"]: etd_date}})
				}
			}else if (outDocType == OrderDocumentOutputType.unloading){
				if (link_order_key === "eta_truck_id" || link_order_key === "eta_truck"){
					setFilters({...filterValues, ...{[link_order_key]: link_order_value, ["eta_date"]: eta_date,  ["eta_time"]: eta_time}})	
				}else{
					setFilters({...filterValues, ...{[link_order_key]: link_order_value, ["eta_date"]: eta_date}})
				}
			}
		}
		handlePopoverClose()
	}

	React.useEffect(() => {
		const handleScroll = (event) => {
			const popover = document.getElementById("mouse-over-popover");
			if (popover && popover.contains(event.target)) {
				return;
			}
			handlePopoverClose();
		};
	
		document.addEventListener("scroll", handleScroll, true);
		return () => {
			document.removeEventListener("scroll", handleScroll, true);
		};
	}, []);

	React.useEffect(() => {
		const _title = outDocType === OrderDocumentOutputType.loading ? translate("osfc.dialogs.load_doc_item.title") : translate("osfc.dialogs.unload_doc_item.title");
		setTitle(_title);

		const _headers = outDocType === OrderDocumentOutputType.loading ?
			["move_order", "unload_order_id", "pallet_number", "income_time", "income_port", "status"] :
			["load_order_id", "pallet_number", "load_time", "load_port", "status"];		
		setHeaders(_headers);

		const _headersDic = {};
		const _headerRows = [];
        for (const key of _headers) {
			_headersDic[key] = translate("osfc.out_doc_item.fields." + key);
			_headerRows.push(<TableCell key={key}>{_headersDic[key]}</TableCell>);
		}
		// setHeadersDic(_headersDic);
		setHeaderRow(<TableRow>{_headerRows}</TableRow>);
    }, [translate, locale, outDocType]);

	React.useEffect(() => {
		if (link_order_key === "etd_truck_id") {
			dataProvider.getOne("trucks", {
				id: link_order_value,
			}).then((data) => {
				setLinkButtonText(`${data?.data.name} / ${etd_date}`);
			});
		}

		setLinkButtonText(`${link_order_value} / ${etd_date}`);

		if (link_order_key === "etd_truck_id" || link_order_key === "etd_truck_id") {
			setLinkButtonText(`${linkButtonText} / ${etd_time}`);
		}
		
    }, [link_order_key, link_order_value]);

	const renderCell = (item, key) => {
		let text = item[key];
		if (key === 'status') {
			 text = outDocStatuses[text].name;
		}
		else if (key === 'load_order_id' || key === 'unload_order_id') {
			 if (text) text = <Link to={`orders/${text}/show`}>{text}</Link>;
			 else  text = null;
		}
		
		return <TableCell key={key}>{text}</TableCell>;
	};

	if (!headers) return null;

	return (
		<>
			<Popover
				id="mouse-over-popover"
				open={isOpen}
				anchorEl={anchorEl.current}
				anchorOrigin={{
			 		vertical: 'top',
			 		horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				className={classes.popover}
				classes={{
					paper: classes.popoverContent,
				}}
				disableScrollLock={true} 
				PaperProps={{onMouseEnter: handlePopoverOpen, onMouseLeave: handlePopoverClose}}
			>
				<DialogTitle>
					<div className={classes.popoverHeader}>
						{title}
						{/* <Button onClick={handlePopoverClose} color="secondary">
							{translate("resources.orders.export-dialog.button.close")}
						</Button> */}
						<Button href={outDocSrc} target="_blank" variant="outlined" color="secondary">DOC</Button>
					</div>
					<div className={classes.popoverHeader}>
						{linkButtonText && <Button onClick={hundleFilterUpdate} variant="contained" color="secondary">
							{linkButtonText}
						</Button>}	
						<div style={{fontWeight:"bold"}}>{suggested_area}/{suggested_area_2}</div>	
					</div>			
				</DialogTitle>
				{outgoing_order_doc_status > OutDocumenStatus.inhouse ?
				<DialogContent>
					<Table size="small">
						<TableHead>
							{headerRow}
						</TableHead>
						<TableBody>
							{outDocItems.map(item =>
								<TableRow >
									{headers.map(key => renderCell(item, key))}
								</TableRow>
							)}
						</TableBody>
						{!outDocItems.length && <TableFooter>
							<TableRow>
								<TableCell colSpan={headers.length} align="center">{translate("osfc.out_doc.empty")}</TableCell>
							</TableRow>
						</TableFooter>}
					</Table>
				</DialogContent> : null
				}
			</Popover>
		</>
	);
}