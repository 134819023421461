import React from "react";
import { Filter } from "react-admin";

import TextInputEx from "../../../components/_extensions/react-admin/input/TextInputEx";
import { YesNoInput } from "../../../components/inputs/YesNoInput";
import { SelectEnumInput } from "../../../components/inputs/SelectEnumInput";
import { CompanyCustomerType} from "../../../entities";

export const CompaniesFilter = (props) => (
	<Filter {...props}>
		<TextInputEx debounceTime={750} source="_query" alwaysOn />
		<TextInputEx debounceTime={750} source="full_name" />
		<TextInputEx debounceTime={750} source="short_name" />
		<TextInputEx debounceTime={750} source="email" />
		<TextInputEx debounceTime={750} source="phone" />
		<SelectEnumInput debounceTime={750} source="type" enumObj={CompanyCustomerType} />
		<TextInputEx debounceTime={750} source="guid" />
	</Filter>
);
