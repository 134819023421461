import React from 'react';
import { BooleanInput, TextInput, required } from 'react-admin';

import { FormGroupGrid, GridForm, GridInput } from '../../../components/GridForm';
import { EmailInput, PhoneInput } from '../../../components/inputs';
import { SelectEnumInput } from '../../../components/inputs/SelectEnumInput';
import { Currencies } from "../../../entities/Currencies";

const requiredValidator = [required()];

export const OwnerCompanyDataForm = (props) => (
    <GridForm {...props}>
        <FormGroupGrid>
            <GridInput 
                xs={12} 
                sm={4} 
                component={TextInput} 
                source="full_name" 
                validate={requiredValidator} 
            />

            <GridInput 
                xs={12} 
                sm={4} 
                component={TextInput} 
                source="short_name" 
                validate={requiredValidator} 
            />

            <GridInput 
                xs={12} 
                sm={4} 
                component={EmailInput} 
                source="email" 
                validate={requiredValidator} 
            />

            <GridInput 
                xs={12} 
                sm={4} 
                component={EmailInput} 
                source="support_email" 
                validate={requiredValidator} 
            />

            <GridInput 
                xs={12} 
                sm={4} 
                component={PhoneInput} 
                source="phone" 
                validate={requiredValidator} 
            />

            <GridInput 
                xs={12} 
                sm={4} 
                component={TextInput} 
                source="switchboard" 
                validate={requiredValidator} 
            />

            <GridInput 
                xs={12} 
                sm={4} 
                component={TextInput} 
                source="web_site" 
                validate={requiredValidator} 
            />

            <GridInput 
                xs={12} 
                sm={4} 
                component={TextInput} 
                source="logo_link" 
                validate={requiredValidator} 
            />

            <GridInput xs={12} sm={4} 
                component={SelectEnumInput} 
                enumObj={Currencies} 
                enumName="currency" source="main_currency" 
                translate={false}
                disabled
                validate={[required()]} 
            />

            <GridInput 
                xs={12} 
                sm={2} 
                component={BooleanInput} 
                source="is_allowed_sending_emails" 
                defaultValue={true}
            />

            <GridInput 
                xs={12} 
                sm={2} 
                component={BooleanInput} 
                source="is_allowed_sending_sms" 
                defaultValue={true}
            />

            <GridInput 
                xs={12} 
                sm={2} 
                component={BooleanInput} 
                disabled
                source="is_allowed_pricelist_algorithm" 
                defaultValue={true}
            />
        </FormGroupGrid>
    </GridForm>
);
