import { MapperType } from "./MapperType"

export const mapperKeys = {
	[MapperType.loading]: ['desc', 'move_order', 'packaging', 'pallet_number', 'units', 'weight', 'box_weight', 'optional'],
	[MapperType.unloading]: ['desc', 'move_order', 'move_order_2', 'production', 'pallet_number', 'units', 'weight', 'box_weight', 'optional'],
	[MapperType.loading_unloading_cc]: ['desc', 'move_order', 'move_order_2', 'packaging', 'production', 'pallet_number', 'units', 'weight', 'box_weight', 'optional'],
	[MapperType.order_import]: ['company_name', 'terminal_id',
								'reference', 'trip_number', 'eta_date', 'eta_time', 'etd_date', 'etd_time', 'eta_truck', 'eta_trailer',
								'etd_truck', 'etd_trailer', 'eta_driver', 'etd_driver', 'eta_driver_phone', 'etd_driver_phone',
								'pallets', 'kilos'],
};

export const optionalMapperKeys = {
	[MapperType.loading]: ['desc', 'packaging', 'optional'],
	[MapperType.unloading]: ['move_order_2', 'optional'],
	[MapperType.loading_unloading_cc]: ['desc', 'packaging', 'move_order_2', 'optional'],
	[MapperType.order_import]: ['reference', 'trip_number', 'eta_date', 'eta_time', 'etd_date', 'etd_time', 'eta_truck', 'eta_trailer',
								'etd_truck', 'etd_trailer', 'eta_driver', 'etd_driver', 'eta_driver_phone', 'etd_driver_phone',
								'pallets', 'kilos'
								],
	[MapperType.none]: []
};
