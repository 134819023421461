import React from "react";

import {useSelector} from 'react-redux'
import {usePermissions, TextInput, useTranslate} from 'react-admin';
import {useForm, useFormState} from 'react-final-form';

import {FormGroupGrid, GridInput} from '../../../components/GridForm';
import {NumberInputEx, TimeInput} from '../../../components/inputs';
import {OrderService, TransporterRoles, UserRole} from '../../../entities';

import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import MUIButton from '@material-ui/core/Button';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';

import {PortInput} from "../../../components/inputs/PortInput";
import TerminalDatesInput from "../../../components/inputs/TerminalDatesInput";
import { isInArray } from "utils/general";


const useDangerTextInputStyles = makeStyles({
	root: {
		borderTop: "1px solid rgba(255,0,0,0.3)",
		background: "rgba(255,0,0,0.1)",

		"&.Mui-focused": {
			background: "rgba(255,0,0,0.06)"
		}
	},
});

export const DangerTextInputForInternalNotes = (props) => {
	const classes = useDangerTextInputStyles();
	return <TextInput {...props} InputProps={{ classes: classes }} />
};

export const driversSelector = (state) => {
	return state.admin.resources['drivers'] && state.admin.resources['drivers'].data;
}

export const truckSelector = (state) => {
	return state.admin.resources['trucks'] && state.admin.resources['trucks'].data;
}

export const trailerSelector = (state) => {
	return state.admin.resources['trailers'] && state.admin.resources['trailers'].data;
}

export const DimensionsBlock = (props) => {
	const { values } = useFormState();
	// const isKilosHidden = values.service === OrderService.reloadCarCar || values.service === OrderService.reloadCarTerminalCar;
	const isDisabledForEditing = !!props.disabled;
	return (
		<FormGroupGrid xs={12} lg={6} {...props}>
			<GridInput xs={4} component={NumberInputEx} source="pallets" min={0} max={100} step={10} disabled={isDisabledForEditing} />
			<GridInput xs={4} component={NumberInputEx} source="boxes" min={0} max={100} step={5} disabled={isDisabledForEditing} />
			<GridInput xs={4} component={NumberInputEx} type="float" digitsCount={2} source="kilos" min={0} max={1000} step={100} disabled={isDisabledForEditing} />
		</FormGroupGrid>
	);
}

export const useDriverChange = (prefix) => {
	const form = useForm();

	const drivers = useSelector(driversSelector);
	const handleDriverChange = React.useCallback(e => {
		if (!e) {
			return;
		}
		const driver = drivers[e.target.value];
		if (driver) {			
			form.change(`${prefix}_driver_phone`, driver.phone);
			if (driver.truck_id){
				form.change(`${prefix}_truck`, null);
				form.change(`${prefix}_truck_id`, driver.truck_id);
			}
			if (driver.trailer_number) {
				form.change(`${prefix}_trailer_id`, null);
				form.change(`${prefix}_trailer`, driver.trailer_number);
			}
		}
	}, [prefix, drivers, form]);

	return handleDriverChange;
}

export const useXPartyChange = (prefix) => {
	const form = useForm();
	const { values } = useFormState();

	const truckData = useSelector(truckSelector);
	const trailerData = useSelector(trailerSelector);

	const handleXPartyChangeOnTruck = React.useCallback(e => {
		const truckKey = `${prefix}_truck`;
		const truckIdKey = `${prefix}_truck_id`;

		const truckId = values[truckIdKey];

		if (truckId && truckData[truckId]){
			form.change(truckKey, truckData[truckId]['name']);
			form.change(truckIdKey, null);
		}

	}, [prefix, truckData, form, values]);

	const handleXPartyChangeOnTrailer = React.useCallback(e => {
		const trailerKey = `${prefix}_trailer`;
		const trailerIdKey = `${prefix}_trailer_id`;

		const trailerId = values[trailerIdKey];

		if (trailerId && trailerData[trailerId]){
			form.change(trailerKey, trailerData[trailerId]['name']);
			form.change(trailerIdKey, null);
		}

	}, [prefix, trailerData, form, values]);

	const handleXPartyChangeOnDriver = React.useCallback(e => {
		form.change(`${prefix}_driver_id`, null);
        form.change(`${prefix}_driver`, null);
        form.change(`${prefix}_driver_phone`, null);

	}, [prefix, form]);

	const handleXPartyChangeOn4pl = React.useCallback(e => {
		form.change(`${prefix}_4th_party`, null);

	}, [prefix, form]);

	return [handleXPartyChangeOnTruck, handleXPartyChangeOnTrailer, handleXPartyChangeOnDriver, handleXPartyChangeOn4pl];
}

export const useFieldsSync = (field_from, field_to) => {
	const form = useForm();

	const handleSyncFields = React.useCallback(() => {
		form.change(field_to, form.getState().values[field_from]);
	}, [field_from, field_to, form]);

	return handleSyncFields;
}

export const useEtaEtdSync = (prefix) => {
	const handleFieldsSync = useFieldsSync(`${prefix}_date`, (prefix === 'eta' ? 'etd' : 'eta') + '_date');
	const { values } = useFormState();
	const isReloadCarCar = values.service === OrderService.reloadCarCar;
	return isReloadCarCar ? handleFieldsSync : undefined;
}

export const useInfo = () => {
	const { permissions } = usePermissions();
	const { values } = useFormState();
	const isIntoPlukkStorage = values.service === OrderService.intoPlukkStorage;
	const isReloadCarCar = values.service === OrderService.reloadCarCar;
	const isReloadCarTerminalCar = values.service === OrderService.reloadCarTerminalCar;
	const isCustomer = permissions && permissions.role === UserRole.employee;
	const isManager = permissions && permissions.role === UserRole.manager;
	const isTransporter = permissions && isInArray(TransporterRoles, permissions.role);

	const info = React.useMemo(() => ({
		isIntoPlukkStorage, isReloadCarCar, isReloadCarTerminalCar,
		isCustomer, isManager, isTransporter,
	}), [isIntoPlukkStorage, isReloadCarCar, isReloadCarTerminalCar, isManager, isCustomer, isTransporter]);

	return info;
}

export const etaEtdColors = {
	eta: "rgba(183,255,125,0.2)",
	etd: "rgba(255,140,140,0.1)",
}

export const etaEtdPorts = {
	eta: "port_in",
	etd: "port_out",
}

export const etaEtdTitles = {
	eta: "resources.orders.block-label.eta",
	etd: "resources.orders.block-label.etd",
}

export const ETAETDFormGroup = ({ children, prefix, ...props }) => {
	const translate = useTranslate()

	return (
		<FormGroupGrid
			{...props}
			style={{backgroundColor: etaEtdColors[prefix]}}
			title={translate(etaEtdTitles[prefix])}
			// actions={(
			// 	<React.Fragment>
			// 		<MUIButton variant="outlined" color="primary" size="small" onClick={(e) => setAnchorEl(e.target)} startIcon={<ArrivedIcon fontSize="small" />}>{prefix.toUpperCase()} Arrived</MUIButton>
			// 	</React.Fragment>
			// )}
		>
			{children}
		</FormGroupGrid>
	);
}

const ResponsiveIconButtonRaw = ({ title, icon, width, onClick, disabled }) => {
	if (isWidthUp('sm', width)) {
		return (
			<MUIButton onClick={onClick} style={{height: 'calc(100% - 15px)', minWidth: 'auto'}} size="small" color="primary" fullWidth variant="outlined" title={title} disabled={disabled}>
				{icon}
			</MUIButton>
		);
	}
	
	return (
		<Box mb={2}>
			<MUIButton onClick={onClick} size="large" color="primary" fullWidth variant="contained" startIcon={icon} disabled={disabled}>
				{title}
			</MUIButton>
		</Box>
	);
};

export const ResponsiveIconButton = withWidth()(ResponsiveIconButtonRaw);


export const ArrivedPopup = ({ render, prefix, portKey, timeKey, slotTimeKey, byTerminalFilter = {}, ...props }) => {
	const { values } = useFormState();
	const isDisabledForEditing = !!values[`${prefix}_3rd_party`];
	const [anchorEl, setAnchorEl] = React.useState(null);

	const onChangeProps = (field) => props?.onChangeProps ? props.onChangeProps(field) : {};

	return (
		<React.Fragment>
				<Popover
					open={!!anchorEl}
					onClose={() => setAnchorEl(null)}
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<form>
					<Box p={2}>
						<Typography variant="h6" component="span">{prefix.toUpperCase()} Arrived</Typography>
						<FormGroupGrid xs={12} {...props} direction="column">
							<GridInput xs={12}>
								<TerminalDatesInput component={TimeInput} source={timeKey} disabled={isDisabledForEditing} {...onChangeProps([timeKey])} />
							</GridInput>
							<GridInput xs={12}>
								<TerminalDatesInput autoFocus component={TimeInput} source={slotTimeKey} disabled={isDisabledForEditing} {...onChangeProps([slotTimeKey])} />
							</GridInput>
							<GridInput xs={12} component={PortInput} source={portKey} filter={byTerminalFilter} {...onChangeProps([portKey])} />
						</FormGroupGrid>
					</Box>
					</form>
				</Popover>
			{render({ openPopup: (e) => setAnchorEl(e.target) })}
		</React.Fragment>
	);
}
