import React from "react";
import { SimpleForm, useRedirect, useNotify } from 'react-admin';
import { SaveWithCancelToolbar } from "../toolbars/SaveWithCancelToolbar";

import { httpClient } from "../../api/httpClient";


export const SimpleFormWithWarningsDialog = ({Form, Dialog, PopupContent, redirect_url, title, open, setOpen, handleClose, ...props}) => {
    const [formValues, setFormValues] = React.useState({})
    const redirect = useRedirect()
    const notify = useNotify()
    const [warnings, setWarnings] = React.useState([]) 
    
    
    const handleSave = async (values) => {
        setFormValues(values)
        await httpClient(props.resource, {
			method: 'POST',
			body: JSON.stringify(values),
			headers: new Headers({
				'Content-Type': 'application/json'
			})})
			.then(response => {
                setWarnings(response.json.warnings)
                if (response.json.warnings && response.json.warnings.length > 0){
                    setOpen(true)
                } else{
                    redirect("list", props.resource)
                }
			})
			.catch(error => {
				notify(error.message, "error")
			});
    };

    const handleIgnore = async () => {
        await httpClient(props.resource, {
			method: 'POST',
			body: JSON.stringify({...formValues, ignore_warnings: true, ...props.additionalData}),
			headers: new Headers({
				'Content-Type': 'application/json'
			})})
			.then(response => {
                redirect("list", props.resource)
			})
			.catch(error => {
				notify(error.message, "error")
			});
    };
	return (
        <div>
            <SimpleForm save={handleSave} submitOnEnter={false} toolbar={<SaveWithCancelToolbar />} redirect={redirect_url}>
                <Form {...props}/>
            </SimpleForm>
            <Dialog PopupContent={PopupContent} open={open} data={warnings} title={title} handleClose={handleClose} handleIgnore={handleIgnore} {...props}/>
    </div>
	);
}