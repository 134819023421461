import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import DescriptionIcon from "@material-ui/icons/Description";
import ArrivedIcon from "@material-ui/icons/LocalShipping";
import SubjectIcon from "@material-ui/icons/Subject";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { differenceInMinutes } from "date-fns";
import { format as dateFnsFormat } from "date-fns";
import React, { memo, useCallback, useEffect, useRef } from "react";
import {
	Button,
	EditButton,
	FunctionField,
	List,
	Loading,
	Pagination,
	SelectField,
	SelectInput,
	ShowButton,
	TextField,
	useGetList,
	useListContext,
	usePermissions,
	useRecordContext,
	useRefresh,
	useTranslate,
} from "react-admin";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { TIME_FORMAT } from "../../../components/_helpers/dateTime";
import { Datagrid } from "../../../components/CustomizableDataGrid/";
import { OutgoingDocItemsPopup } from "../../../components/dialogs/OutgoingDocItemsPopup";
import { DraggableContextsWrapper } from "../../../components/DraggableDatagrid";
import ChatMessageCountField from "../../../components/fields/ChatMessageCountField";
import { CssRowFieldCellWrapper } from "../../../components/fields/CssFieldCellWrapper";
import CustomerField from "../../../components/fields/CustomerField";
import { DateFormatField } from "../../../components/fields/DateFormatField";
import { TextEditableField } from "../../../components/fields/EditableField";
import {
	ButtonEditableCustomField,
	ReferenceEditableField,
} from "../../../components/fields/EditableField";
import { BooleanField, EqualField } from "../../../components/fields/EqualField";
import { FakeReferenceTextField } from '../../../components/fields/FakeReferenceTextField';
import { EditButtonField, ShowButtonField } from "../../../components/fields/LinkField";
import { LongTextField } from "../../../components/fields/LongTextField";
import { NoneOrValueField } from "../../../components/fields/NoOrValueField";
import { ObjectChipField } from "../../../components/fields/ObjectChipField";
import { toPrice } from "../../../components/fields/PriceField";
import { PriorityField } from "../../../components/fields/PriorityField";
import { SelectEnumField } from "../../../components/fields/SelectEnumField";
import { TooltipTextField } from "../../../components/fields/TooltipTextField";
import TransDriverField from "../../../components/fields/TransDriverField";
import { YesNoField } from "../../../components/fields/YesNoField";
import { GridInput } from "../../../components/GridForm";
import { DateInput, TimeInput, YesNoInput } from "../../../components/inputs";
import { PortInput } from "../../../components/inputs/PortInput";
import { SelectDisabledInput } from "../../../components/inputs/SelectUppercaseInput";
import TerminalDatesInput from "../../../components/inputs/TerminalDatesInput";
import { ScrollingWrapper } from "../../../components/ScrollingWrapper";
import { SearchResultDocumentsRowExpand } from "../../../components/SearchResultRowExpand";
import GridUserDataProvider, {
	useGridUserDataContext,
} from "../../../contexts/GridUserDataProvider";
import { useOwnerCompanyDataContext } from "../../../contexts/OwnerCompanyDataProvider";
import { useTerminalsContext } from "../../../contexts/TerminalsProvider";
import {
	AppManagerRoles,
	CoolerType,
	OSFCUserRoles,
	OrderService,
	OrderServices,
	OrderStatus,
	OrderStatusCustomerEdit,
	OrderStatusNotactive,
	UserRole,
	UserRoleByCompanyType,
	useCommodityChoices
} from "../../../entities/";
import { LabelSource } from "../../../entities/LabelSource";
import { LinkOrderType } from "../../../entities/LinkOrderType";
import { OutDocumenStatus } from "../../../entities/OutDocumentStatus";
import { CompPerm } from "../../../entities/Permission";
import { checkIsRankDisabled } from "../../../utils/checker/order";
import { getNowByTerminal, str2datetime } from "../../../utils/datetime";
import { isInArray } from "../../../utils/general";
import { rankChoicesGetter } from "../../../utils/getter";
import { useAsyncColumnsStorage } from "../../../utils/hooks/useAsyncColumnsStorage";
import { useExpandDefaultForAll } from "../../../utils/hooks/useExpandDefaultForAll";
import { useIsSearchInDocs } from "../../../utils/hooks/useIsSearchInDocs";
import { validateArea } from "../../../utils/validation/order";
import { getStatusAllowedIds } from "../forms/FormManager";
import { OrdersActions } from "./Actions";
import CustomBulkActionButtons from "./CustomBulkActionButtons";
import { HandleOnDragEndWrapper } from "./HandleOnDragEndWrapper";
import { OrdersFilter, filterDefaultValues } from "./ListFilters";
import { TotalPriceLabel } from "./TotalPriceLabel";

const defaultSort = { field: "id", order: "DESC" };

const BG_COLOR_WHITE = "#ffffff";
const BG_COLOR_GREEN = "#b7ff7d";
const BG_COLOR_DARKGREEN = "#008000";
const BG_COLOR_RED = "#ff8c8c";
const BG_COLOR_YELLOW = "#FFE600";
const BG_COLOR_DARKRED = "##C9211E";

const useStyles = makeStyles({
	list: {
		noResults: {
			backgroundColor: "red",
			padding: 100,
		},
		actions: {
			backgroundColor: "blue",
		},
	},
	expandIcon: {
		padding: "2px",
	},
});

const useCellStyles = makeStyles({
	cellGreen: {
		backgroundColor: BG_COLOR_GREEN, // green
	},
	cellRed: {
		backgroundColor: BG_COLOR_RED, // red
	},
	cellWhite: {
		backgroundColor: BG_COLOR_WHITE, // white
	},
	noPadding: {
		padding: "0",

		"&>span>button": {
			borderTopRightRadius: 0,
		},
	},
	noPaddingButton: {
		padding: "0",

		"&>span>button": {
			backgroundColor: BG_COLOR_WHITE,
			borderRadius: 3,
			border: "1px solid white",
			// outline: "1px solid white",
			minWidth: "auto",
		},
	},
	noPaddingButtonStart: {
		"&>span>button": {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
			borderRight: "none",
		},
	},
	noPaddingButtonEnd: {
		"&>span>button": {
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0,
			borderLeft: "none",
		},
	},
	cellEtaTruck: {
		".order-cc-late &>span>button": {
			// backgroundColor: BG_COLOR_RED,
		},
		".order-car-detected-eta &>span>button": {
			// borderColor: BG_COLOR_DARKGREEN,
			backgroundColor: BG_COLOR_GREEN,
		},
	},
	cellEtdTruck: {
		".order-cc-late &>span>button": {
			// backgroundColor: BG_COLOR_RED,
		},
		".order-car-detected-etd &>span>button": {
			// borderColor: BG_COLOR_DARKGREEN,
			backgroundColor: BG_COLOR_GREEN,
		},
	},
	cellTime: {
		"&>span": {
			padding: "0 2px",
			border: "1px solid transparent",
			// outline: "1px solid transparent",
		},
		".order-cc-late &>span": {
			// backgroundColor: BG_COLOR_RED,
			cursor: "pointer",
		},
		".order-cc-late &>span .tt-icon": {
			padding: "0 6px",
			backgroundColor: BG_COLOR_YELLOW,
			color: BG_COLOR_DARKRED,
			fontWeight: "bold",
		},
	},
	cellEtaTime: {
		".order-car-detected-eta &>span": {
			// borderColor: BG_COLOR_DARKGREEN,
			outlineColor: "white",
			backgroundColor: BG_COLOR_GREEN + "!important",
		},
		".updated-eta-by-customer &>span": {
			// cursor: 'pointer',
			outlineColor: "white",
			backgroundColor: BG_COLOR_RED,
		},
		// '.updated-eta-by-customer &>span .tt-icon':{
		// 	padding: '0 6px',
		// 	backgroundColor: BG_COLOR_YELLOW,
		// 	color: BG_COLOR_DARKRED,
		// 	fontWeight: 'bold'
		// }
	},
	cellEtdTime: {
		".order-car-detected-etd &>span": {
			// borderColor: BG_COLOR_DARKGREEN,
			outlineColor: "white",
			backgroundColor: BG_COLOR_GREEN + "!important",
		},
		".updated-etd-by-customer &>span": {
			// cursor: 'pointer',
			outlineColor: "white",
			backgroundColor: BG_COLOR_RED,
		},
		// '.updated-etd-by-customer &>span .tt-icon':{
		// 	padding: '0 6px',
		// 	backgroundColor: BG_COLOR_YELLOW,
		// 	color: BG_COLOR_DARKRED,
		// 	fontWeight: 'bold'
		// }
	},
	// cellEtaTime: {
	// 	'.order-cc-late &>span': {
	// 		backgroundColor: BG_COLOR_RED,
	// 		color: BG_COLOR_GREEN,
	// 	},
	// },
	// cellEtdTime: {
	// 	'.order-cc-late &>span': {
	// 		backgroundColor: BG_COLOR_GREEN,
	// 		color: BG_COLOR_RED
	// 	},
	// },
});

const Label = ({ title, label }) => {
	return (
		<Tooltip title={title} placement="bottom">
			<div>{label}</div>
		</Tooltip>
	);
};

const OrdersPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const prepareEtaArrived = (v) => ({
	port_in: v.port_in,
	eta_time: v.eta_time,
	eta_slot_time: v.eta_slot_time,
	is_eta_arrived_manual: true,
	status: OrderStatus.ONE_IN_TERMINAL,
});

const prepareEtdArrived = (v) => ({
	port_out: v.port_out,
	etd_time: v.etd_time,
	etd_slot_time: v.etd_slot_time,
	is_etd_arrived_manual: true,
	status: OrderStatus.ONE_IN_TERMINAL,
});

const getFilterDataArray = (rec) => {
	switch (rec["link_order_type"]) {
		case LinkOrderType.trip_number:
			return ["trip_number", rec["trip_number"]];
		case LinkOrderType.etd_truck_id:
			return ["etd_truck_id", rec["etd_truck_id"]];
		case LinkOrderType.etd_truck:
			return ["etd_truck", rec["etd_truck"]];
		default:
			return [null, null];
	}
};

const TruckEditableConditionalField = ({ ...props }) => {
	const source = props.source;
	const record = props.record;

	if (
		record.service === OrderService.intoPlukkStorage &&
		source === "eta" &&
		record.eta_date == null
	) {
		return null;
	}
	if (
		record.service === OrderService.intoPlukkStorage &&
		source === "etd" &&
		record.etd_date == null
	) {
		return null;
	}
	return <ButtonEditableCustomField {...props} />;
};

const DocumentsButton = (props) => {
	const [isOpenedPopover, setIsOpenedPopover] = React.useState(false);
	const popoverAnchor = React.useRef(null);

	const openPopover = () => {
		setIsOpenedPopover(true);
	};

	const closePopover = () => {
		setIsOpenedPopover(false);
	};

	const [link_order_key, link_order_value] = getFilterDataArray(props.record);

	const data = {
		outDocType: props.record["outgoing_doc_type"],
		// outDocSrc: props.record["outgoing_doc_src"],
		outDocSrc: props.record["outgoing_doc_preview_src"],
		outDocItems: props.record["outgoing_doc_items_with_max_status"],
		suggested_area: props.record["suggested_area"],
		suggested_area_2: props.record["suggested_area_2"],
		outgoing_order_doc_status: props.record["outgoing_order_doc_status"],
		outDocStatuses: props.outDocStatuses,
		link_order_key: link_order_key,
		link_order_value: link_order_value,
		eta_date: props.record["eta_date"],
		eta_time: props.record["eta_time"],
		etd_date: props.record["etd_date"],
		etd_time: props.record["etd_time"],
	};

	return (
		<>
			<div ref={popoverAnchor} onMouseEnter={openPopover} onMouseLeave={closePopover}>
				<ShowButton
					to={`/${props.resource}/${props.record.id}/show/${props.pageIndex}`}
					icon={props.record.has_documents ? <DescriptionIcon /> : <SubjectIcon />}
					{...props}
				/>
			</div>
			{props.record.outgoing_order_doc_status > OutDocumenStatus.none ? (
				<OutgoingDocItemsPopup
					anchorEl={popoverAnchor}
					handlePopoverOpen={openPopover}
					handlePopoverClose={closePopover}
					isOpen={isOpenedPopover}
					data={data}
				/>
			) : null}
		</>
	);
};

const ActionButtonsField = ({
	isCompanyActive,
	isSearchInDocs,
	resource,
	basePath,
	record,
	outDocStatuses,
	comp_perm,
}) => {
	const outgoing_doc_style =
		outDocStatuses && record && record.has_out_documents
			? {
					backgroundColor:
						outDocStatuses[record.outgoing_order_doc_status] &&
						outDocStatuses[record.outgoing_order_doc_status].color,
					border: "1px solid #000000",
			  }
			: {};

	return (
		<React.Fragment>
			{record.has_documents || record.has_out_documents ? (
				<DocumentsButton
					label=""
					resource={resource}
					basePath={basePath}
					record={record}
					pageIndex={comp_perm & CompPerm.is_output_document && record.has_out_documents ? 4 : 3}
					style={{ minWidth: "auto", ...outgoing_doc_style }}
					outDocStatuses={outDocStatuses}
				/>
			) : (
				<EditButton
					label=""
					disabled={!isCompanyActive}
					style={{ minWidth: "auto", ...outgoing_doc_style }}
					resource={resource}
					basePath={basePath}
					record={record}
				/>
			)}
			{isSearchInDocs && (
				<Link to={`/${resource}/${record.id}/show`} target={"_blank"}>
					<Button style={{ minWidth: "auto", ...outgoing_doc_style }}>
						<VisibilityIcon />
					</Button>
				</Link>
			)}
		</React.Fragment>
	);
};

const GridFieldWithSelectionOnDoubleClick = ({ isEqual = false, component, ...props }) => {
	const listContext = useListContext();
	const refresh = useRefresh();
	const record = useRecordContext(props);

	const handleOnDoubleClick = useCallback(() => {
		const newSelectedIds = Object.values(listContext.data)
			.filter((row) => row[props.source] === record[props.source])
			.map((row) => row.id);

		if (
			newSelectedIds.length !== 0 &&
			!newSelectedIds.every((id) => listContext.selectedIds.includes(id))
		) {
			listContext.onSelect(newSelectedIds);
			refresh();
		}
	}, [listContext.data, listContext.selectedIds, props.source]);

	const sharedProps = {
		style: { cursor: "pointer" },
		title: "Double click to select same records",
		onDoubleClick: handleOnDoubleClick,
	};

	return React.createElement(component, { ...props, ...sharedProps });
};

const useDraggableMode = () => {
	const { permissions } = usePermissions();
	const listParamsData = useSelector((state) =>
		state.admin.resources["orders"] ? state.admin.resources["orders"].list.params : {}
	);

	return (
		permissions &&
		(permissions.role === UserRole.osfc_manager ||
			permissions.role === UserRole.admin ||
			permissions.role === UserRole.app_manager) &&
		listParamsData.sort === "rank" &&
		listParamsData.order === "DESC"
	);
};

export const RankField = ({ rankPriorities, ...props }) => {
	let title = "";
	let rankStyle = {};
	if (
		rankPriorities &&
		props.record &&
		Number.isInteger(props.record.rank) &&
		Number.isInteger(props.record.rank_auto)
	) {
		title = "Rank auto group - " + props.record.rank_auto;
		rankStyle = {
			backgroundColor:
				rankPriorities[props.record.rank_auto] && rankPriorities[props.record.rank_auto].color,
			textAlign: "center",
			border: "1px solid #000000",
			borderRadius: "100%",
		};
	}
	// const choices = props.record.rank ?  [{id: false, name: "NO (" +  props.record.rank + ")"}, {id: true, name: "YES (M)"}] :
	// 									 [{id: false, name: "NO ()"}, {id: true, name: "YES (M)"}];
	const rankChoices = rankChoicesGetter(props.record);

	return <TextEditableField title={title} style={rankStyle} choices={rankChoices} {...props} />;
};

// const checkIfRankHidden = (filterValues) => filterValues &&
// 		filterValues['state'] === 'active' &&
// 		filterValues['terminal_id'] && Number.isInteger(filterValues['terminal_id'][0]) &&
// 		filterValues['eta_date|etd_date'] && filterValues['eta_date|etd_date'][0] === 'today'
// 	? {} : {rank:-1};


// Info: for better performance it would be better to calculate at backend side
const isOrderCCLate = (rec) => {
	if (
		rec["service"] === OrderService.reloadCarCar &&
		rec["eta_date"] &&
		rec["eta_time"] &&
		rec["etd_date"] &&
		rec["etd_time"]
	) {
		const diffMin = differenceInMinutes(
			str2datetime(rec["eta_date"], rec["eta_time"]),
			str2datetime(rec["etd_date"], rec["etd_time"])
		);
		return diffMin > 15;
	}
	return false;
};

const _OrdersList = ({ permissions, isDraft, ...props }) => {
	const { filters: currentFilters, sort: currentSort } = useGridUserDataContext();
	const { ownerCompanyData } = useOwnerCompanyDataContext();
	const classes = useStyles();
	const cellClasses = useCellStyles();
	const aboveListRef = useRef(null);
	const translate = useTranslate();
	const t = (field) => translate(`resources.orders.fields.${field}`);
	const columnsAsyncStorage = useAsyncColumnsStorage(isDraft ? "draft-orders-columns" : "orders-columns");
	const isSearchInDocs = useIsSearchInDocs(currentFilters, props.resource);
	const commodityChoices = useCommodityChoices();
	const refresh = useRefresh();
	const [isBulkOpen, setIsBulkOpen] = React.useState(false);
	// const isDraggableMode = useDraggableMode();
	const isDraggableMode = false;
	const { data: terminals } = useTerminalsContext();

	const { ids: _, data: statuses } = useGetList("order-statuses");

	const { ids: __, data: outDocStatuses } = useGetList(
		"outgoing-doc-statuses",
		{ page: 0, perPage: -1 },
		{ field: "id", order: "ASC" }
	);

	const { ids: ___, data: rankPriorities } = useGetList(
		"rank-priorities",
		{ page: 0, perPage: -1 },
		{ field: "id", order: "ASC" }
	);

	useEffect(() => {
		if (permissions?.time_refresh_order) {
			const intervalId = setInterval(refresh, permissions.time_refresh_order * 1000);
			return () => clearInterval(intervalId);
		}
	}, [permissions]);

	useExpandDefaultForAll(props.resource);

	if (!permissions || currentFilters === undefined || columnsAsyncStorage.columns === undefined) {
		return <Loading loadingSecondary="" />;
	}

	const orderRowStyle = (record, index) => {
		return {
			backgroundColor: statuses[record.status] && statuses[record.status].color,
		};
	};

	const orderRowClass = (rec) => {
		const css = [];
		if (isOrderCCLate(rec)) {
			css.push("order-cc-late");
		}

		if (!OrderStatusNotactive.includes(rec.status)) {
			if (rec.service === OrderService.reloadCarTerminalCar) {
				if (
					rec.is_eta_arrived &&
					[
						OrderStatus.DRAFT,
						OrderStatus.BOOKED,
						OrderStatus.ONE_IN_TERMINAL,
						OrderStatus.LOADING_READY,
					].includes(rec.status)
				) {
					css.push("order-car-detected-eta");
				}
			} else {
				if (rec.is_eta_arrived) {
					css.push("order-car-detected-eta");
				}
			}

			if (rec.is_etd_arrived) {
				css.push("order-car-detected-etd");
			}
		}

		if (rec.is_eta_updated_by_customer) {
			css.push("updated-eta-by-customer");
		}
		if (rec.is_etd_updated_by_customer) {
			css.push("updated-etd-by-customer");
		}

		if (isCustomerAndTransporterCompany) {
			if (userCompanyId && userCompanyId !== rec["company_id"]) {
				if (userCompanyId === rec["eta_3rd_party"]) {
					css.push("order-as-trans-eta");
				}
				if (userCompanyId === rec["etd_3rd_party"]) {
					css.push("order-as-trans-etd");
				}
			}
		}
		return css;
	};

	const userCompanyId = permissions && permissions.company_id;
	const isCompanyActive = !permissions["company_is_blocked"];
	const isAppManager = permissions && isInArray(AppManagerRoles, permissions.role);
	const isOSFCUser = permissions && OSFCUserRoles.has(permissions.role);

	const isCustomerCompany =
		permissions &&
		(permissions.role === UserRole.customer_manager ||
			permissions.role === UserRole.customer_employee);
	const isCustomerAndTransporterCompany =
		permissions &&
		(permissions.role === UserRole.customer_and_transporter_manager ||
			permissions.role === UserRole.customer_and_transporter_employee ||
			permissions.role === UserRole.customer_and_transporter_driver);

	const isDriver =
		permissions &&
		(permissions.role === UserRole.customer_and_transporter_driver ||
			permissions.role === UserRole.transporter_driver);

	const isNot3rdPartyRole =
		UserRoleByCompanyType.transporter_all_roles.indexOf(permissions.role) === -1;
	const canMultipleChanges = isOSFCUser;

	const exposedColumns = ["rank"];

	const msgMsgNotChangeTruckArrived = translate(
		"resources.orders.messages.warn.not-change-truck-arrived"
	);
	const msgSystemWillSetNow = translate("resources.orders.messages.warn.system-will-set-now");

	const toPriceFormatter = (value) => toPrice(value, ownerCompanyData.main_currency);

	return (
		<div ref={aboveListRef}>
			<DraggableContextsWrapper isDraggableDefault={isDraggableMode}>
				<List
					{...props}
					perPage={50}
					empty={false}
					pagination={<OrdersPagination />}
					classes={classes.list}
					exporter={false}
					bulkActionButtons={canMultipleChanges && isBulkOpen && <CustomBulkActionButtons isDraft={isDraft} />}
					filters={
						<OrdersFilter
							hasCompanyFilter={isOSFCUser}
							hasOrderTypeFilter={isCustomerAndTransporterCompany}
							isOnlyDates={isDriver}
							isSearchInDocs={isSearchInDocs}
							isDraft={isDraft}
						/>
					}
					filterDefaultValues={currentFilters}
					sort={currentSort}
					hasCreate={props.hasCreate && isCompanyActive}
					actions={<OrdersActions isDraft={isDraft} />}
				>
					<HandleOnDragEndWrapper>
						<ScrollingWrapper>
							<Datagrid
								rowStyle={orderRowStyle}
								classes={{ expandIcon: classes.expandIcon }}
								storage={columnsAsyncStorage}
								isRowExpandable={() => isSearchInDocs}
								expand={isSearchInDocs ? <SearchResultDocumentsRowExpand /> : false}
								expandIconCell={{ padding: "2px" }}
								canMultipleChanges={canMultipleChanges}
								isBulkOpen={isBulkOpen}
								setIsBulkOpen={setIsBulkOpen}
								exposedColumns={exposedColumns}
								useDragAndDropComponents={isDraggableMode}
								// getHiddenColumns={checkIfRankHidden}
							>
								<CssRowFieldCellWrapper cssFn={orderRowClass} />
								<ActionButtonsField
									isCompanyActive={isCompanyActive}
									isSearchInDocs={isSearchInDocs}
									outDocStatuses={outDocStatuses}
									comp_perm={permissions.comp_perm}
								/>

								<ChatMessageCountField source="message_count" label="" sortable={false} />

								{(isOSFCUser || isCustomerAndTransporterCompany) && (
									<CustomerField
										label={t("company_id")}
										source="company_id"
										formatLabel={(record) => record["customer_name"]}
									/>
								)}
								<PriorityField
									source="priority"
									label={<Label title={t("priority")} label="P" />}
									sortable={false}
								/>
								{/* {isAppManager ? <RankField editable={isAppManager}
										 		source={"rank"}
										 		editLabel={translate("resources.orders.fields.rank.edit.label")}
												EditComponent={YesNoInput}
												checkIsDisabled={checkIsRankDisabled}
												rankPriorities={rankPriorities}
												// validate={validateRankField}
												format={val=> val === 'M' ? true : val===null || val===undefined ? false : typeof val === 'string' ? false : true }
												parse={val=> val === true ? 0 : null}
												{...props}
										/>
										: null} */}
								{isAppManager ? (
									<RankField
										editable={isAppManager}
										source="rank"
										editLabel={translate("resources.orders.fields.rank.edit.label")}
										EditComponent={YesNoInput}
										checkIsDisabled={checkIsRankDisabled}
										rankPriorities={rankPriorities}
										formatField={(val) => (val ? val : val === 0 ? "M" : null)}
									/>
								) : null}
								<FunctionField
									source="reference"
									label={t("reference")}
									render={(record) =>
										record.reference ? (
											<EditButtonField
												source="reference"
												record={record}
												disabled={!isCompanyActive}
											/>
										) : null
									}
								/>
								{!isDraft ?
								<ReferenceEditableField
									optimized={true}
									editable={
										isAppManager
											? true
											: isCustomerCompany
											? (rec) => OrderStatusCustomerEdit.indexOf(rec["status"]) !== -1
											: isCustomerAndTransporterCompany && !isDriver
											? (rec) =>
													OrderStatusCustomerEdit.indexOf(rec["status"]) !== -1 &&
													userCompanyId === rec["company_id"]
											: false
									}
									permissions={permissions}
									source="status"
									reference="order-statuses"
									additionalParams={["set_order_completed"]}
									editField={(rec) => {
										if (isOSFCUser) {
											const statusAllowedIds = getStatusAllowedIds(rec, permissions.role);
											return (
												<SelectDisabledInput allowedIds={statusAllowedIds} optionText="name" />
											);
										} else {
											return (
												<SelectDisabledInput
													allowedIds={OrderStatusCustomerEdit.filter(
														(s) => s !== OrderStatus.DRAFT
													)}
													optionText="name"
												/>
											);
										}
									}}
								>
									<FakeReferenceTextField source="status_id" replaceSource="status_name" />
								</ReferenceEditableField> : null}
								<ObjectChipField
									label={t("service")}
									source="service"
									object={OrderServices}
									titleKey="short_name"
									tooltipKey="full_name"
								/>
								<FakeReferenceTextField
									source="terminal_id"
									replaceSource="terminal_name"
									label={t("terminal")}
								/>
								<TextField source="et_date_union" label={t("et_date_union")} />

								<DateFormatField source="eta_date" headerClassName={cellClasses.cellGreen} label={t("eta_date")} />

								<TooltipTextField
									label={t("eta_time")}
									source="eta_time"
									headerClassName={cellClasses.cellGreen}
									cellClassName={cellClasses.cellTime + " " + cellClasses.cellEtaTime}
									ttFn={(rec) =>
										isOrderCCLate(rec)
											? translate("resources.orders.messages.is-order-cc-late")
											: false
									}
								/>
								{/* <TextField source="eta_time" headerClassName={cellClasses.cellGreen} cellClassName={cellClasses.cellEtaTime} /> */}

								<TextField source="eta_slot_time" headerClassName={cellClasses.cellGreen} label={t("eta_slot_time")} />

								{isOSFCUser ? (
									<ReferenceEditableField
										label={t("port_in")}
										source="port_in"
										reference="ports"
										optimized={true}
										headerClassName={cellClasses.cellGreen}
										permissions={permissions}
										editField={<SelectInput optionText="name" emptyValue="" />}
										filter={(rec) => ({ terminal_id: [rec.terminal_id] })}
										allowEmpty={true}
										format={(value) => (value === 0 ? "" : value)}
										parse={(value) => (value === "" ? 0 : value)}
									>
										<FakeReferenceTextField source="port_in" replaceSource="port_in_name" />
									</ReferenceEditableField>
								) : (
									<FakeReferenceTextField
										label={t("port_in")}
										source="port_in"
										replaceSource="port_in_name"
										headerClassName={cellClasses.cellGreen}
									/>
								)}
								<CustomerField
									label={t("eta_3rd_party")}
									source="eta_3rd_party"
									formatLabel={(record) => record["eta_3rd_party_name"]}
									headerClassName={cellClasses.cellGreen}
								/>
								{isNot3rdPartyRole ? (
									<YesNoField
										source="eta_show_doc_for_trans"
										headerClassName={cellClasses.cellGreen}
										label={t("eta_show_doc")}
									/>
								) : null}

								{isCustomerAndTransporterCompany && (
									<TransDriverField
										source="eta_driver"
										refSource="eta_driver_id"
										prefix="eta"
										formatLabel={(record) => record["eta_driver_name"]}
										headerClassName={cellClasses.cellGreen}
									/>
								)}

								{isAppManager && isBulkOpen ? (
									<GridFieldWithSelectionOnDoubleClick
										component={FakeReferenceTextField}
										source="eta_truck_id"
										replaceSource="eta_truck_name"
										label={t("eta_truck")}
										headerClassName={cellClasses.cellGreen}
									/>
								) : (
									<FakeReferenceTextField
										source="eta_truck_id"
										replaceSource="eta_truck_name"
										label={t("eta_truck")}
										headerClassName={cellClasses.cellGreen}
									/>
								)}

								<FakeReferenceTextField
									source="eta_trailer_id"
									replaceSource="eta_trailer_name"
									label={t("eta_trailer")}
									headerClassName={cellClasses.cellGreen}
								/>
								<LongTextField source="place_from" headerClassName={cellClasses.cellGreen} label={t("place_from")} />
								<FakeReferenceTextField
									source="eta_external_terminal_guid"
									replaceSource="eta_external_terminal_name"
									label={t("eta_external_terminal_guid")}
									headerClassName={cellClasses.cellGreen}
								/>
								{/* <TextField source="assignee_1" headerClassName={cellClasses.cellGreen} /> */}
								{isOSFCUser && (
									<ReferenceEditableField
										label={t("assignee_1")}
										source="assignee_1"
										reference="company-users"
										optimized={true}
										headerClassName={cellClasses.cellGreen}
										permissions={permissions}
										editField={
											<SelectInput
												optionText={(rec) => rec.first_name + " " + rec.last_name}
												emptyValue=""
											/>
										}
										filter={{ no_range: true, is_deleted: false, is_blocked:false, is_terminal: true, ...props.filter }}
										sort={{ field: "first_name", order: "ASC" }}
										allowEmpty={true}
										format={(value) => (value === 0 ? "" : value)}
										parse={(value) => (value === "" ? 0 : value)}
									>
										<FakeReferenceTextField source="assignee_1" replaceSource="assignee_1_name" />
									</ReferenceEditableField>
								)}

								{isOSFCUser && (
									<TruckEditableConditionalField
										label={t("eta")}
										source="eta"
										title={<ArrivedIcon style={{ color: "#27ae60" }} fontSize="small" />}
										headerClassName={cellClasses.cellGreen}
										cellClassName={
											cellClasses.noPaddingButton +
											" " +
											cellClasses.noPaddingButtonStart +
											" " +
											cellClasses.cellEtaTruck
										}
										btnLabel={translate("osfc.buttons.truck_arrived")}
										prepareValues={prepareEtaArrived}
										updateType="updatePartial"
										moreFields={["terminal_id", "is_eta_arrived"]}
									>
										<FunctionField
											source="eta_time"
											render={(record) => {
												const props = record["is_eta_arrived"]
													? {
															source: "eta_time",
															title: msgMsgNotChangeTruckArrived,
													  }
													: {
															source: "eta_time_now",
															defaultValue: dateFnsFormat(
																getNowByTerminal(terminals[record.terminal_id]),
																TIME_FORMAT
															),
															title: msgSystemWillSetNow,
													  };
												return (
													<GridInput xs={12}>
														<TerminalDatesInput
															component={TimeInput}
															margin="dense"
															label="resources.orders.fields.eta_time"
															disabled
															{...props}
														/>
													</GridInput>
												);
											}}
										/>
										<GridInput xs={12}>
											<TerminalDatesInput
												component={TimeInput}
												margin="dense"
												label="resources.orders.fields.eta_slot_time"
												source="eta_slot_time"
											/>
										</GridInput>
										<FunctionField
											source="port_in"
											render={(record) => (
												<GridInput
													xs={12}
													component={PortInput}
													label="resources.orders.fields.port_in"
													source="port_in"
													filter={{ terminal_id: [record.terminal_id] }}
												/>
											)}
										/>
									</TruckEditableConditionalField>
								)}
								{isOSFCUser && (
									<TruckEditableConditionalField
										label={t("etd")}
										source="etd"
										title={<ArrivedIcon style={{ color: "#e74c3c" }} fontSize="small" />}
										headerClassName={cellClasses.cellRed}
										btnLabel={translate("osfc.buttons.truck_arrived")}
										cellClassName={
											cellClasses.noPaddingButton +
											" " +
											cellClasses.noPaddingButtonEnd +
											" " +
											cellClasses.cellEtdTruck
										}
										prepareValues={prepareEtdArrived}
										updateType="updatePartial"
										moreFields={["terminal_id", "is_etd_arrived"]}
									>
										<FunctionField
											source="etd_time"
											render={(record) => {
												const props = record["is_etd_arrived"]
													? {
															source: "etd_time",
															title: msgMsgNotChangeTruckArrived,
													  }
													: {
															source: "etd_time_now",
															defaultValue: dateFnsFormat(
																getNowByTerminal(terminals[record.terminal_id]),
																TIME_FORMAT
															),
															title: msgSystemWillSetNow,
													  };
												return (
													<GridInput xs={12}>
														<TerminalDatesInput
															component={TimeInput}
															margin="dense"
															label="resources.orders.fields.etd_time"
															source="etd_time"
															disabled
															{...props}
														/>
													</GridInput>
												);
											}}
										/>
										<GridInput xs={12}>
											<TerminalDatesInput
												component={TimeInput}
												margin="dense"
												label="resources.orders.fields.etd_slot_time"
												source="etd_slot_time"
											/>
										</GridInput>
										<FunctionField
											source="port_out"
											render={(record) => (
												<GridInput
													xs={12}
													component={PortInput}
													label="resources.orders.fields.port_out"
													source="port_out"
													filter={{ terminal_id: [record.terminal_id] }}
												/>
											)}
										/>
									</TruckEditableConditionalField>
								)}

								<DateFormatField source="etd_date" headerClassName={cellClasses.cellRed} label={t("etd_date")} />

								<TooltipTextField
									label={t("etd_time")}
									source="etd_time"
									headerClassName={cellClasses.cellRed}
									cellClassName={cellClasses.cellTime + " " + cellClasses.cellEtdTime}
									ttFn={(rec) =>
										isOrderCCLate(rec)
											? translate("resources.orders.messages.is-order-cc-late")
											: false
									}
								/>
								{/* <TextField source="etd_time" headerClassName={cellClasses.cellRed} cellClassName={cellClasses.cellEtdTime} /> */}

								<TextField source="etd_slot_time" headerClassName={cellClasses.cellRed} label={t("etd_slot_time")} />

								{isOSFCUser ? (
									<ReferenceEditableField
										label={t("port_out")}
										source="port_out"
										reference="ports"
										optimized={true}
										headerClassName={cellClasses.cellRed}
										permissions={permissions}
										editField={<SelectInput optionText="name" emptyValue="" />}
										filter={(rec) => ({ terminal_id: [rec.terminal_id] })}
										allowEmpty={true}
										format={(value) => (value === 0 ? "" : value)}
										parse={(value) => (value === "" ? 0 : value)}
									>
										<FakeReferenceTextField source="port_out" replaceSource="port_out_name" />
									</ReferenceEditableField>
								) : (
									<FakeReferenceTextField
										label={t("port_out")}
										source="port_out"
										replaceSource="port_out_name"
										headerClassName={cellClasses.cellRed}
									/>
								)}
								<CustomerField
									label={t("etd_3rd_party")}
									source="etd_3rd_party"
									formatLabel={(record) => record["etd_3rd_party_name"]}
									headerClassName={cellClasses.cellRed}
								/>
								{isNot3rdPartyRole ? (
									<YesNoField
										label={t("etd_show_doc")}
										source="etd_show_doc_for_trans"
										headerClassName={cellClasses.cellRed}
									/>
								) : null}

								{isCustomerAndTransporterCompany && (
									<TransDriverField
										label={t("etd_driver")}
										source="etd_driver"
										refSource="etd_driver_id"
										prefix="etd"
										formatLabel={(record) => record["etd_driver_name"]}
										headerClassName={cellClasses.cellRed}
									/>
								)}

								{isAppManager && isBulkOpen ? (
									<GridFieldWithSelectionOnDoubleClick
										component={FakeReferenceTextField}
										source="etd_truck_id"
										replaceSource="etd_truck_name"
										label={t("etd_truck")}
										headerClassName={cellClasses.cellRed}
									/>
								) : (
									<FakeReferenceTextField
										source="etd_truck_id"
										replaceSource="etd_truck_name"
										label={t("etd_truck")}
										headerClassName={cellClasses.cellRed}
									/>
								)}

								<FakeReferenceTextField
									sortable={false}
									source="etd_trailer_id"
									replaceSource="etd_trailer_name"
									label={t("etd_trailer")}
									headerClassName={cellClasses.cellRed}
								/>
								<LongTextField source="place_to" headerClassName={cellClasses.cellRed} label={t("place_to")} />
								<FakeReferenceTextField
									source="etd_external_terminal_guid"
									replaceSource="etd_external_terminal_name"
									label={t("etd_external_terminal_guid")}
									headerClassName={cellClasses.cellRed}
								/>
								{isOSFCUser && (
									<ReferenceEditableField
										label={t("assignee_2")}
										source="assignee_2"
										reference="company-users"
										optimized={true}
										headerClassName={cellClasses.cellRed}
										permissions={permissions}
										editField={
											<SelectInput
												optionText={(rec) => rec.first_name + " " + rec.last_name}
												emptyValue=""
											/>
										}
										filter={{ no_range: true, is_deleted: false, is_blocked:false, is_terminal: true, ...props.filter }}
										sort={{ field: "first_name", order: "ASC" }}
										allowEmpty={true}
										format={(value) => (value === 0 ? "" : value)}
										parse={(value) => (value === "" ? 0 : value)}
									>
										<FakeReferenceTextField source="assignee_2" replaceSource="assignee_2_name" />
									</ReferenceEditableField>
								)}
								{isAppManager && isBulkOpen ? (
									<GridFieldWithSelectionOnDoubleClick component={TextField} source="trip_number" label={t("trip_number")} />
								) : (
									<TextField source="trip_number" label={t("trip_number")} />
								)}
								<BooleanField
									source="plumb_seal"
									label={<Label title={t("plumb_seal")} label="S" />}
									sortable={false}
								/>
								<NoneOrValueField
									source="printed_doc"
									label={<Label title={t("printed_doc")} label="D" />}
									sortable={false}
								/>

								<ShowButtonField
									source="total_price"
									label={
										isAppManager ? (
											<TotalPriceLabel source="total_price" resource={props.resource} />
										) : (
											<Label title={t("total_price")} label={t("total_price")} />
										)
									}
									formatter={toPriceFormatter}
									sortable={false}
								/>
								<SelectField source="commodity" choices={commodityChoices} sortable={false} label={t("commodity")} />
								<EqualField
									source="cooler_type_id"
									to={CoolerType.freezer}
									label={<Label title={t("cooler_type_freezer")} label="F" />}
									sortable={false}
								/>
								<LongTextField source="notes" sortable={false} label={t("notes")} />
								<FunctionField
									label={t("area_id")}
									source="area_id"
									render={(record) => (
										<>
											{isOSFCUser ? (
												<ReferenceEditableField
													source="area_id"
													reference="areas"
													record={record}
													optimized={true}
													permissions={permissions}
													editField={
														<SelectInput
															optionText="name"
															emptyValue=""
															disableValue="disabled_by_any"
														/>
													}
													filter={{ terminal_id: [record.terminal_id] }}
													allowEmpty={true}
													format={(value) => (value === 0 ? "" : value)}
													parse={(value) => (value === "" ? 0 : value)}
													validate={(value, _) => validateArea(value, record)}
													{...props}
												>
													<FakeReferenceTextField source="area_id" replaceSource="area_name" />
												</ReferenceEditableField>
											) : (
												<FakeReferenceTextField
													source="area_id"
													replaceSource="area_name"
													label={t("area_id")}
												/>
											)}
										</>
									)}
								/>
								{isOSFCUser ? <YesNoField source="is_area_blocked" label={t("is_area_blocked")} /> : null}
								<TextField source="pallets" label={t("pallets")} />
								<TextField source="boxes" label={t("boxes")} />
								<TextField source="label" label={t("label")} />
								<SelectEnumField
									label={t("label_source")}
									source="label_source"
									enumName="label_source"
									enumObj={LabelSource}
								/>
								<TextField source="kilos" label={t("kilos")} />
								<YesNoField source="is_doc_for_nfsa" label={t("is_doc_for_nfsa")} />
								<LongTextField source="internal_notes" label={t("internal_notes")} sortable={false} />
							</Datagrid>
						</ScrollingWrapper>
					</HandleOnDragEndWrapper>
				</List>
			</DraggableContextsWrapper>
		</div>
	);
};

export const OrdersList = (_props) => {
	if (!_props.permissions) return null;

	const props = {..._props};
	const permanentFilterValues = {};

	const isDraft = props.location.pathname.indexOf("draft-orders") !== -1;
	if (isDraft) {
		props.title = "resources.draft-orders.name";
		permanentFilterValues.draft_orders = true;
	}

	return (
		<GridUserDataProvider
			resource={props.resource}
			filterDefaultValues={filterDefaultValues}
			sortDefaultValues={defaultSort}
			permanentFilterValues={permanentFilterValues}
		>
			<_OrdersList {...props} isDraft={isDraft} />
		</GridUserDataProvider>
	);
};
