import { YesNoField } from 'components/fields/YesNoField';
import React from 'react';
import { Datagrid, EmailField, List, TextField } from 'react-admin';

import { PhoneField } from '../../../components/fields/PhoneField';
import { SelectEnumField } from '../../../components/fields/SelectEnumField';
import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { Currencies } from '../../../entities/Currencies';
import { OwnerCompanyDataActions } from './Actions';

export const OwnerCompanyDataList = (props) => (
    <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
        actions={<OwnerCompanyDataActions />}
    >
        <ScrollingWrapper>
            <Datagrid rowClick='edit'>
                <TextField source='full_name' />
                <TextField source='short_name' />
                <EmailField source='email' />
                <EmailField source='support_email' />
                <PhoneField source='phone' />
                <TextField source='web_site' />
                <TextField source='logo_link' />
                <TextField source='switchboard' />
                <SelectEnumField label="Master Currency" source='main_currency' translate={false} enumName="currency"  
                    key={"currency"} enumObj={Currencies}/>
                <YesNoField source='is_allowed_sending_emails' />
                <YesNoField source='is_allowed_sending_sms' />
                <YesNoField source='is_allowed_pricelist_algorithm' />
            </Datagrid>
        </ScrollingWrapper>
    </List>
);
