import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';

import { VehicleANPRCamerasForm } from '../forms/VehicleANPRCamerasForm';


export const VehicleANPRCamerasEdit = (props) => (
    <Edit {...props} undoable={false}>
        <SimpleForm 
            submitOnEnter={false} 
            toolbar={<SaveWithCancelToolbar />}
        >
            <VehicleANPRCamerasForm />
        </SimpleForm>
    </Edit>
);
