import React from "react";
import { Datagrid, EditButton, List, ReferenceField, TextField, usePermissions } from 'react-admin';

import {DatagridWithBlockDeleteProps} from "../../../components/_helpers/props/DatagridProps";
import {
stylesForColorInheritInList,
} from "../../../components/_helpers/useStyles";
import { LinkField } from '../../../components/fields/LinkField';
import { PhoneField } from '../../../components/fields/PhoneField';
import { StyledEmailField } from '../../../components/fields/StyledEmailField';
import { UserTypeField } from "../../../components/fields/UserTypeField";
import { YesNoField } from '../../../components/fields/YesNoField';
import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import {HideBlockUnblockButtonIfDeleted} from '../../../components/toolbars/BlockUnblockButton';
import DeleteRestoreButtons from '../../../components/toolbars/DeleteRestoreButtons';
import {AppManagerRoles, UserRole} from "../../../entities";
import { isInArray } from "../../../utils/general";
import { CustomerEmployeesActions } from "./Actions";
import { CustomersFilter } from './ListFilters';

const defaultSort = { field: 'id', order: 'DESC' };


export const CustomersList = props => {
	const classes = stylesForColorInheritInList()

	const { permissions } = usePermissions();
	
	return ( !!permissions &&
		<List
			{...props}
			sort={defaultSort}
			exporter={false}
			bulkActionButtons={false}
			filters={<CustomersFilter />}
			perPage={22}
			classes={classes}
			actions={<CustomerEmployeesActions/>}

		>
			<ScrollingWrapper>
				<Datagrid {...DatagridWithBlockDeleteProps}>
					<TextField source="id" />
					{isInArray(AppManagerRoles, permissions.role)
						&&
						<ReferenceField reference="companies" source="company_id" link={false}>
							<TextField source="full_name" />
						</ReferenceField>
					}
					<LinkField source="first_name" />
					<LinkField source="last_name" />
					<StyledEmailField source="email" />
					<PhoneField source="phone" />
					<UserTypeField source="type" sortable={false} />
					<YesNoField source="access_to_export" />
					<EditButton label="" />
					<HideBlockUnblockButtonIfDeleted entity={"user"}/>
					{permissions.role === UserRole.admin && (
							<DeleteRestoreButtons
								replaceResource="user-is-deleted"
								confirmDeleteStringKey="osfc.dialogs.confirm_delete_user"
								confirmRestoreStringKey="osfc.dialogs.confirm_restore_user"
							/>
						)}
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
}
