import { utcToZonedTime } from 'date-fns-tz';

export const str2datetime = (strDate, strTime) => {
	const val = Date.parse(strDate + 'T' + (strTime || "00:00"));
    // console.log(val);
	return val;
};

export const getNowByTimezone = (timeZone) => {
    const now = new Date();
    const zonedTime = utcToZonedTime(now, timeZone);
    return zonedTime;
};

export const getNowByTerminal = (terminal) => {
    return getNowByTimezone(terminal.time_zone)
};

export const getDateStrWitoutTime = (datetime, dateFormat = (year, month, day) => `${year}-${month}-${day}` ) => {
    const day = String(datetime.getDate()).padStart(2, '0'); 
    const month = String(datetime.getMonth() + 1).padStart(2, '0'); 
    const year = datetime.getFullYear();

    return dateFormat(year, month, day)
}

export const getDateWithDayOffset = (terminal, daysOffset = 0, weekAdjustment = false) => {
    const now = terminal ? getNowByTerminal(terminal) : new Date();

    const dateWithOffset = new Date(now);
    dateWithOffset.setDate(now.getDate() - daysOffset);
    if (weekAdjustment){
        const dayOfWeek = dateWithOffset.getDay(); 
        const diffToMonday = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
        dateWithOffset.setDate(dateWithOffset.getDate() + diffToMonday);
    }

    return getDateStrWitoutTime(dateWithOffset)
}

export const getDateWithMonthOffset = (terminal, monthsOffset = 0, monthAdjustment = false) => {
    const now = terminal ? getNowByTerminal(terminal) : new Date();

    const dateWithOffset = new Date(now);
    dateWithOffset.setMonth(now.getMonth() - monthsOffset);
    
    if (monthAdjustment) {
        dateWithOffset.setDate(1); 
    }

    return getDateStrWitoutTime(dateWithOffset);
}
