import ViewListIcon from '@material-ui/icons/ViewList';

import { OrderCreate } from './Create/Create';
import { OrderEditByManager } from './Edit/Edit';
import { OrdersDraftList, OrdersList } from './List/List';
import { OrderShow, OrderShowTabsWrapper } from './Show/Show';

export * from './Create/Create';
export * from './Edit/Edit';
export * from './Show/Show';


const sharedResourceData = {
	icon: ViewListIcon,
	show: OrderShowTabsWrapper,
	list: OrdersList,
};

export const ordersResource = {
	...sharedResourceData,
	create: OrderCreate,
	edit: OrderEditByManager,
};

export const ordersDraftResource = {
	...sharedResourceData,
	edit: OrderEditByManager,
};
