import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { Filter, useTranslate } from 'react-admin';

import TextInputEx from "../../../components/_extensions/react-admin/input/TextInputEx";
import { ToggleDatesEnum, ToggleDatesFilter } from '../../../components/filters/ToggleDatesFilter';
import { ToggleTitleInput } from '../../../components/filters/ToggleInputs';
import { TERMINAL_ALL_VALUE, ToggleTerminalsFilter } from '../../../components/filters/ToggleTerminalsFilter';
import { DateInput } from '../../../components/inputs/DateTimeInput';
import { filterColors } from '../../../utils/constants';

export const filterDefaultValues = {
	"eta_date|etd_date": [ToggleDatesEnum.TODAY],
	"terminal_id": [TERMINAL_ALL_VALUE],
};


const useStyles = makeStyles(t => ({	
	filterSearch: {
		backgroundColor: filterColors.search
	},
}));

export const EntitiesFilter = ({isSearchInDocs, ...props}) => {
	const translate = useTranslate();
	const classes = useStyles();
	return(
	<Filter {...props}>
		{!isSearchInDocs && [
		<ToggleDatesFilter 
			source="eta_date|etd_date" 
			defaultValue={null} 
			alwaysOn 
			key="eta_date|etd_date"
		/>,

		<ToggleTerminalsFilter 
			source="terminal_id" 
			defaultValue={null} 
			alwaysOn 
			key="terminal_id"
		/>,

		<TextInputEx 
			source="id" 
			key="id" 
		/>,
		
		<TextInputEx 
			source="work_time" 
			key="work_time" 
		/>,

		<TextInputEx 
			source="rank" 
			key="rank" 
		/>,

		<DateInput 
			source="eta_date" 
			margin="dense" 
			resettable 
			key="eta_date"
		/>,

		<DateInput 
			source="etd_date" 
			margin="dense" 
			resettable 
			key="etd_date"
		/>]}

		<ToggleTitleInput source="is_search_in_docs" value={true} key={"is_search_in_docs"} 
		label0={translate("osfc.filters.toggles.search_in_docs")} label1={translate("osfc.filters.toggles.back_to_entities")} 
		alwaysOn className={classes.filterSearch}/>
		
		{isSearchInDocs && <TextInputEx label="Search in docs" source="doc_search_text" alwaysOn/>}
	</Filter>
)};
