import get from 'lodash/get';

// Very basic storage helper
// values are stored in browser local or session storage

const StorageTypes = {
  local: 'localStorage',
  session: 'sessionStorage',
};

const StorageKeys = {
  columns: 'gridColumns',
};

const getRootValue = (storageKey, storageType) => {
  try {
    return JSON.parse(window[storageType].getItem(storageKey));
  } catch (e) {
    return undefined;
  }
};

const setRootValue = (value, storageKey, storageType) => {
  try {
    window[storageType].setItem(storageKey, JSON.stringify(value));
  } catch (e) {}
};

const UserDataStorage = (storageType) => (storageKey) => ({
  get(key) {
    return get(getRootValue(storageKey, storageType), key);
  },
  set(key, value) {
    const oldValue = getRootValue();
    setRootValue(
      {
        ...oldValue,
        [key]: value,
      }, 
      storageKey,
      storageType
    );
  },
});

const UserDataLocalStorage = UserDataStorage(StorageTypes.local);
const userDataLocalColumnsStorage = UserDataLocalStorage(StorageKeys.columns);

const UserDataSessionStorage = UserDataStorage(StorageTypes.session);
const userDataSessionColumnsStorage = UserDataSessionStorage(StorageKeys.columns);

export {
  UserDataLocalStorage,
  UserDataSessionStorage,
  userDataLocalColumnsStorage,
  userDataSessionColumnsStorage,
};
