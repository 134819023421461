import React, { useEffect, useMemo, useState } from 'react';
import { Labeled, LinearProgress, ReferenceInput, useTranslate } from 'react-admin';

import { SelectInputEx } from 'components/_extensions/react-admin/input/SelectInputEx';


export const ReferenceInputWithShowMore = (props) => {
    const {
        filterBase,
        filterLess,
        isLessMode: isLessModeProp,
        ...rest
    } = props;

    const t = useTranslate();
    
    const [isLessMode, setIsLessMode] = useState();
    const [filter, setFilter] = useState();

    useEffect(() => {
        setIsLessMode(isLessModeProp);
    }, [isLessModeProp]);

    useEffect(() => {
        if (filterBase !== undefined && filterLess !== undefined && isLessMode !== undefined) {
            if (isLessMode) {
                setFilter({ ...filterBase, ...filterLess });
            } else {
                setFilter({ ...filterBase });
            }
        }        
    }, [filterBase, filterLess, isLessMode]);

    const bottomButtons = useMemo(
        () => (isLessMode ? [{
            // variant: "contained" ,
            label: t('osfc.button.show_more'),
            onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
        
                setIsLessMode(false);
            }
        }] : []),
        [isLessMode]
    );

    return ( filter === undefined ?
        <Labeled {...rest}><LinearProgress /></Labeled> :
        <ReferenceInput {...rest} filter={filter}> 
            <SelectInputEx {...rest} bottomButtons={bottomButtons} withLessMode={true} isLessMode={isLessMode}/>
        </ReferenceInput>
    );
}

export default ReferenceInputWithShowMore;