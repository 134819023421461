import React from "react";

import { List, Datagrid, FunctionField, TextField, ArrayField, Pagination, useTranslate, DeleteButton, EditButton } from 'react-admin';

import { DatagridWithBlockProps } from '../../../components/_helpers/props/DatagridProps';
import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { SelectEnumField } from '../../../components/fields/SelectEnumField';
import { optionalMapperKeys } from "../../../entities/MapperData";
import { MapperType } from "../../../entities/MapperType";
import { MappersActions } from './ListActions';

import {
    makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	customHeaderRequired: {
	  backgroundColor: '#C5E1B2',
	}
  }));


const mapperDataRowStyle = (type) => (record, index) => {
	return {
		backgroundColor: optionalMapperKeys[type.toString()].includes(record['key']) && type !== MapperType.none ? '#F7F1C6' : '#F0F0F0',
	};
};

const defaultSort = { field: 'name', order: 'ASC' };
const defaultFilters = { company_id: null };
const MapperPagination = props => <Pagination rowsPerPageOptions={[2, 6, 12]} {...props} />;

export const MappersList = ({permissions, ...props}) => {
	const classes = useStyles();
	const translate = useTranslate();

	return (
		<List
			{...props}
			sort={defaultSort}
			pagination={<MapperPagination/>}
			exporter={false}
			bulkActionButtons={false}
			classes={classes}
			actions={<MappersActions />}
			filterDefaultValues={defaultFilters}
		>
			<ScrollingWrapper>
				<Datagrid {...DatagridWithBlockProps}>
					<TextField source="id" />
					<TextField source="name" />
					<SelectEnumField source="type" enumName="status" enumObj={MapperType} />
					<FunctionField source="data" sortable={false} render={mapperRecord => 
						<ArrayField source="data" sortable={false}>
							<Datagrid bulkActionButtons={false} classes={{ headerCell: classes.customHeaderRequired }} rowStyle={mapperDataRowStyle(mapperRecord['type'])}>
								<FunctionField source="key" label="resources.mappers.fields.data_field.key"
									render={dataRecord => 
										    <div>{translate(`resources.mappers.fields.data_field.${dataRecord.key}`) + ` (${dataRecord.key})`}</div>
										}
								/>
								<TextField source="value" label="resources.mappers.fields.data_field.value"/>
							</Datagrid>
						</ArrayField>
									
					} />
					<DeleteButton />
					<EditButton label=""/>
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
};
