import { CurrenciesRatesList } from "./List/List";
import { CurrencyRateCreate } from "./Create/Create";
import { CurrencyRateEdit } from "./Edit/Edit";
import CurrenciesRatesIcon from "@material-ui/icons/AccountBalance";

export const currencyRatesResource = {
	list: CurrenciesRatesList,
	create: CurrencyRateCreate,
	edit: CurrencyRateEdit,
	icon: CurrenciesRatesIcon
};
