import React from "react";
import { TextInput, required } from "react-admin";

import { FormGroupGrid, GridForm, GridInput } from "../../../components/GridForm/";

export const ExternalTerminalsCreateForm = (props) => (
	<GridForm {...props}>
		<FormGroupGrid>
			<GridInput sm={12} component={TextInput} source="name" validate={[required()]} autoFocus />
			<GridInput sm={12} component={TextInput} source="guid" validate={[required()]} />
			<GridInput sm={12} component={TextInput} source="api_url" validate={[required()]} />
			<GridInput sm={12} component={TextInput} source="api_key" validate={[required()]} />
		</FormGroupGrid>
	</GridForm>
);
