import React from 'react';
import { Filter } from 'react-admin';

import { ToggleTerminalsFilter, TERMINAL_ALL_VALUE } from '../../../components/filters/ToggleTerminalsFilter';
import { SelectEnumInput } from '../../../components/inputs/SelectEnumInput';
import { Currencies } from '../../../entities/Currencies';
import { FunctionField } from 'react-admin';

export const filterDefaultValues = {
	"terminal_id": [TERMINAL_ALL_VALUE],
};

export const ArticlesFilters = (props) => {
	const currenciesDict = props.currenciesDict;

	if (!props.filterValues.currency || !currenciesDict) return null;
	const rate = props.filterValues.currency == props.mainCurrency ? 1 : currenciesDict[props.filterValues.currency]?.rate;
	const rateDate = props.filterValues.currency == props.mainCurrency ? "" : currenciesDict[props.filterValues.currency]?.date_from;
	return (
		<Filter {...props}>
			<ToggleTerminalsFilter source="terminal_id" alwaysOn key={"terminal_id"} exclude_all={true} />
			<SelectEnumInput 
				alwaysOn
				allowEmpty={false}
                enumObj={Currencies} 
                translate={false} 
                enumName="currency" 
                source="currency" 
                style={{ width: '100px' }} 
            />
			 <FunctionField
                label="Main Currency"
                render={() => <span style={{ fontWeight: 'bold'}}>Price rate: {props.mainCurrency}/{props.filterValues.currency} = {rate}, {rateDate}</span> } 
                style={{marginBottom: '15px', marginLeft: '15px'}}
                alwaysOn
            />
		</Filter>
	);
};
