import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  Loading,
  Pagination,
  TextField,
  useRefresh
} from 'react-admin';
import { DateTimeField } from '../../../components/fields/DateFormatField';
import { SelectEnumField } from '../../../components/fields/SelectEnumField';
import { YesNoField } from '../../../components/fields/YesNoField';
import GridUserDataProvider, { useGridUserDataContext } from '../../../contexts/GridUserDataProvider';
import { useSocketContext } from '../../../contexts/SocketProvider';
import { useTerminalsContext } from '../../../contexts/TerminalsProvider';
import { AppManagerRoles, ProcessStatus } from '../../../entities';
import { DisplayTaskActionType } from '../../../entities/DisplayTaskActionType';
import { DisplayTerminalFilter } from './DisplayTerminalFilter';
import { OutdoorDisplayTaskDisplayActionRowExpand } from './OutdoorDisplayTaskDisplayActionRowExpand';

const defaultSort = { field: 'created_at', order: 'DESC' };

const useStyles = makeStyles((theme) => ({
  scrollingText: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    whiteSpace: 'nowrap',
    animation: `$scroll 10s linear infinite`,
  },

  '@keyframes scroll': {
    '0%': { transform: 'translateX(100%)' },
    '100%': { transform: 'translateX(-100%)' },
  },
}));

const OutdoorDisplay = ({ terminal, socket }) => {
  const classes = useStyles();
  const [task, setTask] = useState(null);
  const [taskText, setTaskText] = useState([]);
  const refresh = useRefresh();

  React.useEffect(() => {
    if (socket) {
      socket.on('new_task_action', (message) => {
        if (message.terminal_id !== terminal.id) return;
        const task = typeof message.task === 'string' ? JSON.parse(message.task) : message.task;
        setTask(task);
        setTaskText(task?.text?.split('<br>'));
        refresh();
      });
      return () => {
        socket.off('new_task_action');
      };
    }
  }, [socket, terminal]);

  if (!terminal) return null;

  return (
    <Card style={{ minWidth: 600, margin: '20px auto', backgroundColor: '#f4f4f4' }}>
      <CardContent>
        <Typography variant="h5" align="center">
          Terminal: {terminal.name}
        </Typography>
        <Box
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            border: '1px solid #ddd',
            padding: '10px',
            position: 'relative',
            minHeight: '30px',
          }}
        >
          {task?.type === DisplayTaskActionType.DISPLAY_TEXT ? (
            taskText?.map((text, index) => (
              <Typography
                variant="h6"
                style={{ height: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}
              >
                {text}
              </Typography>
            ))
            
          ) : (
            taskText?.map((text, index) => (
              <Typography variant="h6" className={classes.scrollingText} 
                  style={{ animationDuration: task?.duration, height: "30px" }}>
                {text}
              </Typography>
            ))
          )}
        </Box>
      </CardContent>
    </Card>
  );
};


const DatagridRowStyle = (record, index) => {
    if(record.status == ProcessStatus.running)
      return {backgroundColor: '#90D246'};
    else if (record.status == ProcessStatus.done)
      return {backgroundColor: '#C0C0C0'};
};

const _OutdoorDisplayTaskDisplayActionList = ({ permissions, ...props }) => {
  const { socket, joinRoom } = useSocketContext();
  const { data: terminals, sortedTerminalsASC } = useTerminalsContext();
  const { filters: currentFilters } = useGridUserDataContext();

  React.useEffect(() => {
    if (socket?.connected && permissions) {
      
      if (AppManagerRoles.indexOf(permissions.role) !== -1) {
        joinRoom([socket.id, 'display_subscribers']);
      }
      else{
        joinRoom([socket.id]);
      }
    }
  }, [socket?.id, socket?.connected, permissions, joinRoom]);

  React.useEffect(() => {
    if (socket?.connected && permissions && currentFilters.terminal_id) {
      if (AppManagerRoles.indexOf(permissions.role) !== -1) {
        socket.emit("get_task_display_action", { terminal_id: currentFilters.terminal_id[0] });
      }
    }
  }, [socket?.id, socket?.connected, permissions, currentFilters.terminal_id]);


  if (!sortedTerminalsASC || !currentFilters) return null;

  if (currentFilters === undefined) {
    return <Loading loadingSecondary="" />;
  };

  return (
    <>
      <OutdoorDisplay terminal={terminals[currentFilters.terminal_id]} socket={socket} />
      <List {...props}
        exporter={false}
        bulkActionButtons={false}
        defaultSort={defaultSort}
        filters={<DisplayTerminalFilter />}
        filterDefaultValues={currentFilters}
        pagination={<Pagination rowsPerPageOptions={[100, 200, 500]} />}
        perPage={100}
      >

        <Datagrid expand={<OutdoorDisplayTaskDisplayActionRowExpand />} rowClick="expand" rowStyle={DatagridRowStyle}>
          <ConditionalEditButton {...props} />
          <TextField source="id" sortable={false} />
          <DateTimeField source="created_at" sortable={false} />
          <DateTimeField source="scheduled_to" sortable={false} />
          <SelectEnumField source="status" enumName="processstatus" enumObj={ProcessStatus} sortable={false} /> 
          <YesNoField source="priority" sortable={false} />
          <ConditionalDeleteButton {...props}/>
        </Datagrid>

      </List>
    </>
  );
};

const ConditionalEditButton = (props) => props.record.status === ProcessStatus.none ? <EditButton {...props} /> : null;

const ConditionalDeleteButton = (props) => props.record.status === ProcessStatus.none ? <DeleteButton undoable={false} {...props} /> : null;

const OutdoorDisplayTaskDisplayActionList = ({ permissions, ...props }) => {
  const { loading, sortedTerminalsASC } = useTerminalsContext();
  const [selectedTerminal, setSelectedTerminal] = React.useState(null);

  React.useEffect(() => {
    if (!loading && sortedTerminalsASC?.length > 0) {
      setSelectedTerminal([sortedTerminalsASC[0].id]);
    }
  }, [loading, sortedTerminalsASC]);
  if (!selectedTerminal) return null;

  return (
    <GridUserDataProvider
      resource={props.resource}
      filterDefaultValues={{ terminal_id: selectedTerminal, done_tasks_limit: 3 }}
      sortDefaultValues={defaultSort}
    >
      <_OutdoorDisplayTaskDisplayActionList {...props} permissions={permissions} />
    </GridUserDataProvider>)
}


export default OutdoorDisplayTaskDisplayActionList;