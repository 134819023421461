import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import UploadIcon from '@material-ui/icons/CloudUpload';
import React, { useState } from 'react';
import { Button, ReferenceInput, SelectInput, required, useNotify, useTranslate } from 'react-admin';
import { Form } from 'react-final-form';

import { httpClient } from '../../../api/httpClient';
import { MapperType } from '../../../entities/MapperType';
import { useNotifyError } from '../../../utils/notifiers/useNotifyError';
import { DocumentAddButton } from '../../fields/DocumentsField/AddButton';
import { DropPaper } from '../../fields/DocumentsField/DropPaper';
import { FileInfo } from '../../fields/DocumentsField/SimpleFileInfo';

const UploadDialog = ({ onDialogClose, onDialogOpen, setSharedData }) => {
	const t = useTranslate();
	const notifyError = useNotifyError();
	const notify = useNotify();

	const [selectedFile, setSelectedFile] = useState(null);
	const [uploading, setUploading] = useState(false);

	const uploadData = React.useCallback((values) => {
		const data = new FormData();
		data.append('file', values.file);
		data.append('mapper_id', values.mapper_id);

		setUploading(true);
		httpClient("orders-upload-preview", {method: 'POST', body: data})
			.then((res) => {
				setSharedData({data: res.json });
				onDialogOpen();
			})
			.catch((error) => {
				notifyError(error);
			})
			.finally(() => {
				setUploading(false);
			});
	}, [setSharedData, onDialogOpen]);

	const handleOnSubmit = React.useCallback((values) => {
		if (selectedFile) {
			uploadData({
				...values,
				file: selectedFile,
			});
		} else {
			notify("File is required", "warning");
		}
	}, [selectedFile, uploadData]);

	return (
		<Form onSubmit={handleOnSubmit} initialValues={{ mapper_id: null }}>
			{formProps =>
				<Dialog open maxWidth="sm" onClose={onDialogClose} fullWidth>
					<DialogTitle>{t("osfc.dialogs.orders-import-upload.title")}</DialogTitle>
					<DialogContent>
						<ReferenceInput 
							fullWidth
							reference="mappers" 
							source="mapper_id" 
							label={t("osfc.dialogs.orders-import-upload.fields.mapper_id")}
							filter={{ type: MapperType.order_import }} 
							validate={[required()]}
							allowEmpty
						>
							<SelectInput name="name" />
						</ReferenceInput>
						<DropPaper processDocument={setSelectedFile} elevation={0} variant={"outlined"}>
							<Box p={2} pb={selectedFile ? 0 : 5} display="flex" justifyContent="space-between" alignItems="center">
								<DocumentAddButton
									uploading={false}
									uploadFile={setSelectedFile}
									acceptTypes=".xls,.xlsx"
								/>
							</Box>
							{selectedFile && (
								<Box p={2}>
									<FileInfo file={selectedFile} setFile={setSelectedFile} />
								</Box>
							)}
						</DropPaper>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" disabled={uploading} label={t("ra.action.cancel")} onClick={onDialogClose} color="secondary" />
						<Button 
							variant="contained" 
							label={t("osfc.buttons.upload")} 
							onClick={formProps.handleSubmit} 
							color="primary" 
							startIcon={uploading ? <CircularProgress size={16} thickness={5} color="inherit" /> : <UploadIcon />}
						/>
					</DialogActions>
				</Dialog>
			}
		</Form>
	);
};

export {
	UploadDialog as default,
};
