import React, { useState } from 'react';
import { SelectInput, usePermissions } from 'react-admin';

import { httpClient } from '../../../../api/httpClient';
import { useNotifyError } from '../../../../utils/notifiers/useNotifyError';

import ReferenceSelectInputWithShowMore, { ReferenceInputWithShowMore } from '../../../../components/inputs/ReferenceInputWithShowMore';
import { isArraysDiff } from 'utils/general';


const transportersFilterBase = {
    no_range: true,
    is_blocked: false
};

export const TransporterInput = ({ excludeIds: excludeIdsProp, ...rest }) => {
    const notifyError = useNotifyError();
    const { permissions } = usePermissions();

    const [isLessMode, setIsLessMode] = useState();
    const [excludeIds, setExcludeIds] = useState([]);
    const [filterBase, setFilterBase] = useState();
    const [filterLess, setFilterLess] = useState();

    React.useEffect(() => {
        const excludeIdsPropFiltered = excludeIdsProp ? excludeIdsProp.filter(x => x) : [];
        if (isArraysDiff(excludeIds, excludeIdsPropFiltered)) {
            setExcludeIds(excludeIdsPropFiltered);
        }
    }, [excludeIdsProp]);
    
    React.useEffect(() => {
        if (excludeIds) {
            setFilterBase({
                ...transportersFilterBase,
                'id:nin': excludeIds
            });
        } else {
            setFilterBase({...transportersFilterBase});
        }
    }, [excludeIds]);

    React.useEffect(() => {
        if (permissions) {
            function setNoneLess() {
                setFilterLess({});
                setIsLessMode(false);
            }
            if (permissions.company_id) {
                httpClient(`customer-companies/${permissions.company_id}`, {
                    method: 'GET',
                })
                .then(response => {
                    return response.json;
                })
                .then(data => {
                    if (data?.preferred_transporters?.length) {
                        setFilterLess({                            
                            'id:in': data.preferred_transporters
                        });
                        setIsLessMode(true);
                    } else {
                        setNoneLess();
                    }
                })
                .catch(error => {
                    notifyError(error);
                    setNoneLess();
                });
            } else {
                setNoneLess();
            }
        }
    }, [permissions]);

    if (!permissions) return null;

    return <ReferenceInputWithShowMore
        {...rest}        
        reference="transporters"
        sort={{ field: "full_name", order: "ASC" }}
        filterBase={filterBase}
        filterLess={filterLess}
        isLessMode={isLessMode}
        allowEmpty
        emptyValue=""
        optionText="full_name"
        ressetable
    />;
}