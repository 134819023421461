import React from "react";
import { TextInput, required } from "react-admin";
import { useFormState } from "react-final-form";

import { GridForm, GridInput } from "../../../components/GridForm/";
import { EmailInput, PhoneInput, YesNoInput } from "../../../components/inputs/";
import LanguageSelect from "../../../components/inputs/LanguageSelect";
import { Languages } from "../../../entities/Languages";
import { CompanyCustomerType } from "../../../entities";
import { SelectEnumInput } from "../../../components/inputs/SelectEnumInput";

const requiredValidator = [required()];

export const CustomerCompanyCreateForm = (props) => {
	const { values } = useFormState();
	const isTransporter = values["type"] == CompanyCustomerType.transporter;

	return (
		<GridForm {...props}>
			<GridInput
				sm={6}
				component={TextInput}
				source="full_name"
				autoFocus
				validate={requiredValidator}
			/>
			<GridInput sm={3} component={TextInput} source="short_name" validate={requiredValidator} />
			{!isTransporter && <GridInput
				sm={3}
				component={TextInput}
				source="accountancy_number"
				validate={requiredValidator}
			/>}
			<GridInput sm={3} component={PhoneInput} source="phone" validate={requiredValidator} />
			<GridInput sm={3} component={EmailInput} source="email" validate={requiredValidator} />
			<GridInput
				sm={3}
				component={EmailInput}
				source="notification_email"
				validate={requiredValidator}
			/>
			<GridInput
				sm={3}
				component={EmailInput}
				source="deviation_notification_email"
				validate={requiredValidator}
			/>
			{!isTransporter && <GridInput xs={4} component={YesNoInput} source="set_order_completed" defaultValue={false} />}
			<GridInput xs={4} source="type" component={SelectEnumInput}
				key={"company_type"} 
				resettable 
				enumName="company-type" 
				enumObj={CompanyCustomerType} 
				defaultValue={CompanyCustomerType.customer}
				/>
			{(values["type"] == CompanyCustomerType.transporter || values["type"] == CompanyCustomerType.customer_and_transporter) && (
				<GridInput sm={4} component={EmailInput} source="transporter_notification_email" />
			)}
			<GridInput
				xs={4}
				component={LanguageSelect}
				defaultValue={Languages.en}
				validate={requiredValidator}
			/>
			<GridInput xs={6} component={TextInput} source="guid" />
		</GridForm>
	);
};
