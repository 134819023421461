import React from "react";

import { Edit, SimpleForm } from 'react-admin';

import { AppManagerRoles, UserRole } from '../../../entities/';
import { usePermissions } from "react-admin";
import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';
import { isInArray } from "../../../utils/general";
import { CurrencyRateForm } from "../Form/Form";

export const CurrencyRateEdit = (props) => {
    const { permissions } = usePermissions();
	const isAdminOrOSFCManager = permissions && isInArray(AppManagerRoles, permissions.role);

	return (
		<Edit {...props}>
			<SimpleForm submitOnEnter={false} undoable={false} toolbar={<SaveWithCancelToolbar />} redirect="list">
                <CurrencyRateForm create={false} {...props} />
			</SimpleForm>
		</Edit>
	);
};