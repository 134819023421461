import { isEqual } from 'lodash';
import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
} from 'react';
import { changeListParams, useDataProvider } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';

const GridUserDataContext = createContext({});

const GridUserDataProvider = ({
    children,
    resource,
    filterDefaultValues,
    sortDefaultValues,
    permanentFilterValues = {},
}) => {
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();

    const resourceParamsState = useSelector((state) =>
        state.admin.resources[resource]
        ? state.admin.resources[resource].list.params
        : {}
    );

    const isInitialMount = useRef(true);
    const prevFilterRef = useRef(resourceParamsState.filter);
    const prevSortRef = useRef({
        order: resourceParamsState.order,
        sort: resourceParamsState.sort,
    });

    const keys = {
        filters: `${resource}-filters`,
        sort: `${resource}-sort`,
    };

    const updateUserData = (key, data) => {
        dataProvider.update('user-data', {
            id: key,
            data: { data },
        });
    };

    useEffect(() => {
        const restoreUserData = async () => {
            try {
                const [filtersResponse, sortResponse] = await Promise.all([
                    dataProvider.getOne('user-data', { id: keys.filters }),
                    dataProvider.getOne('user-data', { id: keys.sort }),
                ]);

                const dispatchPayload = {
                    ...resourceParamsState,
                    filter: {
                        ...filterDefaultValues,
                        ...resourceParamsState.filter,
                        ...filtersResponse.data.data,
                        ...permanentFilterValues,
                    },
                    order: sortResponse.data.data.order || sortDefaultValues.order,
                    sort: sortResponse.data.data.sort || sortDefaultValues.field,
                };

                dispatch(changeListParams(resource, dispatchPayload));

                prevFilterRef.current = dispatchPayload.filter;
                prevSortRef.current = {
                    order: dispatchPayload.order,
                    sort: dispatchPayload.sort,
                };
            } finally {
                isInitialMount.current = false;
            }
        };

        if (isInitialMount.current) {
            restoreUserData();
        }
    }, [
        dataProvider,
        dispatch,
        filterDefaultValues,
        keys.filters,
        keys.sort,
        resource,
        resourceParamsState,
        sortDefaultValues,
    ]);

    useEffect(() => {
        if (!isInitialMount.current) {
            if (!isEqual(resourceParamsState.filter, prevFilterRef.current)) {
                updateUserData(keys.filters, resourceParamsState.filter);
                prevFilterRef.current = resourceParamsState.filter;
            }
        }
    }, [
        resourceParamsState.filter,
        updateUserData,
        keys.filters,
        isInitialMount,
    ]);

    useEffect(() => {
        if (!isInitialMount.current) {
            const currentSort = {
                order: resourceParamsState.order,
                sort: resourceParamsState.sort,
            };
            if (!isEqual(currentSort, prevSortRef.current)) {
                updateUserData(keys.sort, currentSort);
                prevSortRef.current = currentSort;
            }
        }
    }, [
        resourceParamsState.order,
        resourceParamsState.sort,
        updateUserData,
        keys.sort,
        isInitialMount,
    ]);

    if (!(resourceParamsState.order && resourceParamsState.sort)) return null;

    const contextValue = {
        filters: resourceParamsState.filter,
        sort: {
            order: resourceParamsState.order,
            field: resourceParamsState.sort,
        },
    };

    return (
        <GridUserDataContext.Provider value={contextValue}>
            {children}
        </GridUserDataContext.Provider>
    );
};

const useGridUserDataContext = () => {
    const context = useContext(GridUserDataContext);

    if (!context) {
        throw new Error(
            'useGridUserDataContext must be used within a GridUserDataProvider'
        );
    }

    return context;
};

export { GridUserDataProvider as default, useGridUserDataContext };
