export function isNullUndEmpty(val){
    return val === null || val === undefined || val === "";
}

export function isInArray(arr, val){
    return arr.indexOf(val) !== -1;
}

export function isArraysDiff(arr1, arr2){
    if (!arr1 && !arr2) return true;
    if ((!arr1 && arr2) || (arr1 && !arr2)) return false;
    if (arr1.lwngth !== arr2.lwngth) return false;
    for(const el1 of arr1) {
        if (arr2.indexOf(el1) === -1) return false;
    }
    return true;
}