import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import {
	List,
	Loading,
	Pagination,
	SelectInput,
	TextField,
	useGetList,
	useRefresh,
	useTranslate,
} from "react-admin";

import { Datagrid } from "../../../components/CustomizableDataGrid/";
import { DateFormatField, DateTimeField } from "../../../components/fields/DateFormatField";
import {
	ReferenceEditableField,
	TextEditableField,
} from "../../../components/fields/EditableField";
import { PriorityField } from "../../../components/fields/PriorityField";
import { SelectEnumField } from "../../../components/fields/SelectEnumField";
import { UserFullNameReferenceField } from "../../../components/fields/UserFullNameReferenceField";
import LabelWithTooltip from "../../../components/LabelWithTooltip";
import { ScrollingWrapper } from "../../../components/ScrollingWrapper";
import { SearchResultDocumentsRowExpand } from "../../../components/SearchResultRowExpand";
import GridUserDataProvider, {
	useGridUserDataContext,
} from "../../../contexts/GridUserDataProvider";
import { AppManagerRoles, EntityType, OSFCUserRoles } from "../../../entities";
import { isInArray } from "../../../utils/general";
import { useAsyncColumnsStorage } from "../../../utils/hooks/useAsyncColumnsStorage";
import { useIsSearchInDocs } from "../../../utils/hooks/useIsSearchInDocs";
import { ActionButtonsField, EntitiesActions } from "./Actions";
import {
	MatchCompanyField,
	MatchInternalNotesField,
	MatchNotesField,
	MatchServiceField,
	MatchStatusField,
	TripNumberOrIsRecurringField,
} from "./Fields";
import { EntitiesFilter, filterDefaultValues } from "./Filters";

const defaultSort = { field: "id", order: "DESC" };

const BG_COLOR_WHITE = "#ffffff";
const BG_COLOR_GREEN = "#b7ff7d";
const BG_COLOR_RED = "#ff8c8c";

const useStyles = makeStyles({
	list: {
		noResults: {
			backgroundColor: "red",
			padding: 100,
		},
		actions: {
			backgroundColor: "blue",
		},
	},
	expandIcon: {
		padding: "2px",
	},
});

const useCellStyles = makeStyles({
	cellGreen: {
		backgroundColor: BG_COLOR_GREEN, // green
		outline: "1px solid white",
		".order-cc-late": {
			backgroundColor: BG_COLOR_RED,
		},
		"order-car-detected-eta": {
			borderColor: BG_COLOR_GREEN,
		},
	},
	cellRed: {
		backgroundColor: BG_COLOR_RED, // red
		outline: "1px solid white",
		".order-cc-late": {
			backgroundColor: BG_COLOR_RED,
		},
		"order-car-detected-eta": {
			borderColor: BG_COLOR_GREEN,
		},
	},
	noPaddingButton: {
		padding: "0",

		"&>span>button": {
			backgroundColor: BG_COLOR_WHITE,
			border: "solid 1px white",
			minWidth: "auto",
		},
	},
	noPaddingButtonStart: {
		"&>span>button": {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
			borderRight: "none",
		},
		// '.order-cc-late &>span>button': {
		// 	backgroundColor: BG_COLOR_RED
		// },
	},
	noPaddingButtonEnd: {
		"&>span>button": {
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0,
			borderLeft: "none",
		},
		// '.order-cc-late &>span>button': {
		// 	backgroundColor: BG_COLOR_GREEN
		// },
	},
	cellEtaTruck: {
		"&>span": {
			outline: "1px solid white",
		},

		".order-cc-late &>span": {
			backgroundColor: BG_COLOR_RED,
		},
		".order-car-detected-eta &>span": {
			outlineColor: "#1ea814",
		},
	},
	cellEtdTruck: {
		"&>span": {
			outline: "1px solid white",
		},

		".order-cc-late &>span": {
			backgroundColor: BG_COLOR_RED,
		},
		".order-car-detected-etd &>span": {
			outlineColor: "#1ea814",
		},
	},
	cellEtaTime: {
		"&>span": {
			backgroundColor: "1px solid transparent",
		},
		".order-cc-late &>span": {
			backgroundColor: BG_COLOR_RED,
		},
		"order-car-detected-eta &>span": {
			backgroundColor: BG_COLOR_GREEN,
		},
	},
	cellEtdTime: {
		"&>span": {
			backgroundColor: "1px solid transparent",
		},
		".order-cc-late &>span": {
			backgroundColor: BG_COLOR_RED,
		},
		"order-car-detected-etd &>span": {
			backgroundColor: BG_COLOR_GREEN,
		},
	},
	cellEtaTime: {
		"&>span": {
			border: "1px solid transparent",
		},
		".order-cc-late &>span": {
			backgroundColor: BG_COLOR_RED,
		},
		".order-car-detected-eta &>span": {
			backgroundColor: `${BG_COLOR_GREEN} !important`,
		},
	},
	cellEtdTime: {
		"&>span": {
			border: "1px solid transparent",
		},
		".order-cc-late &>span": {
			backgroundColor: BG_COLOR_RED,
		},
		".order-car-detected-etd &>span": {
			backgroundColor: `${BG_COLOR_GREEN} !important`,
		},
	},
	// cellEtaTime: {
	// 	'.order-cc-late &>span': {
	// 		backgroundColor: BG_COLOR_RED,
	// 		color: BG_COLOR_GREEN,
	// 	},
	// },
	// cellEtdTime: {
	// 	'.order-cc-late &>span': {
	// 		backgroundColor: BG_COLOR_GREEN,
	// 		color: BG_COLOR_RED
	// 	},
	// },
});

const EntityPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const prepareEtaArrived = (v) => ({
	port_in: v.port_in,
	eta_time: v.eta_time,
	eta_slot_time: v.eta_slot_time,
});

const prepareEtdArrived = (v) => ({
	port_out: v.port_out,
	etd_time: v.etd_time,
	etd_slot_time: v.etd_slot_time,
});

const _EntitiesList = ({ permissions, ...props }) => {
	const { filters: currentFilters, sort: currentSort } = useGridUserDataContext();
	const classes = useStyles();
	const cellClasses = useCellStyles();
	const translate = useTranslate();
	const t = (field) => translate(`resources.entities.fields.${field}`);
	const isSearchInDocs = useIsSearchInDocs(currentFilters, props.resource);

	const columnsAsyncStorage = useAsyncColumnsStorage("entities-columns");
	const refresh = useRefresh();

	const { data: orderStatuses } = useGetList("order-statuses");
	const { data: taskStatuses } = useGetList("task-statuses");

	const statusesByEntityType = {
		[EntityType.order]: orderStatuses,
		[EntityType.task]: taskStatuses,
	};

	useEffect(() => {
		if (permissions?.time_refresh_order) {
			const intervalId = setInterval(refresh, permissions.time_refresh_order * 1000);
			return () => clearInterval(intervalId);
		}
	}, [permissions, refresh]);

	if (!permissions || currentFilters === undefined || columnsAsyncStorage.columns === undefined) {
		return <Loading loadingSecondary="" />;
	}

	const orderRowStyle = (record, index) => {
		const statuses = statusesByEntityType[record.type];
		return {
			backgroundColor: statuses[record.status] && statuses[record.status].color,
		};
	};

	const isAppManager = permissions && isInArray(AppManagerRoles, permissions.role);
	const isOSFCUser = permissions && OSFCUserRoles.has(permissions.role);
	return (
		<List
			{...props}
			perPage={50}
			empty={false}
			pagination={<EntityPagination />}
			classes={classes.list}
			exporter={false}
			filters={<EntitiesFilter isSearchInDocs={isSearchInDocs} />}
			filterDefaultValues={currentFilters}
			bulkActionButtons={false}
			sort={currentSort}
			actions={<EntitiesActions />}
		>
			<ScrollingWrapper>
				<Datagrid
					rowStyle={orderRowStyle}
					storage={columnsAsyncStorage}
					isRowExpandable={() => isSearchInDocs}
					expand={isSearchInDocs ? <SearchResultDocumentsRowExpand /> : false}
					expandIconCell={{ padding: "2px" }}
				>
					<ActionButtonsField isSearchInDocs={isSearchInDocs} />
					<TextField source="id" />
					<SelectEnumField source="type" translate={false} enumName="type" enumObj={EntityType} />
					<UserFullNameReferenceField
						label="resources.entity-editlog.created_by"
						sortable={false}
					/>
					<PriorityField
						source="priority"
						label={<LabelWithTooltip tooltip={t("priority")} label="P" />}
						sortable={false}
					/>
					{/* TODO: MAke the same view as in Order grid */}
					{/* <TextEditableField editable={isAppManager} source="rank" />
					<TextField source="rank_auto" /> */}
					<TextField source="title" label="resources.tasks.fields.title" />
					<TextField
						source="terminal_name"
						label="resources.entities.fields.terminal"
						sortable={true}
					/>
					<DateFormatField
						source="multi_eta_date"
						label={"resources.entities.fields.eta_date"}
						headerClassName={cellClasses.cellGreen}
					/>
					<TextField
						source="multi_eta_time"
						label={"resources.entities.fields.eta_time"}
						headerClassName={cellClasses.cellGreen}
						cellClassName={cellClasses.cellEtdTime}
					/>
					<ReferenceEditableField
						source="assignee_1"
						reference="company-users"
						optimized={true}
						headerClassName={cellClasses.cellGreen}
						permissions={permissions}
						editField={
							<SelectInput
								optionText={(rec) => rec.first_name + " " + rec.last_name}
								emptyValue=""
							/>
						}
						filter={{ no_range: true, is_deleted: false, is_blocked:false, is_terminal: true, ...props.filter }}
						sort={{ field: "first_name", order: "ASC" }}
						allowEmpty={true}
						format={(value) => (value === 0 ? "" : value)}
						parse={(value) => (value === "" ? 0 : value)}
					>
						<TextField source="assignee_1_name" />
					</ReferenceEditableField>
					<TripNumberOrIsRecurringField
						label={
							<LabelWithTooltip
								tooltip={t("trip_number_or_is_recurring_full")}
								label={t("trip_number_or_is_recurring")}
							/>
						}
					/>
					<DateFormatField
						source="multi_etd_date"
						label={"resources.entities.fields.etd_date"}
						headerClassName={cellClasses.cellRed}
					/>
					<TextField
						source="multi_etd_time"
						label={"resources.entities.fields.etd_time"}
						headerClassName={cellClasses.cellRed}
						cellClassName={cellClasses.cellEtdTime}
					/>
					<ReferenceEditableField
						source="assignee_2"
						reference="company-users"
						optimized={true}
						headerClassName={cellClasses.cellRed}
						permissions={permissions}
						editField={
							<SelectInput
								optionText={(rec) => rec.first_name + " " + rec.last_name}
								emptyValue=""
							/>
						}
						filter={{ no_range: true, is_deleted: false, is_blocked:false, is_terminal: true, ...props.filter }}
						sort={{ field: "first_name", order: "ASC" }}
						allowEmpty={true}
						format={(value) => (value === 0 ? "" : value)}
						parse={(value) => (value === "" ? 0 : value)}
					>
						<TextField source="assignee_2_name" />
					</ReferenceEditableField>
					<MatchNotesField label="resources.entities.fields.notes" />
					<MatchCompanyField label="resources.entities.fields.company" />
					<DateTimeField source="created_at" addLabel />
					<DateTimeField source="completed_at" addLabel />
					<MatchStatusField
						permissions={permissions}
						isAppManager={isAppManager}
						isOSFCUser={isOSFCUser}
						label="resources.tasks.fields.status"
					/>
					<MatchServiceField label="resources.tasks.fields.service" />
					<TextField
						label={
							<LabelWithTooltip
								tooltip={translate("osfc.fields.special.minutes")}
								label={t("work_time")}
							/>
						}
						source="work_time"
					/>
					<MatchInternalNotesField label="resources.entities.fields.internal_notes" />
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
};

export const EntitiesList = (props) => (
	<GridUserDataProvider
		resource={props.resource}
		filterDefaultValues={filterDefaultValues}
		sortDefaultValues={defaultSort}
	>
		<_EntitiesList {...props} />
	</GridUserDataProvider>
);
