
export const Currencies = {
    EUR: "EUR",
    NOK: "NOK",
    DKK: "DKK"
}



export function getCurrenciesWithoutMainCurrency(mainCurrencyValue) {
    return Object.fromEntries(
        Object.entries(Currencies).filter(([key, value]) => value !== mainCurrencyValue)
    );
}