import React from 'react';
import { Show, TextField, ReferenceField, SimpleShowLayout } from 'react-admin';

import { ActionsWithBackButton } from '../../../components/actionbars/ActionsWithBackButton';
import { JsonTextField } from '../../../components/fields/JsonTextField';
import { SelectEnumField } from '../../../components/fields/SelectEnumField';
import { DirectionType } from '../../../entities/DirectionType';


export const VehicleANPRCamerasShow = (props) => (
    <Show 
        actions={<ActionsWithBackButton hasEdit hasDelete />} 
        {...props}
    >
        <SimpleShowLayout>
            <TextField source='camera_id' />

            <ReferenceField 
                source='terminal_id' 
                reference='terminal'
                link={false}
            >
                <TextField source='name' />
            </ReferenceField>

            <SelectEnumField 
                source="force_vehicle_direction_type" 
                enumName="vehicle_direction_type" 
                enumObj={DirectionType} 
                sortable={false}
            />

            <JsonTextField 
                source='ips' 
                jsonString={false} 
                label='resources.vehicle-anpr-cameras.fields.ips' 
            />
        </SimpleShowLayout>
    </Show>
);
