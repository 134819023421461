import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { translate, useGetList, useTranslate } from 'react-admin';

import { OrderNormalStatuses } from '../../../entities';

export const StatusFilterField = ({ value, onChange }) => {
	const handleChange = (e) => {
		onChange && onChange(e.target.value === 0 ? undefined : e.target.value);
	};

	const { ids, data } = useGetList(
		"order-statuses",
		{page: 0, perPage: -1},
		{field: 'id', order: 'ASC'},
		{id: OrderNormalStatuses},
	);

	const translate = useTranslate()

	return (
		<TextField
			select
			label={translate("resources.orders.export-dialog.fields.status")}
			variant="outlined"
			margin="none"
			size="small"
			value={value ? value : 0}
			onChange={handleChange}
			style={{ width: 200, marginLeft: 8 }}
		>
			<MenuItem value={0}>All</MenuItem>
			{ids.map(id => <MenuItem key={id} value={id}>{data[id].name}</MenuItem>)}
		</TextField>
	);

}
